<template>
    <!-- BEGIN: Content-->
    <div class="driverProfile driverinvoices" style="margin-top:-2.5rem">
      <div class="mx-0 px-0" style="position:relative">
        <v-row class="my-0 py-0" style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col cols="12" class="invoice-content py-0 my-0">
            <v-fade-transition>
                <div class="classloader">
                   <Spinner name="circle" color="#008000" size="20" fadeIn="quarter" v-show="overlay"/>
                </div>
              </v-fade-transition>

            <Profile :driverid="parseInt($route.params.id)"/>

            <div class="column content-column">
              <v-container fluid>
                <v-row>
                  <div class="fill header list-header d-flex">
                    <div class="section_title"><span class="title" style="color:#888888;font-size:1.2rem !important">Receipts&nbsp;&nbsp;</span></div>
                  </div>
                  <div class="fill subheader list-subheader d-flex mx-0">

                    <v-btn depressed color="white" @click="showDialog" variant="light" class="light mx-0" size="sm"><v-icon class="mr-1">mdi-plus</v-icon> Record Payment</v-btn>

                  </div>

                  <v-col
                    cols="12"
                    md="12"
                    class="px-0 mx-0 pb-0 mb-0"
                   >

                    <v-card class="pb-3 pt-0 px-0 mx-0 mb-0" style="box-shadow:none;padding-bottom:0 !important">
                      <v-card-title class="py-0 my-0" style="display:flex;align-items:center;justify-content:space-between;height:3rem">
                        <div class="summaryAlert px-0">
                          <div class="summaryTotals">
                            <div class="totals" style="flex-direction:row !important">
                              <span class="font-normal" style="color:#888 !important">Total Payment</span>
                              <span class="font-medium mx-2" style="font-size:18px !important;">KES{{ 
                                    ((driverpayments.reduce( (acc, item) => 
                                          {  
                                              
                                          return acc += parseFloat(item.paidamount); 

                                        }, 0))) | currency(' ')
                                  }}</span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <v-row class="solo-wrapper">
                            <v-col md="12" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                              <v-text-field
                                    v-model="searchfilter"
                                    label="Search"
                                    solo
                                    hide-details="auto"
                                  ></v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-title>
                      <v-card-text class="mx-0 px-0 pb-0 mb-0 cardtext1">
                        <v-fade-transition>
                          <div>
                            <div class="classloader">
                               <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="overlay"/>
                            </div>
                          </div>
                        </v-fade-transition>
                        <v-data-table
                          dense
                          :headers="fields"
                          :items="driverpayments"
                          class="elevation-0"
                          :loading="false"
                          fixed-header

                          disable-pagination
                          hide-default-footer
                          id="virtual-scroll-table"
                          v-scroll:#virtual-scroll-table="onScroll"
                          height="100%"
                          >
                          <template v-slot:item.date="{ item }">
                            {{ moment(item.date).format('MMM Do, YYYY') }}
                          </template>
                          <template v-slot:item.paymentno="{ item }">
                            {{ item.payment_no }}
                          </template>
                          <template v-slot:item.mode="{ item }">
                            {{ item.mode !== null ? item.mode.name : 'N/A' }}
                          </template>
                          <template v-slot:item.refno="{ item }">
                            {{ item.reference_no }}
                          </template>
                          <template v-slot:item.invoiceno="{ item }">
                            <RouterLink v-if="item.invoice !== null" style="color:#F98525 !important;text-decoration:none !important" link
                                :to='{name:"driverInvoice",params:{id:item.driver_id}}'>{{ item.invoice.invoice_no }}</RouterLink>
                            <span v-else>N/A</span>
                          </template>
                          <template v-slot:item.amount="{ item }">
                            {{ item.paidamount | currency('') }}
                          </template>
                          <template v-slot:item.action="{ item }">
                            <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    small
                                    v-on="on">
                                  <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                </v-btn>
                              </template>
                              
                              <v-list-item-group
                                color="primary"
                                class="actions"
                                >

                                  <v-list-item
                                    v-if="item.invoice_no_id == null"
                                    @click="linkpayment(item.id,item.driver_id, item.payment_no)"
                                    >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-link-variant</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Link Invoice</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item
                                    v-if="item.invoice_no_id !== null"
                                    @click="unlinkpayment(item.id)"
                                    >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-link-variant-off</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Unlink Invoice</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item
                                    @click="editReceipt(item)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-pencil</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                <v-list-item
                                  link
                                  @click="deletePayment(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon>mdi-delete</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Remove</v-list-item-title>
                                      </v-list-item-content>
                                </v-list-item>

                              </v-list-item-group>
                            </v-menu>
                          </template>
                        </v-data-table>

                      </v-card-text>
                    </v-card>

                  </v-col>

                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </div>

      <NewReceipt v-model="receiptdialog" :driverid="parseInt($route.params.id)" />
      <EditReceipt v-model="editreceiptdialog" :receiptdata="receiptdata" />
      <LinkPayment v-model="linkpaymentdialog" />

    </div>

</template>
<style>
  .driverinvoices .cardtext1{
    min-height:40vh;
  }
  .profile .v-list-item__title{
    margin-bottom:.5rem !important;
  }
  .profile .v-list-item__subtitle, .profile .v-list-item__title, .profile .v-list-item__action-text{
    white-space: normal !important;
    text-transform: none !important;
  }
  .profile .v-list-item__subtitle, .profile .v-list-item__action-text{
    font-size:.8rem !important;
  }
  .v-list-item--link:before{
    background-color: #fff !important;
  }
  .v-pagination__item{
    box-shadow: none !important;
  }
  .theme--light.v-tabs>.v-tabs-bar,.v-tabs-slider{
      background: transparent !important;
    }
  /*  .v-slide-group__wrapper{
      margin-top: -3rem !important;
    }*/
    .v-pagination__navigation{
      box-shadow: none !important;
    }
    /*.v-window.v-item-group{
      margin-top:-3rem !important;
      padding:1rem !important;
    }*/
    .v-tabs-bar,.v-tabs-bar__content{
      background-color: #fff !important;
    }
    .v-tabs-items{
      background-color: #fff !important;
      margin-top:.3rem !important;
    }
    .driverProfile .v-slide-group__content {
      /*background:#cecece !important;*/
      padding: 1rem .3rem !important;
    }

</style>
<style scoped>

    .v-tabs ::v-deep .v-tabs-slider-wrapper {
      transition: none !important;
    }
    @media (min-width: 576px){
        .modal-slide-in .modal-dialog {
            width: 48rem;
        }
    }

    .vs__selected, .vs__search,.vs__search:focus{
        color: #B4B7BD !important;
    }
    .vs__open-indicator,.vs__clear{
        fill:  #B4B7BD !important;
    }

    .v-tabs-slider-wrapper{
      display: none !important;
    }
    .v-slide-group__content{
      width:100% !important;
      justify-content: flex-end !important;
    }
    
    .v-pagination__item, .v-pagination__navigation{
      box-shadow: none !important;
    }
    .v-pagination__navigation{
      background:#F1F3F4 !important;
    }
    .v-pagination{
      display: flex;
      justify-content: flex-end;
      margin-top:1.2rem;
    }

    .v-tab{
      padding:.5rem 1.5rem !important;
      background:#FFFFFF;
      border-radius: 50px !important;
      height:2.4rem !important;
      margin:.4rem;
    }
    .v-tab.v-tab--active{
      background:#DEC7FF;
    }

</style>
<script>
import Vue from 'vue'
import axios from 'axios'
import StarRating from 'vue-star-rating'
import {ability} from '@/services/ability'

import NewReceipt from '@/components/drivers/newreceipt'
import EditReceipt from '@/components/drivers/editreceipt'
import LinkPayment from '@/components/drivers/linkpayment'
import Profile from '@/components/drivers/profile'
import Spinner from 'vue-spinkit'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { format, parseISO } from 'date-fns'

export default {
    name:"DriverProfile",
    components: {
      StarRating,Spinner,Profile,EditReceipt,DateRangePicker,NewReceipt,LinkPayment
    },
    data(){
        return {
          overlay:false,
          tab: null,
          dialog: false,
          updatedialog: false,
          searchfilter: "",
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          imageRules: [
            value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
          ],
          details: null,
           rating:0,
           menu: false,
           date: "", 
           rowHeight: 20,
           previewImage: null,
           documents: null,
           valid: true,
           start: 0,
            timeout: null,
            isBusy: false,
            loading: false,
           disabled: false,
           receiptdialog: false,
           editreceiptdialog: false,
           linkpaymentdialog: false,
           receiptdata: null,
           height: '100%',

           fields: [
            { text: 'Date', value: 'date' },
            { text: 'Payment #', value: 'paymentno' },
            { text: 'Mode', value: 'mode' },
            { text: 'Ref #', value: 'refno' },
            { text: 'Amount', value: 'amount' },
            { text: 'Invoice #', value: 'invoiceno' },
            { text: 'Action', value: 'action' },
           ],

           dateRange: {
            startDate: null,
            endDate: null
           },
          singleDatePicker: true,
          showWeekNumbers: true,
          showDropdowns: true,
          autoApply: true,
          alwaysshowcalendars: true,
          date: "",
          dates: {},
          daterange: {
            start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
          },
          rangedate: "",
          }
    },
    beforeMount() {
      if(!ability.can('user-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
      this.$store.state.driverreceipts = []
    },
    mounted(){
      this.fetch()
    },
    computed: {   
      loader() {
        return this.$store.state.tableloader
      },   
      driverpayments() {
        return this.$store.state.driverreceipts.filter(item => {

            this.start = 0

            return item.payment_no.toLowerCase().includes(this.searchfilter.toLowerCase())
          })
      },
      startHeight() {
        return this.start * this.rowHeight - 32;
      },
      endHeight() {
        return this.rowHeight * (this.driverreceipts.length - this.start);
      }
    },
    methods:{
      onScroll(e) {
        // debounce if scrolling fast
        this.timeout && clearTimeout(this.timeout);
   
        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.driverreceipts.length ?
            this.driverreceipts.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
      },
      fetch() {
        this.overlay = true
        this.$store
            .dispatch('fetchdriverreceipts',this.$route.params.id)
            .then(() => {
              this.overlay = false
            })
            .catch(() => {
              this.overlay = false
            })
      },
      showDialog() {
        this.receiptdialog = true
      },
      linkpayment(id, driverId, paymentNo) {
        this.$store.state.linkdriverpaymentdata = {
          id: id,
          driverid: driverId,
          tolinkreceipt: paymentNo
        }
        this.linkpaymentdialog = true
      },
      unlinkpayment(id) {
        axios.get(`/driver/payment/unlink/${id}`).then(response=>{
          if(response.data.status == parseInt(200)) {

            let index = this.$store.state.driverreceipts.findIndex(data => data.id == response.data.receipt.id);
            if (index > -1) {
              this.$set(this.$store.state.driverreceipts, index, response.data.receipt);
            }

            this.$toast.success('Payment unlinked successfully','Success',{position: 'topRight',timeout: 7000,})

          }
        }).catch(error=>{
            console.log(error)
        })
      },
      editReceipt(data){
        this.receiptdata = data
        this.editreceiptdialog = true
      },
      deletePayment(id){
          if(confirm("Are you sure to remove receipt ?")){
              axios.get(`/remove-receipt/${id}`).then(response=>{
                if(response.data.status === 200) {
                  let index = this.$store.state.driverreceipts.findIndex(data => data.id == response.data.id);
                  if (index > -1) {
                    this.$store.state.driverreceipts.splice(index, 1);
                  }
                  this.$toast.success('Payment removed successfully','Success',{position: 'topRight',timeout: 7000,})
                }
              }).catch(error=>{
                  console.log(error)
              })
          }
      }
    }
}
</script>