<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>

        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-text-field
                v-model="searchfilter"
                label="Search"
                solo
                hide-details="auto"
                class="search_"
              ></v-text-field>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            link
            :to="{name:'newDebitNote'}"
          >
            Debit Note
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="debitnotesList">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">
                <v-card-title class="mt-0 pt-0">
                  <v-tabs
                    v-model="tab"
                    horizontal
                   >
                    <v-tab link :to='{name:"invoiceList"}'>Invoices</v-tab>
                    <v-tab class="mx-2" link :to='{name:"paymentList"}'>Payments</v-tab>
                    <v-tab class="mx-2" link :to='{name:"creditnotesList"}'>Credit Note</v-tab>
                    <v-tab class="mx-2" link :to='{name:"debitnotesList"}'>Debit Note</v-tab>
                    <v-tab class="mx-2" link :to='{name:"customerStatement"}'>Statement</v-tab>
                  </v-tabs>
                </v-card-title>
                <v-card-text class="px-0 pt-0 pb-3 cardtext_tax">
                  <div class="empty-state" v-if="alldebitnotes.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>

                  <v-virtual-scroll
                      :items="alldebitnotes"
                      :item-height="20"
                      renderless 
                      id="virtualscroll"
                     >
                      <template v-slot:default="{ item }">
                          <div class="tcell">{{ moment(item.date).format('MMM Do YYYY') }}</div>
                          <div class="tcell"><RouterLink style="color:#F98525 !important;text-decoration:none !important" link
                              :to='{name:"showDebitNote",params:{id:item.id}}'>{{ item.debit_note_no }}</RouterLink></div>
                          <div class="tcell">
                            <span v-if="item.organisation !== null">{{ item.organisation.name }}</span>
                            <span v-else>Private</span>
                          </div>
                          <div class="tcell">
                            <RouterLink v-if="item.invoice !== null" style="color:#F98525 !important;text-decoration:none !important" link
                                :to='{name:"showInvoice",params:{id:item.invoice.id}}'>{{ item.invoice.invoice_no }}</RouterLink>
                          </div>
                          <div class="tcell">{{ item.status }}</div>
                          <div class="tcell">{{ item.notes }}</div>
                          <div class="tcell">KES<span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span></div>
                          <div class="tcell">
                            <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    small
                                    v-on="on">
                                  <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                </v-btn>
                              </template>
                              
                              <v-list-item-group
                                color="primary"
                                class="actions"
                                >

                                  <v-list-item
                                    v-if="item.invoice == null"
                                    @click="linkdebit(item.id,item.organisation_id, item.debit_note_no)"
                                    >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-link-variant</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Link Credit Note</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item
                                    link
                                    :to='{name:"editDebitNote",params:{id:item.id}}'
                                  >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-pencil</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                <v-list-item
                                  link
                                  @click="deleteDebit(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon>mdi-delete</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Remove</v-list-item-title>
                                      </v-list-item-content>
                                </v-list-item>

                              </v-list-item-group>
                            </v-menu>
                          </div>
                      </template>
                    </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- components -->
        <LinkDebitNote v-model="debitnotedialog"/>
      </div>
    </div>

</template>
<style>
  .debitnotesList .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     /*grid-auto-columns: minmax(12.5%, 12.5%) !important;*/
     grid-template-columns: 12.5% 10.5% 10.5% 18.5% 10.5% 12.5% 12.5% 12.5%;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
  .v-tabs-bar,.v-tabs-bar__content{
    background-color: #fff !important;
  }
  .v-tabs-items{
    background-color: #fff !important;
    margin-top:.3rem !important;
  }
  .debitnotesList .v-slide-group__content {
    padding: 1rem 0rem !important;
  }
  .theme--light.v-tabs>.v-tabs-bar,.v-tabs-slider{
    background: transparent !important;
  }
  a.v-tab{
    font-size: .65rem !important;
  }
  .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
    background:#f8f8f8;
  }
</style>
<style scoped>

    .v-tabs ::v-deep .v-tabs-slider-wrapper {
      transition: none !important;
    }
    .v-tabs-slider-wrapper{
      display: none !important;
    }
    .v-slide-group__content{
      width:100% !important;
      justify-content: flex-end !important;
    }
    .v-tab{
      padding:.2rem 1.5rem !important;
      border-radius: 50px !important;
      height:1.8rem !important;
      
    }
    .v-tab.v-tab--active{
      background:#DEC7FF;
    }

</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import LinkDebitNote from '@/components/debitnote/linkdebitnote'

export default {
    name:"debitnotesList",
    components: { LinkDebitNote },
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          searchfilter:'',
          search:null,
          valid: true,
          tab: null,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          searchinvoice:null,
          tableloader: false,
          dialog: false,
          updatedialog: false,
          debitnotedialog: false,
          fields: [
             {
              text: 'DATE',
              align: 'start',
              value: 'date',
            },
            { text: 'DEBIT NOTE#', value: 'debitnote' },
            { text: 'ORGANISATION', value: 'organisation' },
            { text: 'INVOICE NO', value: 'invoiceno' },
            { text: 'STATUS', value: 'status' },
            { text: 'NOTES', value: 'note' },
            { text: 'AMOUNT', value: 'amount' },
            { text: 'ACTION', value: 'action' },
           ],
          item:{},
            isBusy: false,
            loading: false,
           disabled: false,
           orgCategory: [],
           orgType: [],
           organisation: [],
           height: '100%',
            rowHeight: 25,
            perPage: 50,
            start: 0,
            timeout: null,
            selectedItem: false,
            invoices: []
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = "Debit Notes"

      if(!this.alldebitnotes || this.alldebitnotes.length == 0) {
        this.getDebitNotes()
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    serverPrefetch () {
    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      debitnotes() {
        return this.$store.state.debitnotes.filter(item => {

          this.start = 0

          let invoice_no = "";
          let debit_note_no = "";
          let date = "";
          if(item.date !== null) {
            date = item.date
          }
          if(item.debit_note_no !== null) {
            debit_note_no = item.debit_note_no
          }
          if(item.invoice !== null) {
            invoice_no = item.invoice.invoice_no
          }
          return debit_note_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || date.includes(this.searchfilter)
        })
      },
      alldebitnotes() {
        return this.debitnotes
      },
    },
    methods:{

      async getDebitNotes(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getDebitNotes')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
  
        clear () {
          if(this.$refs.form != undefined) {
            this.$refs.form.reset()
          }
        
        },
      deleteDebit(id){
          if(confirm("Are you sure to delete debit note ?")){
              axios.delete(`/debit-notes/${id}`).then(response=>{
                if(response.data.status == 200) {

                  let index = this.$store.state.debitnotes.findIndex(driver => driver.id == response.data.debit);
                  if (index > -1) {
                    this.$store.state.debitnotes.splice(index, 1);
                  }
                  this.$toast.success('Debit note deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                }
                
              }).catch(error=>{
                  console.log(error)
              })
          }
      },
      linkdebit(id, organisationId, debitNo) {
        this.$store.state.linkdebitdata = {
          id: id,
          organisationid: organisationId,
          tolinkreceipt: debitNo
        }
        this.debitnotedialog = true
      }
    }
}
</script>