<template>
  <div>
    <v-dialog
      max-width="550px"
      v-model="show"
      >
        <v-card>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">
            <v-card-title class="d-flex justify-space-between">
              <div class="loadflex"><span class="text-h6">Record Payment</span>&nbsp;&nbsp;<div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader"></v-progress-circular></div></div>
            </v-card-title>

            <v-card-text>
                <v-container class="mt-0 mx-0 px-0 mb-0">
                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-autocomplete
                          :items="invoices"
                          label="Select invoice"
                          v-model="item.invoice_id"
                          item-text="invoice_no"
                          item-value="id"
                          :search-input.sync="syncinvoices"
                          @change="(event) => selectedInvoice(event)"
                        >
                        </v-autocomplete>
                      </v-col>
                    <v-col sm="12" md="6" cols="12">
                      <v-text-field
                          label="Amount*"
                          v-model="item.amount"
                          hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      >
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Date*"
                            v-bind="attrs"
                            :value="timepickerdate"
                            v-on="on"
                            hide-details
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          scrollable
                          @input="menu2 = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="menu2 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="$refs.menu2.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-autocomplete
                          :items="modes"
                          label="Select payment mode"
                          v-model="item.payment_mode_id"
                          item-text="name"
                          item-value="id"
                          :search-input.sync="paymentmodes"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col sm="12" md="6" cols="12">
                        <v-text-field
                            label="Reference no"
                            v-model="item.reference_no"
                            hide-details
                          ></v-text-field>
                      </v-col>
                  </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click.stop="show = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="submit"
                >
                  Submit
                </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
    </v-dialog>
  </div>
</template>

<style>
  .modal-payment{
    max-width:550px !important;
  }
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'

export default {
  name: 'newreceipt',
  components: {
  },
  data: () => ({
    fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          menu2: false,
          date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        valid: true,
        paymentmodes: null,
        syncinvoices: null,
        isBusy: false,
        loading: false,
        disabled: false,
        item:{
           amount: "",
           payment_mode_id: '',
           reference_no: "",
           invoice_id: "",
           date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        },
       modes: [],
       invoices: []
  }),
  methods: {

    selectedInvoice(id){
      let index = this.invoices.filter(item => item.id === id );
      this.item.amount = index[0].amount
    },

    async getData(){
        this.$store.state.componentloader = true
        await axios.get(`/driver/payment/modes/${this.driverid}`).then(response=>{
            this.$store.state.componentloader = false
            if(response.data.status == parseInt(200)) {
              this.modes = response.data.modes
              this.invoices = response.data.invoices
            }
        }).catch(error=>{
          this.$store.state.componentloader = false
          console.log(error)
        })
      },
    submit(){
      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('invoice_id',this.item.invoice_id)
      fd.append('driver_id',this.driverid)
      fd.append('payment_mode_id',this.item.payment_mode_id)
      fd.append('amount',this.item.amount)
      fd.append('reference_no',this.item.reference_no)
      fd.append('date',this.item.date)

      axios.post('/driver/receipts/new', fd, config).then(response=>{
          this.loading = false
        if(response.data.status == parseInt(200)) {

          let found = this.$store.state.driverreceipts.find(data => data.id == response.data.receipt.id);
          if (!found) {
            this.$store.state.driverreceipts.unshift(response.data.receipt)
          }

          this.$toast.success('Payment submitted successfully','Success',{position: 'topRight',timeout: 7000,})
          this.clear()
          this.show = false
        }
          
        })
        .catch(() => {
          this.loading = false
        })
    },

    clear() {
      this.item.payment_mode_id = null
      this.item.amount = ""
      this.item.reference_no = ""
      this.item.invoice_id = ""
      this.item.date = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    }
  },
  watch: {
      show: function (newVal,oldVal) {
        if(newVal == true) {
          this.getData()
        }
      }
  },
  props: {
     value: Boolean,
     driverid: Number
  },
  computed: {
    loader() {
      return this.$store.state.componentloader
    },
    timepickerdate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
  mounted() {
  },
  created() {}
}
</script>