<template>
  <div class="showinvoicesdetails" style="margin-top:-2.7rem">
    <div class="mx-0 px-0" style="position:relative">
      <v-row class="my-0 py-0" style="margin-top:0 !important;margin-bottom:0 !important">
        <v-col cols="12" class="invoice-content py-0 my-0">
          <div class="column list-column">
            <div class="fill header list-header d-flex">
              <div class="section_title"><span class="title">All Quotations&nbsp;&nbsp;</span> <div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader"
                  ></v-progress-circular></div></div>
            </div>
            <div class="fill body list-body d-flex scroll-y">
              <div class="scroll-y scrollbox">
                <ul class="content-list" id="itemList">
                  <li @click="viewInvoice(quotation.id)" v-for="(quotation,index) in itemsForList" :key="index">
                    <div class="list-title">
                      <span style="font-size:var(--ab-font-size-up) !important" v-if="quotation.organisation !== null">{{ quotation.organisation.name }},</span>
                      <span style="font-size:var(--ab-font-size-up) !important" v-if="quotation.project !== null">{{ quotation.project.name }},</span>
                      <span style="font-size:var(--ab-font-size-up) !important" v-if="quotation.customer !== null">{{ quotation.customer.name }}</span>
                      <span style="font-size:var(--ab-font-size-up) !important">KES{{ quotation.amount | currency('') }}</span>
                    </div>
                    <div class="list-subtitle">
                      <span style="font-size:var(--ab-font-size-up) !important"><a href="" style="color:#F98525 !important;text-decoration:none !important;font-size: var(--ab-font-size-up) !important;font-weight:500 !important">{{ quotation.quotation_no }}</a> | {{ moment(quotation.date).format('MMM Do, YYYY') }}</span>
                      <span style="color:#44C189;font-size: var(--ab-font-size-up) !important" v-if="quotation.invoices.length > 0">INVOICED</span>
                      <span style="font-size:var(--ab-font-size-up) !important" v-else>DRAFT</span>
                    </div>
                  </li>
                </ul>
                <v-pagination
                              v-model="currentPage"
                              :length="Math.ceil(allquotations.length/perPage)"
                              v-if="allquotations.length > 0"
                            ></v-pagination>
              </div>
            </div>
          </div>
          <div class="column content-column">
            <div class="fill header list-header d-flex">
              <div class="section_title"><span class="title" style="color:#888888">{{ details !== null ? details.quotation_no : "" }}&nbsp;&nbsp;</span> <div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader1"
                  ></v-progress-circular></div></div>
            </div>
            <div class="fill subheader list-subheader d-flex">
              <v-btn depressed color="white" class="light" size="sm"><router-link link :to='{name:"editQuotation",params:{id:item.id}}'><v-icon class="mr-1">mdi-pencil</v-icon> Edit</router-link></v-btn>
              <v-btn depressed color="white" class="light" size="sm"><router-link link :to='{name:"convertQuotation",params:{id:item.id}}'><v-icon class="mr-1">mdi-record-circle-outline</v-icon> Convert to Invoice</router-link></v-btn>
              <v-btn depressed color="white" class="light" size="sm" @click="sendquote(item.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                      </svg>&nbsp; Send Quotation
              </v-btn>

              <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon>
                    <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                
                <v-list-item-group
                  color="primary"
                  class="actions"
                  >

                    <v-list-item
                      @click="downloadpdf"
                      >
                        <v-list-item-icon>
                          <v-icon color="#000000">mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <span>Export Pdf</span>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      @click="downloadexcel"
                      >
                        <v-list-item-icon>
                          <v-icon color="#000000">mdi-file-excel</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <span>Export Excel</span>
                        </v-list-item-content>
                    </v-list-item>

                </v-list-item-group>
              </v-menu>

            </div>
            <div class="fill body list-body d-flex scroll-y">
              <div class="scroll-y scrollbox">
                <div class="invoice-template" v-if="details !== null">
                  <div class="ribbon ribbon-top-right" v-if="details.invoices.length > 0">
                    <span>INVOICED</span>
                  </div>
                  <div v-else class="ribbon ribbon-top-right">
                    <span>DRAFT</span>
                  </div>
                  <v-row class="mt-4">
                    <v-col cols="12" md="6">
                      <img src="/assets/images/logo/logo.png" style="width:50% !important;">
                      <p class="mt-2" style="font-size:.75rem !important;line-height:1.6">P.O Box 6137-00200-Nairobi</br>
                      Kandara Road, Hse 9, Kileleshwa</br>
                      Telephone: 0202011846/2011824</br>
                      Cellphone: 0721400422/0728600654/<br>0733523047/0735009952</br>
                      Email: <a href="mailto:info@absoluteadventuresafaris.com" target="_blank">info@absoluteadventuresafaris.com</a></br>
                      PIN NO: P051199717F | VAT NO: P051199717F</p>

                      <p style="font-size:.92rem !important;margin-top:2rem !important">Bill To</p>
                      <p style="color:#F98525;margin-top:-.8rem !important">
                        <span v-if="details.organisation !== null" style="font-size:var(--ab-font-size-up) !important;text-transform:uppercase !important">{{ details.organisation.name }},<br></span>
                        <span v-if="details.project !== null" style="font-size:var(--ab-font-size-up) !important;text-transform:uppercase !important">{{ details.project.name }},<br></span>
                        <span v-if="details.customer !== null" style="font-size:var(--ab-font-size-up) !important;text-transform:uppercase !important">{{ details.customer.name }}<br></span>
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" style="text-align:right">
                      <h2 style="font-size:1.7rem !important">QUOTATION</h2>
                      <p>#{{ details.quotation_no }}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="mt-1"
                      >
                      <p style="font-size:var(--ab-font-size-up) !important;margin-top:0rem !important;">{{ details.subject !== null && details.subject !== 'null' ? details.subject : "" }}</p>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      >
                        <table class="mt-2 custom-font-size">
                          <tr style="background:#dedede !important">
                            <td style="font-size: var(--ab-font-size-up) !important"><strong>#</strong></td>
                            <td style="font-size: var(--ab-font-size-up) !important"><strong>Description</strong></td>
                            <td style="font-size: var(--ab-font-size-up) !important"><strong>Qty</strong></td>
                            <td style="font-size: var(--ab-font-size-up) !important"><strong>Rate</strong></td>
                            <td style="font-size: var(--ab-font-size-up) !important"><strong>Amount</strong></td>
                          </tr>
                          <tr v-for="(item,index) in details.items" :key="index">
                            <td style="font-size: var(--ab-font-size-up) !important">{{ ++index }}</td>
                            <td style="font-size: var(--ab-font-size-up) !important">{{ item.details }}</td>
                            <td style="font-size: var(--ab-font-size-up) !important">{{ item.quantity | currency('') }}</td>
                            <td style="font-size: var(--ab-font-size-up) !important">{{ item.rate | currency('') }}</td>
                            <td style="font-size: var(--ab-font-size-up) !important">{{ item.amount | currency('') }}</td>
                          </tr>
                        </table>
                      </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      class="subtotals"
                      >
                        <table class="mt-3 custom-font-size">
                            <tr>
                              <td style="font-size: var(--ab-font-size-up) !important"><strong>Sub Total</strong></td>
                              <td style="font-size: var(--ab-font-size-up) !important">{{ (details.items.reduce( (acc, item) => 
                                      {  

                                        return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                                      }, 0)) | currency('') }}</td>
                            </tr>
                            <tr>
                              <td style="font-size: var(--ab-font-size-up) !important"><strong>Vat</strong><span v-if="vat != null">({{ vat.rate }}%)</span></td>
                              <td style="font-size: var(--ab-font-size-up) !important">{{ ((((details.items.reduce( (acc, item) => 
                                  {  

                                    return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                                  }, 0))*(vat != null && details.includevat == 'true' ? vat.rate : 0))/100)) | currency('') }}
                              </td>
                            </tr>
                            <tr>
                              <td style="font-size: var(--ab-font-size-up) !important"><strong>Total</strong></td>
                              <td style="font-size: var(--ab-font-size-up) !important">
                                {{

                                  (details.items.reduce( (acc, item) => 
                                    {  

                                      return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                                    }, 0) + ((((details.items.reduce( (acc, item) => 
                                    {  

                                      return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                                    }, 0))*(vat != null && details.includevat == 'true' ? vat.rate : 0))/100)) ) | currency('')

                                }}
                              </td>
                            </tr>
                        </table>
                      </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

    </div>

    <!-- components -->
    <SendQuotation v-model="quotationdialog"/>

  </div>
</template>
<style>
  .modal-quotation{
    max-width:750px !important;
  }
  .action-dropdown-menu .action-dropdown-item:hover{
    background:var(--ab-primary);
    color:#fff;
    border-radius:var(--ab-dropdown-border-radius);
  }
  .action-dropdown-menu{
    padding-right: .5rem;
  }
  .quotationed .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(16.666667%, 16.666667%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .quotationed .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }

</style>
<style scoped>
  .quotationed .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(16.666667%, 16.666667%) !important;
     grid-gap: 0px;
  }

  .quotationed .scroller{
    height:30vh !important;
  }
  
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import { format, parseISO } from 'date-fns'

import SendQuotation from '@/components/quotations/sendquotation'

 export default {
  name: 'showQuotation',
  components: { SendQuotation },
  computed: {
    mini() {
      return this.$store.state.mini
    },
    loader() {
      return this.$store.state.tableloader
    },
    loader1() {
      return this.$store.state.tableloader1
    },
    allquotations() {
      return this.$store.state.showquotations
    },
    details() {
      return this.$store.state.quotationdetail
    },
    rows() {
      return this.allquotations.length
    },
    vat() {
      return this.$store.state.vat
    },
    itemsForList() {
      return this.allquotations.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    }
  },
  data: () => ({
      perPage: 15,
      currentPage: 1,
      daterange: {
        start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
      },
      searchfilter: '',
      search: '',
      dialog: false,
      searchdialog: false,
      loading: false,
      paymentdialog: false,
      quotationdialog: false,
      fields: [
        { text: 'Name', value: 'name' },
        { text: 'Date', value: 'date' },
        { text: 'From', value: 'from' },
        { text: 'To', value: 'to' },
        { text: 'Vehicle', value: 'vehicletype' },
        { text: 'Dist', value: 'distance' },
        // { text: 'Cost', value: 'cost' },
        // { text: 'Vat', value: 'vat' },
        // { text: 'Total', value: 'total' },
       ],
      item:{
         id: 0
      },
  }),

  mounted() {

    this.$store.state.quotationdetail = null
    this.item.id = this.$route.params.id
    this.getDetails()
    if(!this.allquotations || this.allquotations.length == 0) {
      this.getQuotations()
    }
      
  },
  beforeMount() {
    if(!ability.can('organisation-management', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },
  serverPrefetch () {},

  methods: {
    sendquote(id) {
      this.$store.state.itemid = id
      this.quotationdialog = true
    },
    viewInvoice(id) {
        this.item.id = id
        this.$store.state.itemid = id
        this.getDetails()
      },
      async getQuotations(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getShowQuotations')
      },
      async getDetails() {
        this.$store.state.tableloader1 = true
        await axios.get(`/quotation/vat/${this.item.id}`).then(response=>{
          this.$store.state.tableloader1 = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.vat = response.data.vat
              this.$store.state.quotationdetail = response.data.quotation
            }
        }).catch(error=>{
          this.$store.state.tableloader1 = false
            console.log(error)
        })
      },
      async downloadpdf() {
        this.$store.state.tableloader1 = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',this.item.id)

        axios.post('/quotation/download', fd, config).then(response=>{
         this.$store.state.tableloader1 = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', `${this.details.organisation !== null ? this.details.organisation.name : ""}-${this.details.quotation_no}.pdf`);
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader1 = false
          console.log("Unexpected error occured")
        })
      },

      async downloadexcel() {
        this.$store.state.tableloader1 = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('id',this.item.id)

        axios.post('/quotation/export-excel', fd, config).then(response=>{
          this.$store.state.tableloader1 = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${this.details.organisation !== null ? this.details.organisation.name : ""}-${this.details.quotation_no}.xlsx`);
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader1 = false
          console.log(error)
        })
      },
  }
};

</script>
