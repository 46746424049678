<template>
    <!-- BEGIN: Content-->
    <div class="mt-0 dinvoice">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>
        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
            <v-btn
              color="#FA811E"
              class="ma-1 white--text"
              depressed
              :loading="generateloader"
              @click="generatedocuments"
            >
              <v-icon
                left
                dark
              >
                mdi-file-document-outline
              </v-icon>
              Generate Documents
            </v-btn>
            <v-btn
              color="#f5f5f5"
              class="ma-1"
              depressed
              @click="viewdocuments"
            >
              <v-icon
                left
                dark
              >
                mdi-eye-outline
              </v-icon>
               View Documents
            </v-btn>
            <v-menu offset-x left bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon>
                  <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              
              <v-list-item-group
                color="primary"
                class="actions"
                >
                  <v-list-item
                    @click="exportpdf"
                    >
                      <v-list-item-icon>
                        <v-icon color="#000000">mdi-file-pdf-box</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <span>Export Pdf</span>
                      </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="exportexcel"
                    >
                      <v-list-item-icon>
                        <v-icon color="#000000">mdi-file-excel-box</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <span>Export Excel</span>
                      </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="exportvoucher"
                    >
                      <v-list-item-icon>
                        <v-icon color="#000000">mdi-file-pdf-box</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <span>Export Voucher</span>
                      </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>
            </v-menu>
        </div>
      </div>
      <div class="Reports">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row>
            <v-col cols="12" class="pb-0 mb-0 px-0">
              <div class="summaryAlert px-4">
               <!--  <div class="mb-2">
                  <span class="summaryTitle">Payment Summary <span v-if="reportdata.organisation_id.length > 0">| {{ reportdata.organisation_id[0].name }}</span></span>
                </div> -->
                <div class="summaryTotals mt-2">
                  <div class="totals">
                    <span class="font-normal">Sub Total</span>
                    <span class="font-medium">KES{{

                            (this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(parseInt(item.movement_id) == 0) {
                                    if(item.organisationrate.length > 0) {
                                      if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                        amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                      }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                        amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                      }else {
                                        amt = parseInt(item.organisationrate[0].base_rate)
                                      }
                                    }
                                  }else {
                                    amt = Math.round(item.amount)
                                  }
                                return acc += amt; 
                              }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0)) | currency('')

                          }}</span>
                  </div>
                  <div class="mx-5 arrow-right"></div>
                  <div class="totals">
                    <span class="font-normal">Vat</span>
                    <span class="font-medium">KES{{

                            ((((this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(parseInt(item.movement_id) == 0) {
                                    if(item.organisation.vat !== '0') {
                                      if(item.organisationrate.length > 0) {
                                        if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                          amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                        }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                          amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                        }else {
                                          amt = parseInt(item.organisationrate[0].base_rate)
                                        }
                                      }
                                    }
                                  }else {
                                    amt = Math.round(item.amount)
                                  }
                                return acc += amt; 
                              }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0))*(vat != null ? vat.rate : 0))/100)) | currency('')

                          }}</span>
                  </div>
                  <div class="mx-5 arrow-right"></div>
                  <div class="totals">
                    <span class="font-normal">Total</span>
                    <span class="font-medium">KES{{

                            (this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0;

                              if(item.expressway.length > 0) {
                                amt = Math.round(item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0))
                              }
                              
                              return acc += amt; 

                            }, 0) + ((((this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(parseInt(item.movement_id) == 0) {
                                    if(item.organisation.vat !== '0') {
                                      if(item.organisationrate.length > 0) {
                                        if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                          amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                        }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                          amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                        }else {
                                          amt = parseInt(item.organisationrate[0].base_rate)
                                        }
                                      }
                                    }
                                  }else {
                                    amt = Math.round(item.amount)
                                  }
                                return acc += amt; 
                              }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0))*(vat != null ? vat.rate : 0))/100)) + this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(parseInt(item.movement_id) == 0) {
                                    if(item.organisationrate.length > 0) {
                                      if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                        amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                      }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                        amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                      }else {
                                        amt = parseInt(item.organisationrate[0].base_rate)
                                      }
                                    }
                                  }else {
                                    amt = Math.round(item.amount)
                                  }
                                return acc += amt; 
                              }, 0)) | currency('')

                          }}</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row style="position:relative !important;z-index:1 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12">
              <v-card class="card-shadow py-0">
                <v-card-title style="display:block;">
                  <v-row class="pa-1">
                    
                      <Search/>

                  </v-row>
                </v-card-title>
                <v-card-text class="pb-0 px-0 cardtext3">
                  <v-row class="mb-3 mt-3">
                    <v-col cols="12" class="mx-3 my-3 py-0 d-flex align-center" style="height:30px;justify-content:space-between">
                      <div>
                        <label class="form-checkbox" style="font-size:.7rem !important;font-weight:600;color:#333;text-transform:uppercase;margin-right:1rem">
                          <input type="checkbox" v-model="selectAll" @click="select">
                          Select All
                        </label>
                        <v-btn
                          depressed
                          color="grey lighten-2"
                          v-if="selected.length > 0"
                          style="margin-right:2rem"
                          rounded
                          @click="updateselected"
                        >
                          Transfer records
                        </v-btn>
                      </div>
                      <div class="d-flex justify-space-between align-center">
                          <v-switch
                            v-model="grouped"
                            label="Generate Grouped Report ?"
                            hide-details
                            @change="changeState"
                            class="mb-2"
                          ></v-switch>
                        <div style="width:250px !important;display:flex">
                          <v-text-field
                                v-model="search"
                                label="Search"
                                hide-details
                                class="mx-4"
                                solo
                              ></v-text-field>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="empty-state" v-if="allbookingreports.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>
                  <v-virtual-scroll
                      :items="allbookingreports"
                      :item-height="20"
                      renderless 
                      id="virtualscroll"
                      class="invoice"
                     >
                      <template v-slot:default="{ item }">
                          <div class="tcell">
                            <label class="form-checkbox">
                              <input type="checkbox" :value="item.id" v-model="selected">
                              <i class="form-icon"></i>
                            </label>
                          </div>
                          <div class="tcell" @click="bookingdetailsupdate(item.id)">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-if="item.passenger_name !== null" v-bind="attrs"
                                  v-on="on">
                                  {{ item.passenger_name }}
                                </span>
                              </template>
                              <span>
                                <span v-if="item.passenger_name !== null">
                                  {{ item.passenger_name }}
                                </span>
                              </span>
                            </v-tooltip>
                          </div>
                          <div class="tcell">
                            <div>{{ item.telephone }}</div>
                          </div>
                          <div class="tcell">{{ item.pickup_date }} {{ item.pickup_time }}</div>
                          <div class="tcell">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs"
                                  v-on="on">
                                  {{ item.travelfrom }}
                                </span>
                              </template>
                              <span>
                                {{ item.travelfrom }}
                              </span>
                            </v-tooltip>
                          </div>
                          <div class="tcell">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs"
                                  v-on="on">
                                  {{ item.travelto }}
                                </span>
                              </template>
                              <span>
                                {{ item.travelto }}
                              </span>
                            </v-tooltip>
                          </div>
                          <div class="tcell">
                            <span v-if="item.vehicle_type_name !== null">{{ item.vehicle_type_name }}</span>
                            <span v-else>N/A</span>
                          </div>
                          <div class="tcell" @click="editdistance(item.id)">{{ item.distance | currency('') }}</div>
                          <div class="tcell">
                            <div v-if="parseInt(item.movement_id) == 0">
                              <span v-if="item.organisationrate.length > 0">
                                <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                                <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                                <span v-else>{{ item.organisationrate[0].base_rate | currency('') }}</span>
                              </span>
                              <span v-else>0.00</span>
                            </div>
                            <div v-else>{{ item.amount | currency('') }}</div>
                          </div>

                          <div class="tcell" @click="expressway(item.id)">
                            {{ item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0) }}
                          </div>

                          <div class="tcell">
                            <div v-if="parseInt(item.movement_id) == 0">
                              <div v-if="item.organisationrate.length > 0">
                                <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                <span v-else>{{ ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                              </div>
                              <div v-else>0.00</div>
                            </div>
                            <div v-else>{{ ((Math.round(item.amount)*(vat != null ? vat.rate : 0))/100) | currency('') }}</div>
                          </div>
                          <div class="tcell">
                            <div v-if="parseInt(item.movement_id) == 0">
                              <div v-if="item.organisationrate.length > 0">
                                <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                                <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                                <span v-else>{{ (parseInt(item.organisationrate[0].base_rate) + ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                              </div>
                              <div v-else>0.00</div>
                            </div>
                            <div v-else>
                              {{ (Math.round(item.amount) + ((Math.round(item.amount)*(vat != null ? vat.rate : 0))/100)) | currency('') }}
                            </div>
                          </div>
                          <!-- <div class="tcell">{{ item.status }}</div> -->
                          <div class="tcell">
                            <span v-if="item.user_name !== null">
                              {{ item.user_name }}
                            </span>
                            <span v-else>N/A</span>
                          </div>
                        <!--   <div class="tcell">
                            <span v-if="item.approver !== null">
                              {{ item.approver.name }}
                            </span>
                            <span v-else>N/A</span>
                          </div> -->
                          <div class="tcell">
                              <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                      icon
                                      v-bind="attrs"
                                      small
                                      v-on="on">
                                    <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                  </v-btn>
                                </template>
                                
                                <v-list-item-group
                                  color="primary"
                                  class="actions"
                                  >
                                  <v-list-item
                                      @click="bookingprofile(item.id)"
                                      >
                                          <v-list-item-icon>
                                            <v-icon color="#000000">mdi-account</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>Profile</v-list-item-title>
                                          </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                      @click="movement(item.id)"
                                      >
                                        <v-list-item-icon>
                                          <v-icon color="#000000">mdi-radar</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>Change Movement</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                      @click="expressway(item.id)"
                                      >
                                        <v-list-item-icon>
                                          <v-icon color="#000000">mdi-highway</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>Expressway</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                  <v-list-item
                                    link
                                    @click="waiting(item.id)"
                                    >
                                        <v-list-item-icon>
                                          <v-icon>mdi-clock</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>Waitings</v-list-item-title>
                                        </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item
                                    link
                                    @click="voucher(item.ref_no)"
                                    >
                                        <v-list-item-icon>
                                          <v-icon>mdi-ticket-confirmation</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>E-voucher</v-list-item-title>
                                        </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item
                                    @click="bookingdocument(item.id)"
                                    >
                                        <v-list-item-icon>
                                          <v-icon color="#000000">mdi-file-document-multiple-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>Documents</v-list-item-title>
                                        </v-list-item-content>
                                  </v-list-item>

                                </v-list-item-group>
                              </v-menu>
                          </div>
                      </template>
                    </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <EditDistance v-model="editdistancedialog" :bookingid="bookingid"/>
        <ExpresswayDetail v-model="expresswaydialog" :expresswaydetails="expresswaydetails"/>
        <MovementDetail v-model="movementdialog" :movementdetails="movementdetails"/>
        <WaitingDetail v-model="waitingdialog" :waitingdetails="waitingdetails"/>
        <ClientProfile v-model="profiledialog" :profiledetails="profiledetails"/>
        <UpdateDetail v-model="updatedialog" :updatedetails="updatedetails"/>
        <UpdateAll v-model="updatealldialog" :selected="selected"/>
        <BookingDocument v-model="bookingdocumentdialog" v-if="booking_id !== null"/>
        <GenerateReport v-model="generatereport"/>
        <Confirm v-model="confirmdialog"/>

      </div>
    </div>
</template>
<style>
    .Reports .v-virtual-scroll__item{
      width:100%;
      display: grid !important;

       /*grid-auto-columns: minmax(4%, 7.691%) !important;*/
       grid-template-columns:2% 10.691% 7.691% 7.691% 12.691% 12.691% 7.691% 4.691% 4.691% 4.691% 5.691% 5.691% 7.691% 5.691%;
       grid-auto-flow: column !important;
       grid-gap: 0px;
    }
    .dinvoice .reportrange-text input{
      background-color: #f5f5f5 !important;
      padding: 1.1rem !important;
      border-radius: 5px !important;
    }
    .dinvoice .reportrange-text .v-label{
      padding-left: .8rem !important;
    }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import {ability} from '@/services/ability'
import $ from 'jquery';
import { format, parseISO } from 'date-fns'

import EditDistance from '@/components/subcomponents/editDistance'
import ExpresswayDetail from '@/components/subcomponents/expressway'
import MovementDetail from '@/components/subcomponents/movement'
import WaitingDetail from '@/components/subcomponents/waiting'
import ClientProfile from '@/components/bookings/submodules/today/clientprofile'
import UpdateDetail from '@/components/subcomponents/updatedetails'
import UpdateAll from '@/components/subcomponents/updateall'
import BookingDocument from '@/components/subcomponents/bookingDocument'
import Search from '@/components/reports/search'
import GenerateReport from '@/components/reports/generatereport'
import Confirm from '@/components/reports/confirm'

export default {
    name:"InvoiceReport",
    components: { DateRangePicker,ExpresswayDetail,MovementDetail,WaitingDetail,ClientProfile,EditDistance,UpdateDetail,UpdateAll,BookingDocument, Search, GenerateReport,Confirm },
    data(){
        return {
          confirmdialog: false,
          selected: [],
          selectAll: false,
          generatereport: false,
          dateRange: {
            startDate: null,
            endDate: null
           },
          singleDatePicker: true,
          showWeekNumbers: true,
          showDropdowns: true,
          autoApply: true,
          alwaysshowcalendars: true,
          date: "",

          valid: true,
            isBusy: false,
            loading: false,
           disabled: false,
           Organisation: [],
           searchfilter: "",
           search: "",
           fields: [
            { text: '', value: '' },
            { text: 'Name', value: 'name' },
            { text: 'Telephone', value: 'telephone' },
            // { text: 'Project', value: 'project' },
            { text: 'Date', value: 'date' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            // { text: 'Stop', value: 'stop' },
            { text: 'VehicleType', value: 'vehicletype' },
            { text: 'Distance', value: 'distance' },
            { text: 'Cost', value: 'cost' },
            { text: 'Xway', value: 'expressway' },
            { text: 'Vat', value: 'vat' },
            { text: 'Total', value: 'total' },
            // { text: 'Status', value: 'status' },
            { text: 'Bookedby', value: 'bookedby' },
            // { text: 'Approver', value: 'approver' },
            { text: 'Action', value: 'action' },
           ],
            searchRes: "",
            searchDate: "",
            searchTime: "",
            direction: "",
            show: false,
            dates: {},
            daterange: {
              start: '',
              end: ''
            },
            grouped: true,
            generateloader: false,
            viewloader: false,
            loadingvoucher: false,
            loadingsearch: false,
            rowHeight: 25,
            perPage: 200,
            start: 0,
            timeout: null,
            expresswaydialog: false,
            movementdialog: false,
             editdistancedialog: false,
             bookingid: null,
             expresswaydetails: [],
             movementdetails: [],
             updatedetails: [],
             waitingdialog: false,
             waitingdetails: [],
             profiledetails: [],
             profiledialog: false,
             updatedetails: [],
             updatedialog: false,
             updateall: [],
             updatealldialog: false,
             bookingdocumentdialog: false,
 
        }
    },
    beforeMount() {
      this.$store.state.allbookingreports = []
      // if(!ability.can('view-reports', 'all')) {
      //   this.$router.push({ name: 'Dashboard' })
      //   return
      // }
    },
    serverPrefetch () {
    },
    mounted(){

      this.$store.state.loader_text = "Invoice Report"

      if(!this.organisations || this.organisations.length == 0 || this.allcustomers.length == 0) {
        this.getOrgCustomer()
      }

      this.$store.state.reportdata = {
            organisation_id: [],
            project_id: [],
            customer_id: [],
            status: '',
            request_category: '',
            start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
          }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    computed: {
      reportdata () {
        return this.$store.state.reportdata
      },
      organisations() {
        return this.$store.state.reportorganisations
      },
      allcustomers() {
        return this.$store.state.reportcustomers
      },
      loader() {
        return this.$store.state.tableloader
      },
      vat() {
        return this.$store.state.vat
      },
      bookingreports() {
        return this.$store.state.allbookingreports
      },
      allbookingreports() {
        return this.bookingreports.filter(item => {
          this.start = 0
          let name = "";
          if(item.passenger_name != null && item.passenger_name != 'null') {name = item.passenger_name}
            
          return name.toLowerCase().includes(this.search.toLowerCase())
        })
      },
      calcbookingreports() {
        return this.$store.state.allbookingreports.filter(item => {
          this.start = 0
            if(this.selected.includes(item.id) === true) {
              return item
            }else {
              return false
            }

        })
      },
      ...mapState({
        searchFilter: 'searchFilter'
      }),
      booking_id() {
        return this.$store.state.bookingid
      },
    },

    methods:{
      changeState(item) {
        this.$store.state.grouped = item
      },
      bookingdocument(id) {
        let details = this.bookingreports.filter(booking => booking.id === id )

        this.$store.state.bookingid = parseInt(details[0].id)
        this.$store.state.documents = []

        this.$store
          .dispatch('getDocuments',parseInt(details[0].id))
          .then(() => {})
          .catch(() => {})

        this.bookingdocumentdialog = true
      },
      select() {
        this.selected = [];
        if (!this.selectAll) {
          for (let i in this.allbookingreports) {
            this.selected.push(this.allbookingreports[i].id);
          }
        }
      },
      bookingprofile(id) {
        this.$store.state.bookingid = id
        this.profiledialog = true
      },
      bookingdetailsupdate(id) {
        this.updatedetails = this.allbookingreports.filter(booking => booking.id === id )
        this.updatedialog = true
      },
      updateselected() {
        this.updatealldialog = true
      },
      updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.date = this.daterange.start+" - "+this.daterange.end
      },
      editdistance(id) {
        this.bookingid = id
        this.editdistancedialog = true
      },
      expressway(id) {
        this.expresswaydetails = this.allbookingreports.filter(booking => booking.id === id )
        this.expresswaydialog = true
      },
      movement(id) {
        this.movementdetails = this.allbookingreports.filter(booking => booking.id === id )
        this.movementdialog = true
      },
      waiting(id) {
        this.waitingdetails = this.allbookingreports.filter(booking => booking.id === id )
        this.waitingdialog = true
      },
      voucher(ref) {
        window.open(`https://e-voucher.absolutecabs.co.ke/customer/e-voucher/${ref}`, '_blank', 'noreferrer');
      },
      getDate(data) {
        return Vue.filter('dateF')(data)
      },
      exportexcel(){
        this.$store.state.tableloader = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('id',JSON.stringify(this.reportdata.organisation_id))
          fd.append('selected',JSON.stringify(this.selected))
          fd.append('request_category',this.reportdata.request_category)
          fd.append('project_id',JSON.stringify(this.reportdata.project_id))
          fd.append('customer_id',JSON.stringify(this.reportdata.customer_id))
          fd.append('start',this.reportdata.start)
          fd.append('end',this.reportdata.end)

        axios.post('/booking/invoice/export/excel', fd, config).then(response=>{
          this.$store.state.tableloader = false
          let data = this.organisations.filter(organisation =>  this.reportdata.organisation_id.includes(organisation.id) )
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          if(data.length > 0) {
            fileLink.setAttribute('download', `${data[0].name}_invoice_${this.reportdata.start}_to_${this.reportdata.end}.xlsx`);
           }else {
            fileLink.setAttribute('download', 'Invoice.xlsx');
           }
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.$store.state.tableloader = false
          console.log("Unexpected error occured")
        })
      },
      exportpdf(){
        this.$store.state.tableloader = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',JSON.stringify(this.reportdata.organisation_id))
          fd.append('selected',JSON.stringify(this.selected))
          fd.append('request_category',this.reportdata.request_category)
          fd.append('project_id',JSON.stringify(this.reportdata.project_id))
          fd.append('customer_id',JSON.stringify(this.reportdata.customer_id))
          fd.append('start',this.reportdata.start)
          fd.append('end',this.reportdata.end)

        axios.post('/booking/invoice/export/pdf', fd, config).then(response=>{
         this.$store.state.tableloader = false
         let data = this.organisations.filter(organisation =>  this.reportdata.organisation_id.includes(organisation.id) )
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         if(data.length > 0) {
          fileLink.setAttribute('download', `${data[0].name}_invoice_${this.reportdata.start}_to_${this.reportdata.end}.pdf`);
         }else {
          fileLink.setAttribute('download', 'Invoice.pdf');
         }
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.$store.state.tableloader = false
          console.log("Unexpected error occured")
        })
      },
      exportvoucher(){
        this.$store.state.tableloader = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',JSON.stringify(this.reportdata.organisation_id))
          fd.append('selected',JSON.stringify(this.selected))
          fd.append('request_category',this.reportdata.request_category)
          fd.append('project_id',JSON.stringify(this.reportdata.project_id))
          fd.append('customer_id',JSON.stringify(this.reportdata.customer_id))
          fd.append('start',this.reportdata.start)
          fd.append('end',this.reportdata.end)

        axios.post('/booking/invoice/voucher/pdf', fd, config).then(response=>{
         this.$store.state.tableloader = false
         let data = this.organisations.filter(organisation =>  this.reportdata.organisation_id.includes(organisation.id) )
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         if(data.length > 0) {
          fileLink.setAttribute('download', `${data[0].name}_booking_voucher_${this.reportdata.start}_to_${this.reportdata.end}.pdf`);
         }else {
          fileLink.setAttribute('download', 'booking_voucher.pdf');
         }
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.$store.state.tableloader = false
          console.log("Unexpected error occured")
        })
      },
      generatedocuments(){
        this.generateloader = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }};
          let fd = new FormData();
          fd.append('id',JSON.stringify(this.reportdata.organisation_id))
          fd.append('selected',JSON.stringify(this.selected))
          fd.append('grouped',this.grouped)
          fd.append('request_category',this.reportdata.request_category)
          fd.append('project_id',JSON.stringify(this.reportdata.project_id))
          fd.append('customer_id',JSON.stringify(this.reportdata.customer_id))
          fd.append('start',this.reportdata.start)
          fd.append('end',this.reportdata.end)

        axios.post('/report/generate-documents', fd, config).then(response=>{
         this.generateloader = false
         if(response.data.status === parseInt(200)) {

            this.$store.state.confirm_title = "Documents Request";
            this.$store.state.confirm_message = "Your requested documents will be generated in a short while. To download the documents once generated click on the <b>View Documents</b> button.";
            this.confirmdialog = true

         }
          
        })
        .catch(() => {
          this.generateloader = false
          console.log("Unexpected error occured")
        })
      },
      viewdocuments() {
        this.generatereport = true
      },
      getOrgCustomer(){

        this.$store.state.tableloader = true

        this.$store
          .dispatch('getOrgCustomer')
          .then(() => {
          })
          .catch(() => {
          })
      },

    }
}
</script>\