<template>
	<div>
	  <!-- confirm modal -->
      <v-dialog
        v-model="show"
        max-width="350px"
        >
          
          <v-card
            class="mx-auto profiled"
          >
            <div
              style="width:100%;background:#F9780D;height:100% !important;display:flex;justify-content:center"
            >

            <div class="confirm__icon">
              <img
                src="@/assets/send.svg"
                style="width:80%"
              />
            </div>

            <v-btn
              color="black"
              icon
              style="position:absolute;top:.5rem;right:0"
              @click="show = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </div>
            <div class="confirm__content-wrapper">
               <h1 v-html="title"></h1>
               <p v-html="message"></p>
               <v-btn
                  rounded
                  color="#FDE3CE"
                  depressed
                  large
                  @click="show = false"
                >
                  CLOSE
                </v-btn>
            </div>
          </v-card>

      </v-dialog>
      <!-- end confirm modal -->
	</div>
</template>

<style>
.confirm__icon{
  width:150px;
  height:150px;
  border-radius: 100%;
  background:#FCC497;
  margin-top:1.5rem;
  margin-bottom:1.5rem;
}
.confirm__content-wrapper{
  width:100%;
  background:#ffffff;
  height:210px;
  padding:1rem;
  text-align: center;
  margin: auto;
}
.confirm__content-wrapper h1{
  font-size:16px !important;
  margin-bottom:1rem !important;
}
.confirm__content-wrapper p, .confirm__content-wrapper p > b{
  font-size:14px !important;
}
</style>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'confirm',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        labelpickupl: "",
        labeldropl:"",
        directionsService: null,
        directionsDisplay: null,
        coords: {lat: 0, lng: 0},
        destination: {lat: 0, lng: 0},

	}),
	methods: {

	},
	props: {
     value: Boolean
  },
  computed: {
    title() {
      return this.$store.state.confirm_title
    },
    message() {
      return this.$store.state.confirm_message
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
  watch: {},
mounted() {

},
created() {
   
  }
}
</script>