<template>
	<v-col cols="12" sm="12" class="solo-wrapper px-0 mx-0">
    <v-form class="pt-1"  enctype="multipart/form-data" v-model="valid" ref="form">
      <v-row>
        <v-col
          cols="12"
          xl="2"
          md="2"
          class="py-0"
          >
          <date-range-picker
            ref="picker"
            :showWeekNumbers="showWeekNumbers"
            :showDropdowns="showDropdowns"
            :autoApply="autoApply"
            opens="right"
            :always-show-calendars="alwaysshowcalendars"
            v-model="dateRange"
            @select="updateValues"
          >
              <template v-slot:input="picker" style="min-width: 350px;">
                  <v-text-field
                    label="Select date range"
                    :rules="fieldRules"
                    v-model="date"
                    hide-details
                    solo
                  ></v-text-field>
              </template>
          </date-range-picker>
        </v-col>

        <v-col
          cols="12"
          xl="2"
          md="2"
          class="py-0"
          >
          <v-autocomplete
              :items="organisations"
              label="Select organisation"
              v-model="item.organisation_id"
              item-text="name"
              item-value="id"
              :search-input.sync="searchorganisation"
              multiple
              chips
              hide-details
              solo
            >
              <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeorganisation(data.item)"
                  >
                    {{ data.item.name.substring(0,8)+".." }}
                  </v-chip>
                </template>
            </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          xl="2"
          md="2"
          class="py-0"
          >
          <v-autocomplete
              :items="projects"
              label="Select project"
              v-model="item.project_id"
              item-text="name"
              item-value="id"
              :search-input.sync="searchproject"
              hide-details
              chips
              multiple
              solo
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeproject(data.item)"
                >
                  {{ data.item.name.substring(0,8)+".." }}
                </v-chip>
              </template>
            </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          xl="2"
          md="2"
          class="py-0"
          >
          <v-autocomplete
              :items="customers"
              label="Select staff"
              v-model="item.customer_id"
              item-text="name"
              item-value="id"
              :search-input.sync="searchcustomer"
              hide-details
              chips
              multiple
              solo
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removecustomer(data.item)"
                >
                  {{ data.item.name.substring(0,8)+".." }}
                </v-chip>
              </template>
            </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          xl="2"
          md="2"
          class="py-0"
          >
          <v-autocomplete
            :items="['Client', 'Delivery']"
            label="Select request category"
            v-model="item.request_category"
            :rules="fieldRules"
            :search-input.sync="searchrequestcategory"
            hide-details
            clearable
            solo
            @click:clear="item.request_category = ''"
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          xl="2"
          md="2"
          class="py-0 custom_1btn"
          >
            <v-btn
              color="primary darken-1"
              :loading="loading"
              :disabled="!valid"
              @click="fetchreport"
              class="btn-size"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { format, parseISO } from 'date-fns'

export default {
	name: 'reportdialog',
	components: { DateRangePicker },
	data: () => ({
    fieldRules: [
          (v) => !!v || 'Field is required'
          ],
    searchorganisation: null,
    searchproject: null,
    searchcustomer: null,
    searchrequestcategory: null,
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    item: {
      request_category: '',
      organisation_id: [],
      project_id: [],
      customer_id: []
     },
    requests: ['Client', 'Delivery'],
    statuses: ['Waiting', 'Active', 'Cancelled', 'Dropped'],
    dateRange: {
      startDate: null,
      endDate: null
     },
    singleDatePicker: true,
    showWeekNumbers: true,
    showDropdowns: true,
    autoApply: true,
    alwaysshowcalendars: true,
    date: "",
    dates: {},
    daterange: {
      start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    },
    rangedate: "",

	}),
	methods: {
    removeproject (item) {
      const index = this.item.project_id.indexOf(item.id)
      if (index >= 0) this.item.project_id.splice(index, 1)
    },
    removecustomer (item) {
      const index = this.item.customer_id.indexOf(item.id)
      if (index >= 0) this.item.customer_id.splice(index, 1)
    },
    removeorganisation (item) {
      const index = this.item.organisation_id.indexOf(item.id)
      if (index >= 0) this.item.organisation_id.splice(index, 1)
    },
    updateValues(val) { 
      this.daterange.start = Vue.filter('date')(val.startDate)
      this.daterange.end = Vue.filter('date')(val.endDate)
      this.date = this.daterange.start+" - "+this.daterange.end
    },
    fetchreport(){
      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('id',JSON.stringify(this.item.organisation_id))
      fd.append('request_category',this.item.request_category)
      fd.append('grouped',this.grouped)
      fd.append('project_id',JSON.stringify(this.item.project_id))
      fd.append('customer_id',JSON.stringify(this.item.customer_id))
      fd.append('start',this.daterange.start)
      fd.append('end',this.daterange.end)

      axios.post('/booking/invoice/admin/reports', fd, config).then(response=>{
        this.loading = false
        if(response.data.status == parseInt(200)) {
          this.$store.state.allbookingreports = response.data.bookings
          this.$store.state.vat = response.data.vat
          this.$store.state.reportdata = {
              organisation_id: this.item.organisation_id,
              project_id: this.item.project_id,
              customer_id: this.item.customer_id,
              request_category: this.item.request_category,
              status: this.item.status,
              start: this.daterange.start,
              end: this.daterange.end
            }
        }
        this.show = false
        
      })
      .catch(() => {
        this.loading = false
        console.log("Unexpected error occured")
      })
    },
	},
	props: {
     value: Boolean
  },
  computed: {
    grouped() {
      return this.$store.state.grouped
    },
    organisations() {
      return this.$store.state.reportorganisations
    },
    projects() {
      if(this.item.organisation_id.length > 0){
        let projects = []
        let data = this.organisations.filter(organisation =>  this.item.organisation_id.includes(organisation.id) )
        for (let i in data) {
          if(data[i].projects.length > 0) {
            projects.push(...data[i].projects);
          }
        }

        return projects
      }else{
        return []
      }       
    },
    allcustomers() {
      return this.$store.state.reportcustomers
    },
    customers() {
      if(this.item.organisation_id.length > 0){
        let data = this.allcustomers.filter(customer => this.item.organisation_id.includes(customer.organisation_id) )
        return data
      }else{
        return []
      }       
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    },
  },
	mounted() { 
    this.$store.state.allbookingreports = [] 
  },
	created() {
     
    }
}
</script>