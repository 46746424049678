<template>
  <div class="settingswrapper mt-0">
    <loader/>
    <div class="mx-0 px-0" style="position:relative">
      <v-row class="subsection pt-5" style="display:flex;align-items:center;justify-content:center">
        <v-col sm="12" xl="8" md="10" lg="9" class="mx-0 px-3">
          <v-card no-body class="settings card-shadow mt-5 pr-2">

            <v-tabs vertical>
              <v-tab>SMS Credentials</v-tab>
              <v-tab>Waiting Time</v-tab>
              <v-tab>VAT</v-tab>

              <v-tab-item>
                <v-card-text class="px-0 py-0 mx-0">
                  <v-row>
                    <v-col cols="12" style="display:flex;align-items:center;justify-content:flex-end">
                      <v-btn depressed color="primary" class="primary" size="sm" @click.stop="bulksmsdialog = true">Update Details <v-icon class="mr-1">mdi-plus</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" class="mt-2">
                      <v-simple-table dense >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>Api Key</th>
                              <th>Partner ID</th>
                              <th>Shortcode</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><em>{{ $store.state.settingsapikey }}</em></td>
                              <td><em>{{ $store.state.settingspartnerid }}</em></td>
                              <td><em>{{ $store.state.settingsshortcode }}</em></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>

              <v-tab-item>
                <v-card-text class="px-0 py-0">
                  <v-row>
                    <v-col cols="12" style="display:flex;align-items:center;justify-content:flex-end">
                      <v-btn depressed color="primary" class="primary" size="sm" @click.stop="waitingtimedialog = true">Update Waitings <v-icon class="mr-1">mdi-plus</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" class="mt-2">
                      <v-simple-table dense >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>Vehicle</th>
                              <th>Free Minutes</th>
                              <th>Rate/Minute</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item,index) in ratecharges" :key="index">
                              <td><span>{{ item.type.type }}</span></td>
                              <td><span>{{ item.minutes }}</span></td>
                              <td><span>{{ item.rate }}</span></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>

              <v-tab-item>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" style="display:flex;align-items:center;justify-content:flex-end">
                      <v-btn depressed color="primary" class="primary" size="sm" @click.stop="vatdialog = true">Update Vat <v-icon class="mr-1">mdi-plus</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" class="mt-2">
                      <v-simple-table dense >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>VAT(%)</th>
                              <th>Witholding Tax(%)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><em>{{ $store.state.settingsvat }}</em></td>
                              <td><em>{{ $store.state.settingswitholdingtax }}</em></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>

            </v-tabs>

          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- components -->
    <BulkSms v-model="bulksmsdialog"/>
    <WaitingTime v-model="waitingtimedialog"/>
    <Vat v-model="vatdialog"/>

  </div>
</template>

<style scoped>

</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

import BulkSms from '@/components/settings/BulkSms'
import WaitingTime from '@/components/settings/WaitingTime'
import Vat from '@/components/settings/Vat'
import loader from '@/components/loader'

 export default {
  name: 'Settings',
  components: {BulkSms,WaitingTime,Vat,loader},
  computed: {
    mini() {
      return this.$store.state.mini
    },
    loader() {
      return this.$store.state.tableloader
    },
    ratecharges() {
      return this.$store.state.settingsratecharges
    },
  },
  data: () => ({
      bulksmsdialog: false,
      waitingtimedialog: false,
      vatdialog: false,
      searchfilter: '',
      search: '',
      loading: false,
      fields: [
        { text: 'Name', value: 'name' },
        { text: 'Telephone', value: 'telephone' },
        { text: 'Email', value: 'email' },
        { text: 'Account Type', value: 'account' },
        { text: 'Organisation', value: 'organisation' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
       ],
       item: {
        apikey: '',
        partnerid: '',
        shortcode: '',
        one_trip_rate:"",
        round_trip_rate:"",
        type_id: "",
        rate: "",
        minutes: "",
        vat: ""
       },
  }),

  mounted() {

    this.$store.state.loader_text = "Settings"

    this.getSettingsDetails()
  },
  beforeMount() {
    if(!ability.can('view-reports', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },

  methods: {

      getSettingsDetails(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getConfig')
      },

  }
};

</script>
