<template>
	<div class="sendinvoice">
    <v-dialog
      max-width="650px"
      v-model="show"
      >
        <v-card>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">
            <v-card-title class="d-flex justify-space-between">
              <div style="display:flex !important;"><span class="text-h6">Send Invoice Documents</span>&nbsp;&nbsp;<div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader"></v-progress-circular></div></div>
              <div><v-icon class="" @click="refresh" class="mx-3" style="cursor:pointer">mdi-history</v-icon></div>
            </v-card-title>

            <v-card-text>
                <v-container class="mt-0 mx-0 px-0 mb-0">
                  <v-row>
                    <v-col sm="12" md="12" class="pt-0 mt-0">
                      <div class="details_exp">
                        The email sent will have an attachment of the invoice in Pdf, Excel formats.<br>If the invoice is associated with e-vouchers, a zipped file of the vouchers will be attached as well.
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="12">
                      <v-row class="">
                        <v-col sm="3">
                          <label for="">To &nbsp;&nbsp;&nbsp;&nbsp;<code @click="showextra = !showextra" style="cursor:pointer;">Cc/Bcc</code>:</label>
                        </v-col>
                        <v-col sm="9">
                          <v-text-field
                              label="To"
                              v-model="item.to"
                              :rules="emailRules"
                              hide-details
                            ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="">
                        <v-col sm="3">
                        </v-col>
                        <v-col sm="9">
                          <v-switch
                            v-model="sendmeacopy" 
                            label="Send me a copy"
                            @change="onSwitchchange"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row class="" v-if="showextra">
                        <v-col sm="3">
                          <label for="">Cc :</label>
                        </v-col>
                        <v-col sm="9">
                          <v-text-field
                              label="Separate emails with comma(,)"
                              v-model="item.cc"
                              hide-details
                            ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="" v-if="showextra">
                        <v-col sm="3">
                          <label for="">Bcc :</label>
                        </v-col>
                        <v-col sm="9">
                          <v-text-field
                              label="Bcc"
                              v-model="item.bcc"
                              :value="item.to"
                              hide-details
                            ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="">
                        <v-col sm="3">
                          <label for="">Subject :</label>
                        </v-col>
                        <v-col sm="9">
                          <v-text-field
                              label="Subject"
                              v-model="item.subject"
                              hide-details
                            ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="">
                        <v-col sm="3">
                        </v-col>
                        <v-col sm="9">
                          <div style="display:flex;align-items:center">
                            <v-icon class="">mdi-paperclip</v-icon> Invoice in PDF,CSV
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="">
                        <v-col sm="3">
                          <label for="body">Email body :</label>
                        </v-col>
                        <v-col sm="9">
                          <v-textarea
                            name="input-4-4"
                            label="Email body"
                            v-model="item.body"
                            rows="4"
                            hide-details
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click.stop="show = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="submit"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
        </v-card>
    </v-dialog>
	</div>
</template>

<style>
  .modal-sendinvoice{
    max-width:650px !important;
  }
  .modal-sendinvoice .modal-title{
    width:100% !important;
  }
  .downloadfiles{
    width:60%;
    padding:1rem;
    border-radius:4px;
    margin-bottom:1rem;
  }

</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'

export default {
	name: 'sendinvoice',
	components: { },
	data: () => ({
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
    valid: true,
    showextra: false,
    sendmeacopy: false,
    isBusy: false,
    loading: false,
    disabled: false,
    details: null,
    item:{
       to: "",
       subject: "",
       body: "",
       cc: "",
       bcc: "",
       attachmentpdf: "",
       base64pdf: "",
       attachmentexcel: "",
       base64excel: "",
       attachmentvoucher: "",
       base64voucher: "",
       clientname: ""
    },
   modes: [],
   email: "",
   filename: "",
   page: null,
   pageCount: 1,
   showAllPages: true
	}),
	methods: {

    refresh() {
      this.getData()
    },

    onSwitchchange($event) {
      if($event === true || $event === 'true' || $event === 'on') {
        this.item.bcc = this.email
      }else {
        this.item.bcc = ""
      }
    },

    async getData(){
        this.$store.state.componentloader = true
        await axios.get(`/invoices/${this.itemid}`).then(response=>{
            this.$store.state.componentloader = false
            if(response.data.status == parseInt(200)) {
              this.details = response.data.details

              this.item.body = `Dear ${this.details.passengers.length > 0 ? this.details.passengers[0].name : (this.details.organisations.length > 0 ? this.details.organisations[0].name : "Customer")},\nPlease review the attached invoice. Feel free to contact us if you have any questions.\n\nRegards,\n${response.data.name}`;
              this.item.to = `${this.details.passengers.length > 0 ? this.details.passengers[0].email : (this.details.organisations.length > 0 ? this.details.organisations[0].email : "")}`;
              this.item.subject = `Invoice ${this.details.invoice_no}`;
              this.item.clientname = this.details.passengers.length > 0 ? this.details.passengers[0].name : (this.details.organisations.length > 0 ? this.details.organisations[0].name : "Customer");
              this.email = response.data.email;

            }
        }).catch(error=>{
          this.$store.state.componentloader = false
          console.log(error)
        })
      },
    submit(){
      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('to',this.item.to)
      fd.append('subject',this.item.subject)
      fd.append('body',this.item.body)
      fd.append('cc',this.item.cc)
      fd.append('bcc',this.item.bcc)
      fd.append('name',this.item.clientname)
      fd.append('grouped',this.$store.state.grouped)
      fd.append('id',this.itemid)

      axios.post('/send/invoice/documents', fd, config).then(response=>{
          this.loading = false
        if(response.data.status == parseInt(200)) {

          this.$toast.success('Invoice sent successfully','Success',{position: 'topRight',timeout: 7000,})
          this.clear()
          this.show = false
        }
          
        })
        .catch(() => {
          this.loading = false
        })
    },

    downloadpdf() {
      if(this.pdf !== '' && this.pdf !== null) {
        const linkSource = `data:application/pdf;base64,${this.pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = `Invoice-${this.details.invoice_no}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },

    downloadexcel() {
      if(this.excel !== '' && this.excel !== null) {

        // var fileURL = window.URL.createObjectURL(new Blob([this.excel]));
        //   var fileLink = document.createElement('a');
        //   fileLink.href = fileURL;
        //   fileLink.setAttribute('download', `Invoice-${this.details.invoice_no}.xlsx`);
        //  document.body.appendChild(fileLink);
        //  fileLink.click();

        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${this.excel}`;
        const downloadLink = document.createElement("a");
        const fileName = `Invoice-${this.details.invoice_no}.xlsx`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },

    downloadvoucher() {
      if(this.voucher !== '' && this.voucher !== null) {
        const linkSource = `data:application/pdf;base64,${this.voucher}`;
        const downloadLink = document.createElement("a");
        const fileName = `Invoice-${this.details.invoice_no}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },

    clear() {
      this.details = null
      this.item.to = ""
      this.item.subject = ""
      this.item.body = ""
      this.item.cc = ""
      this.item.bcc = ""
      this.item.clientname = ""
      this.item.attachmentpdf = ""
      this.item.attachmentexcel = ""
      this.item.attachmentvoucher = ""
      this.item.base64pdf = ""
      this.item.base64excel = ""
      this.item.base64voucher = ""
    }
	},
  watch: {
      show: function (newVal,oldVal) {
        if(newVal == true) {
          this.clear()
          this.getData()
        }
      }
  },
	props: {
     value: Boolean,
     invoiceid: Number
  },
  computed: {
    loader() {
      return this.$store.state.componentloader
    },
    itemid() {
      return this.$store.state.itemid
    },
    pdf() {
      return this.item.base64pdf
    },
    excel() {
      return this.item.base64excel
    },
    voucher() {
      return this.item.base64voucher
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
	mounted() {
  },
	created() {}
}
</script>