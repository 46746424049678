<template>
  <div class="settingswrapper mt-0">
    <loader/>
    <div class="mx-0 px-0" style="position:relative">
      <v-row class="subsection pt-5" style="display:flex;align-items:center;justify-content:center">
      	<v-col sm="12" xl="9" md="12" lg="9" class="mx-0 px-3">
          <v-card no-body class="logs card-shadow mt-5">

            <v-tabs vertical>
              <v-tab @click="onchange('inv')">Invoices</v-tab>
              <v-tab @click="onchange('cr')">Credit Note</v-tab>
              <v-tab @click="onchange('dr')">Debit Note</v-tab>
              <v-tab @click="onchange('pymnt')">Payments</v-tab>
              <v-tab @click="onchange('rev')">Reversals</v-tab>

              <v-tab-item>
                <v-card-text class="px-0 py-0 mx-0">
                  <v-row>
                    <v-col cols="12" class="mt-2">
                      <div class="">
                        <div class="empty-state mt-5" v-if="logs.length === 0">
                          <div class="empty-state__content">
                            <div class="empty-state__icon" style="width:100px !important;height:100px !important">
                              <img src="@/assets/data.svg" style="width:40%">
                            </div>
                            <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                            <div class="empty-state__message" v-else>No records available.</div>
                          </div>
                        </div>
                        <ul class="content-list" id="itemList" v-if="logs.length > 0">
                          <li v-for="(invoice,index) in itemsForList" :key="index">
                            <div>
                              <span>{{ moment(invoice.created_at).format('Do MMM YYYY H:m:s') }}</span>
                              <span style="font-weight:normal !important;text-transform:capitalize">{{ invoice.log }} - #{{ invoice.invoice !== null ? invoice.invoice.invoice_no : '' }}</span>
                            </div>
                            <div style="font-size:12px !important"><em>Action By: {{ invoice.user !== null ? invoice.user.name : '' }}</em></div>
                          </li>
                        </ul>
                        <v-pagination
                              v-model="currentPage"
                              :length="Math.ceil(logs.length/perPage)"
                              v-if="logs.length > 0"
                            ></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>

              <v-tab-item>
                <v-card-text class="px-0 py-0 mx-0">
                  <v-row>
                    <v-col cols="12" class="mt-2">
                      <div class="">
                        <div class="empty-state mt-5" v-if="logs.length === 0">
                          <div class="empty-state__content">
                            <div class="empty-state__icon" style="width:100px !important;height:100px !important">
                              <img src="@/assets/data.svg" style="width:40%">
                            </div>
                            <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                            <div class="empty-state__message" v-else>No records available.</div>
                          </div>
                        </div>
                        <ul class="content-list" id="itemList" v-if="logs.length > 0">
                          <li v-for="(credit,index) in itemsForList" :key="index">
                            <div>
                              <span>{{ moment(credit.created_at).format('Do MMM YYYY H:m:s') }}</span>
                              <span style="font-weight:normal !important;text-transform:capitalize">{{ credit.log }} - #{{ credit.creditnote !== null ? credit.creditnote.credit_note_no : '' }}</span>
                            </div>
                            <div style="font-size:12px !important"><em>Action By: {{ credit.user !== null ? credit.user.name : '' }}</em></div>
                          </li>
                        </ul>
                        <v-pagination
                              v-model="currentPage"
                              :length="Math.ceil(logs.length/perPage)"
                              v-if="logs.length > 0"
                            ></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>

              <v-tab-item>
                <v-card-text class="px-0 py-0 mx-0">
                  <v-row>
                    <v-col cols="12" class="mt-2">
                      <div class="">
                        <div class="empty-state mt-5" v-if="logs.length === 0">
                          <div class="empty-state__content">
                            <div class="empty-state__icon" style="width:100px !important;height:100px !important">
                              <img src="@/assets/data.svg" style="width:40%">
                            </div>
                            <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                            <div class="empty-state__message" v-else>No records available.</div>
                          </div>
                        </div>
                        <ul class="content-list" id="itemList" v-if="logs.length > 0">
                          <li v-for="(debit,index) in itemsForList" :key="index">
                            <div>
                              <span>{{ moment(debit.created_at).format('Do MMM YYYY H:m:s') }}</span>
                              <span style="font-weight:normal !important;text-transform:capitalize">{{ debit.log }} - #{{ debit.debitnote !== null ? debit.debitnote.debit_note_no : '' }}</span>
                            </div>
                            <div style="font-size:12px !important"><em>Action By: {{ debit.user !== null ? debit.user.name : '' }}</em></div>
                          </li>
                        </ul>
                        <v-pagination
                              v-model="currentPage"
                              :length="Math.ceil(logs.length/perPage)"
                              v-if="logs.length > 0"
                            ></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>

              <v-tab-item>
                <v-card-text class="px-0 py-0 mx-0">
                  <v-row>
                    <v-col cols="12" class="mt-2">
                      <div class="">
                        <div class="empty-state mt-5" v-if="logs.length === 0">
                          <div class="empty-state__content">
                            <div class="empty-state__icon" style="width:100px !important;height:100px !important">
                              <img src="@/assets/data.svg" style="width:40%">
                            </div>
                            <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                            <div class="empty-state__message" v-else>No records available.</div>
                          </div>
                        </div>
                        <ul class="content-list" id="itemList" v-if="logs.length > 0">
                          <li v-for="(payment,index) in itemsForList" :key="index">
                            <div>
                              <span>{{ moment(payment.created_at).format('Do MMM YYYY H:m:s') }}</span>
                              <span style="font-weight:normal !important;text-transform:capitalize">{{ payment.log }} - #{{ payment.payment !== null ? payment.payment.payment_no : '' }}</span>
                            </div>
                            <div style="font-size:12px !important"><em>Action By: {{ payment.user !== null ? payment.user.name : '' }}</em></div>
                          </li>
                        </ul>
                        <v-pagination
                              v-model="currentPage"
                              :length="Math.ceil(logs.length/perPage)"
                              v-if="logs.length > 0"
                            ></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>

              <v-tab-item>
                <v-card-text class="px-0 py-0 mx-0">
                  <v-row>
                    <v-col cols="12" class="mt-2">
                      <div class="">
                        <div class="empty-state mt-5" v-if="logs.length === 0">
                          <div class="empty-state__content">
                            <div class="empty-state__icon" style="width:100px !important;height:100px !important">
                              <img src="@/assets/data.svg" style="width:40%">
                            </div>
                            <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                            <div class="empty-state__message" v-else>No records available.</div>
                          </div>
                        </div>
                        <ul class="content-list" id="itemList" v-if="logs.length > 0">
                          <li v-for="(reversal,index) in itemsForList" :key="index">
                            <div>
                              <span>{{ moment(reversal.created_at).format('Do MMM YYYY H:m:s') }}</span>
                              <span style="font-weight:normal !important;text-transform:capitalize">{{ reversal.log }} - #{{ reversal.reversal !== null ? reversal.reversal.reversal_no : '' }}</span>
                            </div>
                            <div style="font-size:12px !important"><em>Action By: {{ reversal.user !== null ? reversal.user.name : '' }}</em></div>
                          </li>
                        </ul>
                        <v-pagination
                              v-model="currentPage"
                              :length="Math.ceil(logs.length/perPage)"
                              v-if="logs.length > 0"
                            ></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>

            </v-tabs>

          </v-card>
      	</v-col>
      </v-row>
    </div>

  </div>
</template>

<style scoped>

</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import loader from '@/components/loader'

 export default {
  name: 'Logs',
  components: { loader },
  computed: {
    loader() {
    	return this.$store.state.tableloader
    },
    ratecharges() {
      return this.$store.state.settingsratecharges
    },
    logs() {
      return this.$store.state.logs
    },
    itemsForList() {
      return this.logs.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    },
    rows() {
      return this.logs.length
    },
  },
  data: () => ({
      bulksmsdialog: false,
      waitingtimedialog: false,
      vatdialog: false,
      searchfilter: '',
      search: '',
      loading: false,
      fields: [
        { text: 'Name', value: 'name' },
        { text: 'Telephone', value: 'telephone' },
        { text: 'Email', value: 'email' },
        { text: 'Account Type', value: 'account' },
        { text: 'Organisation', value: 'organisation' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
       ],
       item: {
        apikey: '',
        partnerid: '',
        shortcode: '',
        one_trip_rate:"",
        round_trip_rate:"",
        type_id: "",
        rate: "",
        minutes: "",
        vat: ""
       },
       perPage: 10,
      currentPage: 1
  }),

  mounted() {

    this.$store.state.loader_text = "Logs"

  	this.getLogs()
  },
  beforeMount() {
    if(!ability.can('view-reports', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },

  methods: {

    onchange(val) {
      this.$store.state.logs = []
      this.$store.state.tableloader = true
      this.$store
        .dispatch('getLogs',val)
    },

    getLogs(){
      this.$store.state.tableloader = true
      this.$store
        .dispatch('getLogs','inv')
    },

  }
};

</script>
