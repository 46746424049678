<template>
	<div>
	  <!-- assign vehicle modal -->
      <v-dialog
        v-model="show"
        max-width="420px"
        >
        <v-card>
          <div style="width:100%;height:.3rem !important">
                  <v-progress-linear
                    color="primary accent-4"
                    indeterminate
                    height="3"
                    :active="addbookingloader"
                  ></v-progress-linear>
                </div>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Upload Document</span>
          </v-card-title>

          <v-card-text>
              <v-container class="px-0 mx-0">
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    >
                    <v-autocomplete
                      :items="organisations" 
                      v-model="item.organisation"
                      label="Select Organisation"
                      item-text="name"
                      item-value="id"
                      :rules="fieldRules"
                      variant="outlined"
                      required
                      clearable
                      color="yellow-lighten-2"
                      :search-input.sync="searchorganisation"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-file-input 
                      label="Excel Document" 
                      density="compact"
                      v-model="item.docfile"
                      v-on:change="onChange"
                      id="file" 
                      name="file"
                      hide-details
                      prepend-icon=""
                      ></v-file-input>
                  </v-col>
                  
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="show = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="submit"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end assign vehicle -->
	</div>
</template>
<style scoped>
  span {
    font-size: 11.5px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'bulkprojects',
	components: {
	},
	data: () => ({
		fieldRules: [
      (v) => !!v || 'Field is required'
    ],
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    searchorganisation:"",
    item: {
      organisation: "",
      docfile: null,
    },
    file: null,

	}),
	methods: {

    onChange(event) {
      if(event) {
        this.file = event;
      }
    },

    getOrganisations(){

        this.$store.state.addbookingloader = true

        this.$store
          .dispatch('getUpdateDetails')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },

		submit(){

         if (this.$refs.form.validate()) {

	        this.loading = true
	        this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation', this.item.organisation)
          fd.append('file', this.file)

          axios.post('/bulk-projects', fd, config).then(response=>{
            if(response.data.status === parseInt(404)) {
              this.$toast.error("Failed uploading file",'Error',{position: 'topRight',messageColor:'#ffffff',timeout: 7000,})
            }
            if(response.data.status === parseInt(401)) {
              this.$toast.warning("Failed uploading file",'Error',{position: 'topRight',timeout: 7000,})
            }

            if(response.data.status === parseInt(200)) {              
              this.$toast.success("Upload was successful",'',{position: 'topRight',timeout: 7000})
              this.$refs.form.reset()
              location.reload();
            }
            this.show = false
            this.loading = false
            this.disabled = false
            
          })
          .catch(() => {
            this.loading = false
            this.disabled = false
          })

	      }

        }
	},
	props: {
	     value: Boolean,
  },
  computed: {
    addbookingloader() {
      return this.$store.state.addbookingloader
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    },
    organisations() {
      return this.$store.state.updateorganisations
    },
  },
  watch: {
      show: function (newVal,oldVal) {
        if(newVal == true) {
          if(this.organisations.length == 0){
            this.getOrganisations()
          }
        }
      }
  },
	mounted() {
    
	},
	created() {
     
    }
}
</script>