<template>

  <div class="sendinvoice">
    <v-dialog
      max-width="950px"
      v-model="show"
      >
        <v-card>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">
            <v-card-title class="d-flex justify-space-between">
              <div style="display:flex !important;"><span class="title">Send Quotation&nbsp;&nbsp;<span v-if="details !== null" style="font-size:14px !important;color:#888888">{{ details.quotation_no }}&nbsp;&nbsp;</span></span> <div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader"></v-progress-circular></div></div>
              <div><v-icon @click="refresh" class="mx-3" style="cursor:pointer">mdi-history</v-icon>
              <v-icon @click="download" class="mx-3" style="cursor:pointer">mdi-download</v-icon></div>
            </v-card-title>

            <v-card-text>
                <v-container class="mt-0 mx-0 px-0 mb-0">
                  <v-row>
                    <v-col sm="6" md="6">
                      <v-row class="">
                        <v-col sm="3">
                          <label for="">To &nbsp;&nbsp;&nbsp;&nbsp;<code @click="showextra = !showextra" style="cursor:pointer;">Cc/Bcc</code>:</label>
                        </v-col>
                        <v-col sm="9">
                          <v-text-field
                              label="To"
                              v-model="item.to"
                              :rules="emailRules"
                              hide-details
                            ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="">
                        <v-col sm="3">
                        </v-col>
                        <v-col sm="9">
                          <v-switch
                            v-model="sendmeacopy" 
                            label="Send me a copy"
                            @change="onSwitchchange"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row class="" v-if="showextra">
                        <v-col sm="3">
                          <label for="">Cc :</label>
                        </v-col>
                        <v-col sm="9">
                          <v-text-field
                              label="Separate emails with comma(,)"
                              v-model="item.cc"
                              hide-details
                            ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="" v-if="showextra">
                        <v-col sm="3">
                          <label for="">Bcc :</label>
                        </v-col>
                        <v-col sm="9">
                          <v-text-field
                              label="Bcc"
                              v-model="item.bcc"
                              :value="item.to"
                              hide-details
                            ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="">
                        <v-col sm="3">
                          <label for="">Subject :</label>
                        </v-col>
                        <v-col sm="9">
                          <v-text-field
                              label="Subject"
                              v-model="item.subject"
                              hide-details
                            ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="">
                        <v-col sm="3">
                        </v-col>
                        <v-col sm="9">
                          <div style="display:flex;align-items:center">
                            <v-icon class="">mdi-paperclip</v-icon> Invoice in PDF,CSV
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="">
                        <v-col sm="3">
                          <label for="body">Email body :</label>
                        </v-col>
                        <v-col sm="9">
                          <v-textarea
                            name="input-4-4"
                            label="Email body"
                            v-model="item.body"
                            rows="4"
                            hide-details
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col sm="6" md="6" class="px-0">
                      <div style="width:100%;max-height:300px;overflow-y:auto;overflow-x:clip;">
                        <vue-pdf-embed
                          ref="pdfRef"
                          :source="pdfSource"
                          :page="page"
                          :annotationLayer="annotationLayer"
                          :textLayer="textLayer"
                          @password-requested="handlePasswordRequest"
                          @rendered="handleDocumentRender"
                          v-if="pdfSource !== null"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click.stop="show = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="submit"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
        </v-card>
    </v-dialog>
  </div>
</template>

<style>
  .modal-sendquotation{
    max-width:950px !important;
  }
  .modal-sendquotation .modal-title{
    width:100% !important;
  }
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
	name: 'sendquotation',
	components: { VuePdfEmbed },
	data: () => ({
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
    valid: true,
    showextra: false,
    sendmeacopy: false,
    textLayer: true,
    annotationLayer: true,
    isBusy: false,
    loading: false,
    disabled: false,
    details: null,
    item:{
       to: "",
       subject: "",
       body: "",
       cc: "",
       bcc: "",
       attachment: "",
       base64: "",
       clientname: ""
    },
   modes: [],
   email: "",
   pdf: "",
   filename: "",
   page: null,
   pageCount: 1,
   showAllPages: true
	}),
	methods: {

    refresh() {
      this.getData()
    },

    handleDocumentRender(args) {
      this.pageCount = this.$refs.pdfRef.pageCount
    },
    handlePasswordRequest(callback, retry) {
      callback(prompt(retry ? 'Enter password again' : 'Enter password'))
    },

    onSwitchchange($event) {
      if($event === true || $event === 'true' || $event === 'on') {
        this.item.bcc = this.email
      }else {
        this.item.bcc = ""
      }
    },

    async getData(){
        this.$store.state.componentloader = true
        await axios.get(`/quotations/${this.itemid}`).then(response=>{
            this.$store.state.componentloader = false
            if(response.data.status == parseInt(200)) {
              this.details = response.data.details

              this.item.body = `Dear ${this.details.customer !== null ? this.details.customer.name : (this.details.organisation !== null ? this.details.organisation.name : "Customer")},\nPlease review the attached quote. Feel free to contact us if you have any questions.\nWe look forward to working with you.\n\nRegards,\n${response.data.name}`;
              this.item.to = `${this.details.customer !== null ? this.details.customer.email : (this.details.organisation !== null ? this.details.organisation.email : "")}`;
              this.item.subject = `Quotation ${this.details.quotation_no}`;
              this.item.attachment = response.data.attachment;
              this.item.base64 = response.data.base64;
              this.item.clientname = response.data.clientname;
              this.email = response.data.email;

              this.$store.state.pdfSource = {
                                              cMapUrl: 'https://unpkg.com/pdfjs-dist/cmaps/',
                                              url: `data:application/pdf;base64,${this.item.base64}`,
                                            }
            }
        }).catch(error=>{
          this.$store.state.componentloader = false
          console.log(error)
        })
      },
    submit(){
      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('to',this.item.to)
      fd.append('subject',this.item.subject)
      fd.append('body',this.item.body)
      fd.append('cc',this.item.cc)
      fd.append('bcc',this.item.bcc)
      fd.append('name',this.item.clientname)
      fd.append('attachment',this.item.attachment)
      fd.append('id',this.itemid)

      axios.post('/send/quotation', fd, config).then(response=>{
          this.loading = false
        if(response.data.status == parseInt(200)) {

          this.$toast.success('Quotation sent successfully','Success',{position: 'topRight',timeout: 7000,})
          this.clear()
          this.show = false
        }
          
        })
        .catch(() => {
          this.loading = false
        })
    },

    download() {
      if(this.item.base64 !== '' && this.item.base64 !== null) {
        const linkSource = `data:application/pdf;base64,${this.item.base64}`;
        const downloadLink = document.createElement("a");
        const fileName = `Quote-${this.details.quotation_no}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },

    clear() {
      this.details = null
      this.item.to = ""
      this.item.subject = ""
      this.item.body = ""
      this.item.cc = ""
      this.item.bcc = ""
      this.item.clientname = ""
      this.item.attachment = ""
      this.item.base64 = ""
    }
	},
  watch: {
      show: function (newVal,oldVal) {
        if(newVal == true) {
          this.$store.state.pdfSource = null
          this.clear()
          this.getData()
        }
      },
      showAllPages() {
        this.page = this.showAllPages ? null : 1
      }
  },
	props: {
     value: Boolean,
     invoiceid: Number
  },
  computed: {
    loader() {
      return this.$store.state.componentloader
    },
    itemid() {
      return this.$store.state.itemid
    },
    pdfSource() {
      return this.$store.state.pdfSource
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
	mounted() {
  },
	created() {}
}
</script>