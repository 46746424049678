<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>

        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-text-field
              v-model="searchfilter"
              label="Search"
              solo
              hide-details="auto"
              class="search_"
            ></v-text-field>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            link
            :to="{name:'newPaymentReversal'}"
          >
            Reversal
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="paymentReversalsList">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">
                <v-card-text class="px-0 py-3 cardtext">
                  <div class="empty-state" v-if="allpaymentreversals.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>
                  <v-virtual-scroll
                    :items="allpaymentreversals"
                    :item-height="20"
                    renderless 
                    id="virtualscroll"
                   >
                    <template v-slot:default="{ item }">
                        <div class="tcell">{{ moment(item.date).format('MMM Do YYYY') }}</div>
                        <div class="tcell"><span style="color:#F98525 !important;text-decoration:none !important">{{ item.reversal_no }}</span></div>
                        <div class="tcell">
                          <span v-if="item.organisation !== null">{{ item.organisation.name }}</span>
                          <span v-else>Private</span>
                        </div>
                        <div class="tcell">
                          <RouterLink v-if="item.payment !== null" style="color:#F98525 !important;text-decoration:none !important" link
                              :to='{name:"showPayment",params:{id:item.payment.id}}'>{{ item.payment.payment_no }}</RouterLink>
                        </div>
                        <div class="tcell">{{ item.notes }}</div>
                        <div class="tcell">KES<span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span></div>
                        <div class="tcell">
                          <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  v-bind="attrs"
                                  small
                                  v-on="on">
                                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            
                            <v-list-item-group
                              color="primary"
                              class="actions"
                              >

                                <v-list-item
                                  v-if="item.invoice == null"
                                  @click="undoreversal(item.id, item.payment.payment_no)"
                                  >
                                    <v-list-item-icon>
                                      <v-icon color="#000000">mdi-link-variant-minus</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Undo Reversal</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item
                                  link
                                  :to='{name:"editPaymentReversal",params:{id:item.id}}'
                                >
                                    <v-list-item-icon>
                                      <v-icon color="#000000">mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                              <v-list-item
                                link
                                @click="deleteReversal(item.id)"
                                >
                                    <v-list-item-icon>
                                      <v-icon>mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Remove</v-list-item-title>
                                    </v-list-item-content>
                              </v-list-item>

                            </v-list-item-group>
                          </v-menu>
                        </div>
                    </template>
                  </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

</template>
<style>
  .paymentReversalsList .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     /*grid-auto-columns: minmax(12.5%, 12.5%) !important;*/
     grid-template-columns: 14.286% 14.286% 14.286% 14.286% 14.286% 14.286% 14.286%;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

export default {
    name:"paymentReversalsList",
    components: {},
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          searchfilter:'',
          search:null,
          valid: true,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          searchinvoice:null,
          dialog: false,
          tableloader: false,
          updatedialog: false,
          linkpaymentdialog: false,
          fields: [
             {
              text: 'DATE',
              align: 'start',
              value: 'date',
            },
            { text: 'REVERSAL#', value: 'reversalno' },
            { text: 'ORGANISATION', value: 'organisation' },
            { text: 'PAYMENT NO', value: 'paymentno' },
            { text: 'NOTES', value: 'note' },
            { text: 'AMOUNT', value: 'amount' },
            { text: 'ACTION', value: 'action' },
           ],
          item:{},
            isBusy: false,
            loading: false,
           disabled: false,
           orgCategory: [],
           orgType: [],
           organisation: [],
           height: '100%',
            rowHeight: 25,
            perPage: 50,
            start: 0,
            timeout: null,
            selectedItem: false,
            invoices: []
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = "Payment Reversals"

      if(!this.allpaymentreversals || this.allpaymentreversals.length == 0) {
        this.getPaymentReversal()
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    serverPrefetch () {},
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      paymentreversals() {
        return this.$store.state.paymentreversals.filter(item => {

          this.start = 0

          let payment_no = "";
          let reversal_no = "";
          let date = "";
          if(item.date !== null) {
            date = item.date
          }
          if(item.payment !== null) {
            payment_no = item.payment.payment_no
          }
          if(item.reversal_no !== null) {
            reversal_no = item.reversal_no
          }
          return payment_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || date.includes(this.searchfilter) || reversal_no.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
      },
      allpaymentreversals() {
        return this.paymentreversals
      },
    },
    methods:{
      async getPaymentReversal(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getPaymentReversal')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
  
      clear () {
        if(this.$refs.form != undefined) {
          this.$refs.form.reset()
        }
      
      },
      deleteReversal(id){
            if(confirm("Are you sure to delete payment ?")){
                axios.delete(`/reversals/${id}`).then(response=>{

                  let index = this.$store.state.paymentreversals.findIndex(data => data.id == response.data.reversal.id);
                  if (index > -1) {
                    this.$store.state.paymentreversals.splice(index, 1);
                  }

                  this.$toast.success('Payment reversal removed successfully','Success',{position: 'topRight',timeout: 7000,})
                }).catch(error=>{
                    console.log(error)
                })
            }
        },

        undoreversal(id, paymentno) {

          this.$store.state.tableloader = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('id',id)
          fd.append('payment_no',paymentno)

          axios.post('/reversal/undo', fd, config).then(response=>{
            this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {

              let index = this.$store.state.paymentreversals.findIndex(data => data.id == response.data.reversal.id);
              if (index > -1) {
                this.$store.state.paymentreversals.splice(index, 1);
              }

              this.$toast.success('Reversal undone successfully','Success',{position: 'topRight',timeout: 7000,})
              this.clear()
            }
            })
            .catch(() => {
              this.$store.state.tableloader = true
            })
      },
    }
}
</script>