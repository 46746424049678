import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vue2Filters from 'vue2-filters'
import axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import moment from 'moment'
import { abilitiesPlugin } from '@casl/vue';
import {ability} from './services/ability';
import { Can } from '@casl/vue'
import 'vuetify/dist/vuetify.min.css'
import 'izitoast/dist/css/iziToast.min.css'
import VueIziToast from 'vue-izitoast'
import "@/assets/style.css"
import "@/assets/css/responsive.css"
import "@/assets/main.css"
// import "@/assets/fonts/mdi.css"
// import "@/assets/css/navigation.css"
// import "@/assets/css/trebuchet/style.css"
// import "@/assets/css/themify.css"
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import HighchartsVue from 'highcharts-vue'

// CSS
import BookingUtils from "@/utils/booking_util"

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

Vue.prototype.moment = moment

Vue.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
  })

Vue.config.productionTip = false

Vue.filter('date', function(val) {
    if (val) {
        return val ? moment(val.toLocaleString()).format("YYYY-MM-DD") : ''
    }
})

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format("MMM Do, YYYY")
    }
})
Vue.filter('dateNo', function(value) {
    if (value) {
        return moment(String(value)).format('D')
    }
})
Vue.filter('dateName', function(value) {
    if (value) {
        return moment(String(value)).format('ddd')
    }
})
Vue.filter('dateF', function(value) {
    if (value) {
        return moment(String(value)).format("MMM Do, YYYY")
    }
})
Vue.filter('timeF', function(value) {
    if (value) {
        return moment(String(value)).format('LT')
    }
})
Vue.filter('timeremaining', function(value) {
    if (value) {
        return moment(String(value)).fromNow()
    }
})
Vue.filter('minutesremaining', function(value) {
    if (value) {
      var nowtime = moment()
      value = moment(String(value))

      var duration = moment.duration(value.diff(nowtime))
      var minutes = parseInt(duration.asMinutes())
        return minutes
        // return moment(String(value)).fromNow()
    }
})


Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBOq3xAvXV2SsaJPxNk3iz4XNIKPdz4gRc",
    // key: "AIzaSyC7ys5xYYXQtN7jV0Oz4m8cu1jct9T1jc8",
    libraries: ["places", "geometry"],
    region: 'KE'
  }
})
Vue.use(Vue2Filters)
Vue.use(VueIziToast)
Vue.use(HighchartsVue)
Vue.use(BookingUtils)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  created () {
    const userInfo = localStorage.getItem('user')
    if (userInfo) {
      const userData = JSON.parse(userInfo)
      this.$store.commit('setUserData', userData)
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  },
  render: h => h(App)
}).$mount('#app')
