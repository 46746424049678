<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>
        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-text-field
              v-model="searchfilter"
              label="Search"
              solo
              hide-details="auto"
              class="search_"
            ></v-text-field>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            link
            :to="{name:'newInvoice'}"
          >
            Invoice
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="invoiceList">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">
                <v-card-title class="mt-0 pt-0">
                  <v-tabs
                    v-model="tab"
                    horizontal
                   >
                    <v-tab link :to='{name:"invoiceList"}'>Invoices</v-tab>
                    <v-tab class="mx-2" link :to='{name:"paymentList"}'>Payments</v-tab>
                    <v-tab class="mx-2" link :to='{name:"creditnotesList"}'>Credit Note</v-tab>
                    <v-tab class="mx-2" link :to='{name:"debitnotesList"}'>Debit Note</v-tab>
                    <v-tab class="mx-2" link :to='{name:"customerStatement"}'>Statement</v-tab>
                  </v-tabs>
                </v-card-title>
                <v-card-text  class="px-0 pt-0 pb-3 cardtext_tax">
                  <div class="empty-state" v-if="allinvoices.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>
                    <v-virtual-scroll
                      :items="allinvoices"
                      :item-height="20"
                      renderless 
                      id="virtualscroll"
                      class="allinvoices"
                     >
                      <template v-slot:default="{ item }">
                          <div class="tcell">{{ moment(item.date).format('MMM Do YYYY') }}</div>
                          <div class="tcell"><RouterLink style="color:#F98525 !important;text-decoration:none !important" link
                                :to='{name:"showInvoice",params:{id:item.id}}'>{{ item.invoice_no }}</RouterLink></div>
                          <div class="tcell">
                            <div v-if="item.organisations.length > 0"><span v-for="(organisation,index) in item.organisations" :key="index">{{ organisation.name }}</span></div>
                              <span v-else>Private</span>
                          </div>
                          <div class="tcell">{{ item.status }}</div>
                          <div class="tcell">KES<span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span></div>
                          <div class="tcell">
                            <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    small
                                    v-on="on">
                                  <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                </v-btn>
                              </template>
                              
                              <v-list-item-group
                                color="primary"
                                class="actions"
                                >

                                <v-list-item
                                    @click="sendinvoice(item.id)"
                                    >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-send-variant-outline</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Send Invoice</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item
                                    @click="reverseInvoice(item.id,item.invoice_no)"
                                    >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-file-undo-outline</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Reverse Invoice</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item
                                    link
                                    :to='{name:"editInvoice",params:{id:item.id}}'
                                  >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-pencil</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                <v-list-item
                                  link
                                  @click="deleteInvoice(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon>mdi-delete</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Remove</v-list-item-title>
                                      </v-list-item-content>
                                </v-list-item>

                              </v-list-item-group>
                            </v-menu>
                          </div>
                      </template>
                    </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <!-- reverse invoices -->
        <v-dialog
          v-model="reverseinvoicesdialog"
          width="70%"
          >
          <v-card>

            <v-card-title class="mb-3" style="display:flex;align-items:center;justify-content:space-between">
              <div style="font-weight:normal !important;font-size:.97rem !important">Reverse Invoice <span style="color:#d95e00;font-size:.97rem !important">{{ this.item.invoice_no }} </span></div>
              <v-col md="2" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                    <v-text-field
                          v-model="searchbookings"
                          label="Search bookings"
                          solo
                          hide-details="auto"
                        ></v-text-field>
                  </v-col>
            </v-card-title>
            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      
                      <v-data-table
                        v-model="reversebookings"
                        dense
                        :headers="reversefields"
                        :items="reversals"
                        class="elevation-0"
                        loading="false"
                        loading-text="Loading... Please wait"
                        show-select
                        disable-pagination
                        hide-default-footer
                        id="virtual-scroll-table1"
                        v-scroll:#virtual-scroll-table="onScroll"
                        fixed-header
                        >

                        <template v-slot:item.passenger="{ item }">
                          <div>{{ item.booking.passenger_name }}</div>
                        </template>
                        <template v-slot:item.date="{ item }">
                          {{ moment(item.booking.pickup_date).format('Y-m-d') }}
                        </template>
                        <template v-slot:item.time="{ item }">
                          {{ item.booking.pickup_time }}
                        </template>
                        <template v-slot:item.ref="{ item }">
                          {{ item.booking.ref_no }}
                        </template>
                        <template v-slot:item.from="{ item }">
                          {{ item.booking.travelfrom }}
                        </template>
                        <template v-slot:item.to="{ item }">
                          {{ item.booking.travelto }}
                        </template>
                        
                        <template v-slot:item.distance="{ item }">
                          {{ item.booking.distance | currency('') }}
                        </template>
                      </v-data-table>

                    </v-col>
                    
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click="reverseinvoicesdialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :loading="loading"
                  @click="reverseForm"
                >
                  Reverse
                </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
        <!-- end reverse invoices -->
      </div>

      <!-- components -->
      <SendInvoice v-model="invoicedialog"/>

    </div>

</template>
<style>
  .invoiceList .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     /*grid-auto-columns: minmax(16.667%, 16.667%) !important;*/
     grid-template-columns: 20% 10% 30% 10% 15% 15%;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
  .v-tabs-bar,.v-tabs-bar__content{
    background-color: #fff !important;
  }
  .v-tabs-items{
    background-color: #fff !important;
    margin-top:.3rem !important;
  }
  .invoiceList .v-slide-group__content {
    padding: 1rem 0rem !important;
  }
  .theme--light.v-tabs>.v-tabs-bar,.v-tabs-slider{
    background: transparent !important;
  }
  a.v-tab{
    font-size: .65rem !important;
  }
  .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
    background:#f8f8f8;
  }
 
</style>
<style scoped>

    .v-tabs ::v-deep .v-tabs-slider-wrapper {
      transition: none !important;
    }
    .v-tabs-slider-wrapper{
      display: none !important;
    }
    .v-slide-group__content{
      width:100% !important;
      justify-content: flex-end !important;
    }
    .v-tab{
      padding:.2rem 1.5rem !important;
      border-radius: 50px !important;
      height:1.8rem !important;
      
    }

</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import SendInvoice from '@/components/invoices/sendinvoice'

export default {
    name:"invoiceList",
    components: { SendInvoice },
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          searchfilter:'',
          searchbookings:'',
          search:null,
          valid: true,
          tab: null,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          tableloader: false,
          dialog: false,
          invoicedialog: false,
          reverseinvoicesdialog: false,
          updatedialog: false,
          fields: [
             {
              text: 'DATE',
              align: 'start',
              value: 'date',
            },
            { text: 'INVOICE#', value: 'invoiceno' },
            { text: 'ORGANISATION NAME', value: 'organisation' },
            { text: 'STATUS', value: 'status' },
            // { text: 'DUE DATE', value: 'duedate' },
            { text: 'AMOUNT', value: 'amount' },
            { text: 'ACTION', value: 'action' },
           ],
           reversefields: [
            { text: 'Passenger', value: 'passenger' },
            {
              text: 'Date',
              value: 'date',
            },
            { text: 'Time', value: 'time' },
            { text: 'Ref', value: 'ref' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            
            { text: 'Distance', value: 'distance' },

           ],
          item:{
            invoice_id: "",
            invoice_no: ""
          },
            isBusy: false,
            loading: false,
           disabled: false,
           bookings: [],
           reversebookings: [],
           orgCategory: [],
           orgType: [],
           organisation: [],
           height: '100%',
            rowHeight: 25,
            perPage: 50,
            start: 0,
            timeout: null,
            selectedItem: false
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = "Invoices"

      if(!this.allinvoices || this.allinvoices.length == 0) {
        this.getInvoices()
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    serverPrefetch () {
      return this.getInvoices()
    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      invoices() {
        return this.$store.state.invoices.filter(item => {

          this.start = 0

          let invoice_no = "";
          let organisation = "";
          let amount = "";
          let date = "";
          if(item.organisations.length > 0) {
            organisation = item.organisations[0].name
          }
          if(item.invoice_no !== null) {
            invoice_no = item.invoice_no
          }
          if(item.amount !== null) {
            amount = item.amount
          }
          if(item.date !== null) {
            date = item.date
          }
          return organisation.toLowerCase().includes(this.searchfilter.toLowerCase()) || invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
      },
      reversals() {
        return this.bookings.filter(item => {

          this.start = 0

          let name = "",ref_no = "",time = "",date = "",from = "", to = "";
          if(item.booking.ref_no != null && item.booking.ref_no != 'null') {ref_no = item.booking.ref_no}
          if(item.booking.passenger_name != null && item.booking.passenger_name != 'null') {name = item.booking.passenger_name}
          if(item.booking.pickup_time != null && item.booking.pickup_time != 'null') {time = item.booking.pickup_time}
          if(item.booking.pickup_date != null && item.booking.pickup_date != 'null') {date = item.booking.pickup_date}
          if(item.booking.travelfrom != null && item.booking.travelfrom != 'null') {from = item.booking.travelfrom}
          if(item.booking.travelto != null && item.booking.travelto != 'null') {to = item.booking.travelto}

            if(this.searchbookings == null || this.searchbookings == 'null') {
              this.searchbookings = ''
            }

          return name.toLowerCase().includes(this.searchbookings.toLowerCase()) || ref_no.toLowerCase().includes(this.searchbookings.toLowerCase()) || time.includes(this.searchbookings) || date.includes(this.searchbookings) || from.toLowerCase().includes(this.searchbookings.toLowerCase()) || to.toLowerCase().includes(this.searchbookings.toLowerCase())
        })
      },
      allinvoices() {
        return this.invoices
      },
      organisations() {
        return this.$store.state.organisations
      },
      startHeight() {
        return this.start * this.rowHeight - 32;
      },
      endHeight() {
        return this.rowHeight * (this.bookings.length - this.start);
      }
    },
    methods:{
      getRowClass(item) {
        return 'row-color' 
      },
      onScroll(e) {
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.bookings.length ?
          this.bookings.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
      },
      async getInvoices(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getInvoices')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
  
      clear () {
        if(this.$refs.form != undefined) {
          this.$refs.form.reset()
        }
      
      },
      deleteInvoice(id){
            if(confirm("Are you sure to delete invoice ?")){
                axios.delete(`/invoices/${id}`).then(response=>{
                  this.$toast.success('Invoice deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                }).catch(error=>{
                    console.log(error)
                })
            }
        },
        reverseInvoice(id,invoice_no){

          this.item.invoice_id = id
          this.item.invoice_no = invoice_no

          this.reverseinvoicesdialog = true

            axios.get(`/reverse-invoices/${id}`).then(response=>{
              this.bookings = response.data.bookings

            }).catch(error=>{
                console.log(error)
            })
        },
        reverseForm(){

          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('invoice_id',this.item.invoice_id)
          fd.append('bookings',JSON.stringify(this.reversebookings))

          axios.post('/submit-reverse-invoices', fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {

              this.$toast.success('Invoices reversed successfully','Success',{position: 'topRight',timeout: 7000,})

              this.reverseinvoicesdialog = false

              this.getInvoices()

            }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        },
        sendinvoice(id) {
          this.$store.state.itemid = id
          this.invoicedialog = true
        },
    }
}
</script>