<template>
	<div>
    <v-dialog
      max-width="450px"
      v-model="show"
      >
        <v-card>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">
            <v-card-title class="d-flex justify-space-between">
              <div class="loadflex"><span class="text-h6">Update Waiting</span>&nbsp;&nbsp;<div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader"></v-progress-circular></div></div>
            </v-card-title>

            <v-card-text>
                <v-container class="mt-0 mx-0 px-0 mb-0">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      >
                      <v-autocomplete
                          :items="settingsVehicleType"
                          label="Select vehicle*"
                          v-model="item.type_id"
                          item-text="type"
                          item-value="id"
                          :rules="fieldRules"
                          :search-input.sync="vehicle"
                          hide-details
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      >
                      <v-text-field
                        label="Free Minutes*"
                        v-model="item.minutes"
                        :rules="fieldRules"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      >
                      <v-text-field
                        label="Rate per minute*"
                        v-model="item.rate"
                        :rules="fieldRules"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click.stop="show = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="savecharges"
                >
                  Submit
                </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
    </v-dialog>
	</div>
</template>

<style>
  .modal-waitingtime{
    max-width:550px !important;
  }
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'

export default {
	name: 'waitingtime',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        vehicle: null,
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        item: {
          type_id: null,
          minutes: "",
          rate: ""
        }
	}),
	methods: {
    getSettingsDetails(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getConfig')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
    savecharges(){

      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('type_id',this.item.type_id)
      fd.append('rate',this.item.rate)
      fd.append('minutes',this.item.minutes)

      axios.post('/rate-charges', fd, config).then(response=>{
        if(response.data.status == parseInt(200)) {

          this.$toast.success('Waiting updated successfully','Success',{position: 'topRight',timeout: 7000,})

          this.getSettingsDetails()

          this.show = false
        }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    deleteRate(id){
        if(confirm("Are you sure to remove waiting time ?")){
            axios.get(`/delete-charge/${id}`).then(response=>{
              this.$toastpopup('success','Success',response.data.message)
              this.getSettingsDetails()
            }).catch(error=>{
                console.log(error)
            })
        }
    }
	},
	props: {
	     value: Boolean
	  },
	  computed: {
      loader() {
        return this.$store.state.componentloader
      },
      settingsVehicleType() {
        return this.$store.state.settingsVehicleType
      },
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {},
	created() {}
}
</script>