<template>
    <!-- BEGIN: Content-->
    <div class="driverProfile" style="margin-top:-2.5rem">
      <div class="mx-0 px-0" style="position:relative">

        <v-row class="my-0 py-0" style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col cols="12" class="invoice-content py-0 my-0">

            <Profile :driverid="parseInt($route.params.id)"/>

            <div class="column content-column">
              <v-container fluid>
                <v-row>
                  <div class="fill header list-header d-flex">
                    <div class="section_title"><span class="title" style="color:#888888;font-size:1.2rem !important">Commissions&nbsp;&nbsp;</span></div>
                  </div>
                  <div class="fill subheader list-subheader d-flex mx-0">

                    <v-btn depressed color="white" @click="showRates" variant="light" class="light mx-0" size="sm"><v-icon class="mr-1">mdi-eye-outline</v-icon> View Rates</v-btn>

                  </div>

                  <v-col
                    cols="12"
                    md="12"
                    class="px-0 mx-0 pb-0 mb-0"
                   >

                    <v-card class="pb-3 pt-0 px-0 mx-0 mb-0" style="box-shadow:none;padding-bottom:0 !important">
                      <v-card-title class="py-0 my-0" style="display:flex;align-items:center;justify-content:space-between;height:3rem">
                        <div class="summaryAlert px-0">
                          <div class="summaryTotals">
                            <div class="totals" style="flex-direction:row !important">
                              <span class="font-normal" style="color:#888 !important">Total Commission</span>
                              <span class="font-medium mx-2" style="font-size:18px !important;">KES{{ 
                                    (((((commissions.reduce( (acc, item) => 
                                          {  
                                              let amt = 0;
                                              if(parseInt(item.movement_id) == 0) {
                                                if(item.organisationrate.length > 0) {
                                                  if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                                    amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                                  }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                                    amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                                  }else {
                                                    amt = parseInt(item.organisationrate[0].base_rate)
                                                  }
                                                }
                                              }else {
                                                amt = Math.round(item.amount)
                                              }
                                            return acc += amt; 
                                          }, 0) + commissions.reduce( (acc, item) => 
                                        {  

                                          let amt = 0, waitingfee = 0, subamt = 0;
                                          if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                            if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                                waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                            }
                                          }

                                          if(item.waiting.length > 0) {
                                            subamt = item.waiting.reduce((acc1, value) => { 
                                                      let total = 0;
                                                      if(value.starttime !== null && value.endtime !== null) {
                                                        
                                                        if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                          total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                                        }

                                                      }

                                                      return  acc1 += total
                                                    }, 0)
                                          }

                                          amt = subamt + waitingfee;
                                          
                                          return acc += amt; 

                                        }, 0))*(drivercommission != null ? drivercommission.rate : 0))/100) + (drivercommission != null ? parseFloat(drivercommission.topup) : 0) + (commissions.reduce( (acc, item) => 
                                          {  
                                              let amt = 0;
                                              if(item.total_additions != null ) {
                                                amt = Math.round(item.total_additions)
                                              }
                                            return acc += amt; 
                                          }, 0))) - (commissions.reduce( (acc, item) => 
                                          {  
                                              let amt = 0;
                                              if(item.total_deductions != null ) {
                                                amt = Math.round(item.total_deductions)
                                              }
                                            return acc += amt; 
                                          }, 0))) | currency(' ')
                                  }}</span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <v-row class="solo-wrapper scommission">
                            <v-col
                              cols="12"
                              xl="6"
                              md="6"
                              class="py-0"
                              >
                              <date-range-picker
                                ref="picker"
                                :showWeekNumbers="showWeekNumbers"
                                :showDropdowns="showDropdowns"
                                :autoApply="autoApply"
                                opens="left"
                                :always-show-calendars="alwaysshowcalendars"
                                v-model="dateRange"
                                @select="updateValues"
                              >
                                  <template v-slot:input="picker" style="min-width: 350px;">
                                      <v-text-field
                                        label="Select date range"
                                        :rules="fieldRules"
                                        v-model="date"
                                        hide-details
                                        solo
                                        style="width:170px;"
                                      ></v-text-field>
                                  </template>
                              </date-range-picker>
                            </v-col>
                            <v-col md="6" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                              <v-text-field
                                    v-model="searchfilter"
                                    label="Search"
                                    solo
                                    hide-details="auto"
                                  ></v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-title>
                      <v-card-text class="mx-0 px-0 pb-0 mb-0 cardtext1">

                        <v-fade-transition>
                          <div>
                            <div class="classloader">
                               <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="overlay"/>
                            </div>
                          </div>
                        </v-fade-transition>

                        <v-data-table
                          dense
                          :headers="fields"
                          :items="commissions"
                          class="elevation-0"
                          :loading="false"
                          fixed-header

                          disable-pagination
                          hide-default-footer
                          id="virtual-scroll-table"
                          v-scroll:#virtual-scroll-table="onScroll"
                          height="100%"
                          >
                          <template v-slot:item.date="{ item }">
                            {{ moment(item.pickup_date).format('MMM Do, YYYY') }}
                          </template>
                          <template v-slot:item.refno="{ item }">
                            {{ item.ref_no }}
                          </template>
                          <template v-slot:item.passenger="{ item }">
                            {{ item.passenger_name }}
                          </template>
                          <template v-slot:item.amount="{ item }">
                            <div v-if="parseInt(item.movement_id) == 0">
                              <div v-if="item.organisationrate.length > 0">
                                <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)))) | currency('') }}</span>
                                <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)))) | currency('') }}</span>
                                <span v-else>{{ ((item.organisationrate[0].base_rate)) | currency('') }}</span>
                              </div>
                              <div v-else>0.00</div>
                            </div>
                            <div v-else>{{ ((Math.round(item.amount))) | currency('') }}</div>
                          </template>
                          <template v-slot:item.deductions="{ item }">
                            <span @click="deduct(item.id)" style="cursor:pointer" v-if="item.total_deductions !== null">{{ item.total_deductions | currency('') }}</span>
                            <span @click="deduct(item.id)" style="cursor:pointer" v-else>0.00</span>
                          </template>
                          <template v-slot:item.additions="{ item }">
                            <span @click="add(item.id)" style="cursor:pointer" v-if="item.total_additions !== null">{{ item.total_additions | currency('') }}</span>
                            <span @click="add(item.id)" style="cursor:pointer" v-else>0.00</span>
                          </template>
                          <template v-slot:item.commision="{ item }">
                            <div v-if="parseInt(item.movement_id) == 0">
                              <div v-if="item.organisationrate.length > 0">
                                <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ ((((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(drivercommission != null ? drivercommission.rate : 0))/100) + (item.total_additions !== null ? parseFloat(item.total_additions) : 0)) - (item.total_deductions !== null ? parseFloat(item.total_deductions) : 0)) | currency('') }}</span>
                                <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ ((((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(drivercommission != null ? drivercommission.rate : 0))/100) + (item.total_additions !== null ? parseFloat(item.total_additions) : 0)) - (item.total_deductions !== null ? parseFloat(item.total_deductions) : 0)) | currency('') }}</span>
                                <span v-else>{{ ((item.organisationrate[0].base_rate*(drivercommission != null ? drivercommission.rate : 0))/100) | currency('') }}</span>
                              </div>
                              <div v-else>0.00</div>
                            </div>
                            <div v-else>{{ ((((Math.round(item.amount)*(drivercommission != null ? drivercommission.rate : 0))/100) + (item.total_additions !== null ? parseFloat(item.total_additions) : 0)) - (item.total_deductions !== null ? parseFloat(item.total_deductions) : 0)) | currency('') }}</div>
                          </template>
                        </v-data-table>

                      </v-card-text>
                    </v-card>

                  </v-col>

                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>

        <CommissionRate v-model="commissionratedialog" :driverid="parseInt($route.params.id)"/>
        <DeductionDialog v-model="deductiondialog" :bookingid="parseInt(bookingid)"/>
        <AdditionDialog v-model="additiondialog" :bookingid="parseInt(bookingid)"/>

      </div>
    </div>

</template>
<style>
  .profile .v-list-item__title{
    margin-bottom:.5rem !important;
  }
  .profile .v-list-item__subtitle, .profile .v-list-item__title, .profile .v-list-item__action-text{
    white-space: normal !important;
    text-transform: none !important;
  }
  .profile .v-list-item__subtitle, .profile .v-list-item__action-text{
    font-size:.8rem !important;
  }
  .v-list-item--link:before{
    background-color: #fff !important;
  }
  .v-pagination__item{
    box-shadow: none !important;
  }
  .theme--light.v-tabs>.v-tabs-bar,.v-tabs-slider{
      background: transparent !important;
    }
  /*  .v-slide-group__wrapper{
      margin-top: -3rem !important;
    }*/
    .v-pagination__navigation{
      box-shadow: none !important;
    }
    /*.v-window.v-item-group{
      margin-top:-3rem !important;
      padding:1rem !important;
    }*/
    .v-tabs-bar,.v-tabs-bar__content{
      background-color: #fff !important;
    }
    .v-tabs-items{
      background-color: #fff !important;
      margin-top:.3rem !important;
    }
    .driverProfile .v-slide-group__content {
      /*background:#cecece !important;*/
      padding: 1rem .3rem !important;
    }

    .scommission .reportrange-text input{
      background-color: #f5f5f5 !important;
      padding-left: .5rem !important;
      padding-right: .5rem !important;
      border-radius: 5px !important;
    }

</style>
<style scoped>

    .v-tabs ::v-deep .v-tabs-slider-wrapper {
      transition: none !important;
    }
    @media (min-width: 576px){
        .modal-slide-in .modal-dialog {
            width: 48rem;
        }
    }

    .vs__selected, .vs__search,.vs__search:focus{
        color: #B4B7BD !important;
    }
    .vs__open-indicator,.vs__clear{
        fill:  #B4B7BD !important;
    }

    .v-tabs-slider-wrapper{
      display: none !important;
    }
    .v-slide-group__content{
      width:100% !important;
      justify-content: flex-end !important;
    }
    
    .v-pagination__item, .v-pagination__navigation{
      box-shadow: none !important;
    }
    .v-pagination__navigation{
      background:#F1F3F4 !important;
    }
    .v-pagination{
      display: flex;
      justify-content: flex-end;
      margin-top:1.2rem;
    }

    .v-tab{
      padding:.5rem 1.5rem !important;
      background:#FFFFFF;
      border-radius: 50px !important;
      height:2.4rem !important;
      margin:.4rem;
    }
    .v-tab.v-tab--active{
      background:#DEC7FF;
    }

</style>
<script>
import Vue from 'vue'
import axios from 'axios'
import StarRating from 'vue-star-rating'
import {ability} from '@/services/ability'

import DeductionDialog from '@/components/drivers/deductions'
import AdditionDialog from '@/components/drivers/additions'

import CommissionRate from '@/components/subcomponents/commissionRate'
import Profile from '@/components/drivers/profile'
import Spinner from 'vue-spinkit'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { format, parseISO } from 'date-fns'

export default {
    name:"DriverProfile",
    components: {
      StarRating,Spinner,Profile,CommissionRate,DateRangePicker,DeductionDialog,AdditionDialog
    },
    data(){
        return {
          overlay:false,
          tab: null,
          dialog: false,
          updatedialog: false,
          searchfilter: "",
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          imageRules: [
            value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
          ],
          details: null,
           rating:0,
           menu: false,
           date: "", 
           rowHeight: 20,
           previewImage: null,
           documents: null,
           bookingid: null,
           valid: true,
           start: 0,
            timeout: null,
            isBusy: false,
            loading: false,
           disabled: false,
           commissionratedialog: false,
           deductiondialog: false,
           additiondialog: false,
           height: '100%',

           fields: [
            { text: 'Date', value: 'date' },
            { text: 'Ref #', value: 'refno' },
            { text: 'Passenger', value: 'passenger' },
            { text: 'Total Amt.', value: 'amount' },
            { text: 'Deductions', value: 'deductions' },
            { text: 'Additions', value: 'additions' },
            { text: 'Commision', value: 'commision' },
           ],

           dateRange: {
            startDate: null,
            endDate: null
           },
          singleDatePicker: true,
          showWeekNumbers: true,
          showDropdowns: true,
          autoApply: true,
          alwaysshowcalendars: true,
          date: "",
          dates: {},
          daterange: {
            start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
          },
          rangedate: "",
          }
    },
    beforeMount() {
      if(!ability.can('user-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.daterange.start = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
      this.daterange.end = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
      this.date = this.daterange.start+" - "+this.daterange.end

      this.$store.state.driverid = this.$route.params.id

      this.fetchcommissions()
    },
    computed: {   
      loader() {
        return this.$store.state.tableloader
      },   
      drivercommission() {
        return this.$store.state.drivercommission
      },
      commissions() {
        return this.$store.state.commissions.filter(item => {

            this.start = 0

            return item.ref_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || item.passenger_name.toLowerCase().includes(this.searchfilter.toLowerCase())
          })
      },
    },
    methods:{
      updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.date = this.daterange.start+" - "+this.daterange.end
        this.fetchcommissions()
      },
      onScroll(e) {
        // debounce if scrolling fast
        this.timeout && clearTimeout(this.timeout);
   
        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.commissions.length ?
            this.commissions.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
      },
      fetchcommissions() {
        this.overlay = true
        let res = {
          "driverid":this.$route.params.id,
          "start":this.daterange.start,
          "end":this.daterange.end,
        }
        this.$store
            .dispatch('fetchcommissions',res)
            .then(() => {
              this.overlay = false
            })
            .catch(() => {
              this.overlay = false
            })
      },
      showRates() {
        this.driverId = this.$route.params.id
        this.commissionratedialog = true
      },
      deduct(id) {
        this.bookingid = id
        this.deductiondialog = true
      },
      add(id) {
        this.bookingid = id
        this.additiondialog = true
      }
    }
}
</script>