<template>
    <div class="showinvoicesdetails" style="margin-top:-2.8rem">
      <div class="mx-0 px-0" style="position:relative">
        <v-row class="my-0 py-0" style="margin-top:0 !important;margin-bottom:0 !important">
          <v-col cols="12" class="invoice-content py-0 my-0">
            <div class="column list-column">
              <div class="fill header list-header d-flex">
                <div class="section_title"><span class="title">All Credit Notes&nbsp;&nbsp;</span> <div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader"
                  ></v-progress-circular></div></div>
              </div>
              <div class="fill body list-body d-flex scroll-y">
                <div class="scroll-y scrollbox">
                  <ul class="content-list" id="itemList">
                    <li @click="viewPayment(creditnote.id)" v-for="(creditnote,index) in itemsForList" :key="index">
                      <div class="list-title">
                        <span style="font-size: var(--ab-font-size-up) !important">{{ creditnote.organisation !== null ? creditnote.organisation.name : "Private" }}</span>
                        <span style="font-size: var(--ab-font-size-up) !important">KES{{ creditnote.amount | currency('') }}</span>
                      </div>
                      <div class="list-subtitle">
                        <span style="font-size: var(--ab-font-size-up) !important"><a href="" style="color:#F98525 !important;text-decoration:none !important;font-size: var(--ab-font-size-up) !important;font-weight:500 !important">{{ creditnote.credit_note_no }}</a> | {{ moment(creditnote.date).format('MMM Do, YYYY') }}</span>
                        <span style="color:#44C189; font-size: var(--ab-font-size-up) !important" v-if="creditnote.invoice !== null">CLOSED</span>
                        <span style="font-size: var(--ab-font-size-up) !important" v-else>OPEN</span>
                      </div>
                    </li>
                  </ul>
                  <v-pagination
                              v-model="currentPage"
                              :length="Math.ceil(allcreditnotes.length/perPage)"
                              v-if="allcreditnotes.length > 0"
                            ></v-pagination>
                </div>
              </div>
            </div>
            <div class="column content-column">
              <div class="fill header list-header d-flex">
                <div class="section_title"><span class="title" style="color:#888888">{{ details !== null ? details.credit_note_no : "" }}&nbsp;&nbsp;</span> <div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader1"
                  ></v-progress-circular></div></div>
              </div>
              <div class="fill subheader list-subheader d-flex">
                <v-btn depressed color="white" variant="light" class="light" size="sm"><router-link link :to='{name:"editCreditNote",params:{id:item.id}}'><v-icon class="mr-1">mdi-pencil</v-icon> Edit</router-link></v-btn>

                <v-btn depressed color="white" v-if="details !== null && details.invoice === null" variant="light" class="light" size="sm" @click="linkcreditnote(details.id,details.organisation_id,details.credit_note_no)"><v-icon class="mr-1">mdi-record-circle-outline</v-icon> Link Credit Note</v-btn>

                <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon>
                      <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  
                  <v-list-item-group
                    color="primary"
                    class="actions"
                    >
                      <v-list-item
                        @click="downloadpdf"
                        >
                          <v-list-item-icon>
                            <v-icon color="#000000">mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span>Export Pdf</span>
                          </v-list-item-content>
                      </v-list-item>
                  </v-list-item-group>
                </v-menu> 

              </div>
              <div class="fill body list-body d-flex scroll-y">
                <div class="scroll-y scrollbox">
                  <div class="invoice-template" v-if="details !== null">
                    <div class="ribbon ribbon-top-right" v-if="details.invoice !== null">
                      <span>CLOSED</span>
                    </div>
                    <div v-else class="ribbon ribbon-top-right">
                      <span>OPEN</span>
                    </div>
                    <v-row class="mt-4">
                      <v-col cols="12" md="6">
                        <img src="/assets/images/logo/logo.png" style="width:50% !important;">
                        <p class="mt-2" style="font-size:.75rem !important;line-height:1.6">P.O Box 6137-00200-Nairobi</br>
                        Kandara Road, Hse 9, Kileleshwa</br>
                        Telephone: 0202011846/2011824</br>
                        Cellphone: 0721400422/0728600654/<br>0733523047/0735009952</br>
                        Email: <a href="mailto:info@absoluteadventuresafaris.com" target="_blank">info@absoluteadventuresafaris.com</a></br>
                        PIN NO: P051199717F | VAT NO: P051199717F</p>

                        <p style="font-size:.92rem !important;margin-top:2rem !important">Bill To</p>
                        <p style="color:#F98525;font-size:var(--ab-font-size-up) !important;margin-top:-.8rem !important">{{ details.organisation.name }}</p>
                        <p style="color:#F98525;font-size:var(--ab-font-size-up) !important;margin-top:-.8rem !important">{{ details.organisation.address }}</p>
                      </v-col>
                      <v-col cols="12" md="6" style="text-align:right">
                        <h2 style="font-size:1.7rem !important">CREDIT NOTE</h2>
                        <p>#{{ details.credit_note_no }}</p>
                        <p style="margin-top:3rem !important;font-size:.75rem !important">Amount</p>
                        <p style="font-size:1.2rem !important;color:#333 !important;font-weight:500 !important;margin-top:-1.1rem !important">{{ details.amount | currency(' ') }}</p>
                        <p style="margin-top:5rem !important;display:flex;justify-content:end">
                          <table class=" custom-font-size">
                                <tr>
                                  <td class="py-2" style="padding-right:3rem !important">Credit Date:</td>
                                  <td class="py-2">{{ moment(details.date).format('Do MMM, YYYY') }}</td>
                                </tr>
                          </table>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2 pb-0">
                      <v-col cols="12" lg="12" class="mt-0">
                        <p>
                          <table class=" custom-font-size">
                            <tr style="background:#eff0f1">
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important;font-weight:500 !important">#</td>
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important;font-weight:500 !important">Description</td>
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important;text-align:right !important;font-weight:500 !important">Amount</td>
                            </tr>
                            <tr>
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important">1</td>
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important">{{ details.notes }}</td>
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important;text-align:right !important">KES{{ details.amount | currency(' ') }}</td>
                            </tr>
                          </table>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row v-if="details.invoice !== null" class="mt-1 pb-2">
                      <v-col cols="12" lg="12">
                        <hr class="mt-0">
                      </v-col>
                      <v-col cols="12" lg="12" class="mt-1">
                        <span style="font-weight:500 !important" class="title mb-2">Credit for</span>
                        <p>
                          <table class=" custom-font-size">
                            <tr style="background:#eff0f1">
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important;font-weight:500 !important">Invoice Number</td>
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important;font-weight:500 !important">Invoice Date</td>
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important;font-weight:500 !important">Invoice Amount</td>
                            </tr>
                            <tr>
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important">{{ details.invoice.invoice_no }}</td>
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important">{{ moment(details.invoice.date).format('Do MMM, YYYY') }}</td>
                              <td style="padding:.5rem !important;font-size:var(--ab-font-size-up) !important">KES{{ details.invoice.amount | currency(' ') }}</td>
                            </tr>
                          </table>
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- components -->
      <LinkCreditNote v-model="creditnotedialog"/>

    </div>

</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import {ability} from '@/services/ability'
import $ from 'jquery';
import LinkCreditNote from '@/components/creditnote/linkcreditnote'

export default {
    name:"showCreditNote",
    components: { LinkCreditNote },
    data(){
        return {
          perPage: 15,
          currentPage: 1,
          daterange: {
            start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
          },
          searchfilter: '',
          search: '',
          dialog: false,
          searchdialog: false,
          loading: false,
          creditnotedialog: false,
          fields: [
             {
              text: '#',
              align: 'start',
              value: '#',
            },
            { text: 'Date', value: 'date' },
            { text: 'Time', value: 'time' },
            { text: 'Ref', value: 'ref' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            { text: 'Passenger', value: 'passenger' },
            { text: 'RequestType', value: 'requesttype' },
            { text: 'VehicleType', value: 'vehicletype' },
            { text: 'Distance(Km)', value: 'distance' },
            { text: 'Amount', value: 'amount' },
           ],
          item:{
             id: 0,
             organisationid: null,
             tolinkreceipt: ""
          },
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.item.id = this.$route.params.id
      this.getDetails()
      if(!this.allcreditnotes || this.allcreditnotes.length == 0) {
        this.getCreditNotes()
      }

    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      loader1() {
        return this.$store.state.tableloader1
      },
      allcreditnotes() {
        return this.$store.state.showcreditnotes
      },
      details() {
        return this.$store.state.creditnotedetail
      },
      rows() {
        return this.allcreditnotes.length
      },
      itemsForList() {
        return this.allcreditnotes.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage,
        );
      }
    },
    methods:{

      linkcreditnote(id,organisationId,creditNo) {
        this.$store.state.linkcreditdata = {
          id: id,
          organisationid: organisationId,
          tolinkreceipt: creditNo
        }
        this.creditnotedialog = true
      },
      viewPayment(id) {
        this.item.id = id
        this.getDetails()
      },
      async getCreditNotes(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getShowCreditNotes')
      },
      async getDetails() {
        this.$store.state.tableloader1 = true
        await axios.get(`/viewsinglecreditenote/${this.item.id}`).then(response=>{
          this.$store.state.tableloader1 = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.creditnotedetail = response.data.creditnote
            }
        }).catch(error=>{
          this.$store.state.tableloader1 = false
            console.log(error)
        })
      },
      async downloadpdf() {
        this.$store.state.tableloader1 = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',this.item.id)

        axios.post('/credit-note/download', fd, config).then(response=>{
         this.$store.state.tableloader1 = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', `${this.details.organisation.name}-${this.details.credit_note_no}.pdf`);
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader1 = false
          console.log(error)
        })
      },

    }
}
</script>