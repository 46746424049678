<template>
	<div class="badges">
    <!-- 15min badge -->
      <v-btn
        class="white--text countin15 mr-1"
        style="background:#FFA50099 !important"
        depressed
        small
        @click="filterRecord('in15')"
      >
        In 15mins <span> ({{ in15count }})</span>
      </v-btn>
    <!-- over 15 badge -->
      <v-btn
        class="white--text countover15 mr-1"
        style="background:#B9CD9999 !important"
        depressed
        small
        @click="filterRecord('over15')"
      >
        Over 15mins <span> ({{ over15count }})</span>
      </v-btn>
    <!-- late badge -->
      <v-btn
        class="white--text countlate mr-1"
        style="background:#ff000099 !important"
        depressed
        small
        @click="filterRecord('late')"
      >
        Late <span> ({{ latecount }})</span>
      </v-btn>
    <!-- 30min badge -->
      <v-btn
        class="white--text countin30 mr-1"
        depressed
        small
        @click="filterRecord('in30')"
        style="background:#68b8e399 !important"
      >
        In 30mins <span> ({{ in30count }})</span>
      </v-btn>
    <!-- 2hrs badge -->
      <v-btn
        class="white--text countin2 mr-1"
        style="background:#ff000099 !important"
        depressed
        small
        @click="filterRecord('in2')"
      >
        In 2hrs <span> ({{ in2count }})</span>
      </v-btn>
    <!-- over2hrs badge -->
      <v-btn
        class="black--text countover2 mr-1"
        style="background:#FFFF9999 !important"
        depressed
        small
        @click="filterRecord('over2')"
      >
        Over 2hrs <span> ({{ over2count }})</span>
      </v-btn>
    <!-- cancelled badge -->
      <v-btn
        class="white--text countcancelled mr-1"
        style="background:#c0c0c099 !important"
        depressed
        small
        @click="filterRecord('cancelled')"
      >
        Cancelled <span> ({{ cancelledcount }})</span>
      </v-btn>
    <!-- allcount badge -->
      <v-btn
        class="white--text countall"
        depressed
        small
        @click="filterRecord('')"
      >
        All <span> ({{ allcount }})</span>
      </v-btn>
  </div>
</template>
<style>
/*table color variant*/
.late, .countlate{color: var(--ab-dark) !important;background:#ff000099 !important}
.in15, .countin15{color: var(--ab-dark) !important;background:#FFA50099 !important}
.over15, .countover15{color: var(--ab-dark) !important;background:#B9CD99 !important}
.in30, .countin30{color: var(--ab-dark) !important;background:#68b8e399 !important}
.in2, .countin2{color: var(--ab-dark) !important;background:#ff000099 !important}
.over2, .countover2{color: var(--ab-dark) !important;background:#FFFF9999 !important}
.cancelled, .countcancelled{color: var(--ab-dark) !important;background:#c0c0c099 !important}
.black, .countblack{background:#000000 !important;color: #fff !important;}
.all, .countall{
    background-image: linear-gradient(to bottom right, #c0c0c099, #ffa50099, #68b8e399, #ff000099, #c0c0c099) !important;
}
.countover2 > .v-badge__wrapper > .v-badge__badge{
  color: var(--ab-dark) !important;
}

.badges .v-btn:not(.v-btn--round).v-size--small {
    height: 20px !important;
    min-width: 15px !important;
}

  .badges .v-badge{
    margin-right: .5rem !important;
  }
  .v-badge__wrapper span{
    z-index: 1000111 !important;
  }
  .over2 .v-badge__wrapper span{
    color: var(--ab-dark);
  }
  .v-badge .v-btn__content{
    font-size:.2rem !important;
  }
  .v-badge, .badges .v-btn {
    border-radius: 2px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
	name: 'bookingcounts',
	components: {
	},
	data: () => ({

		
	}),
	methods: {

	  filterRecord(res) {
        this.$store.commit('searchFilter', res)
      },
		
	},
	computed: mapState(['in15count','over15count','in30count','in2count','over2count','latecount','cancelledcount','allcount','dayscount']),

	mounted() {
  },
	created() {}
}
</script>