<template>
    <!-- BEGIN: Content-->
    <section>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper container-xxl p-0">
        <div class="content-header row">
        </div>
        <div class="content-body"><!-- users list start -->
        <section class="app-user-list">
          <div class="row">
            <div class="col-lg-3 col-sm-6">
              <div class="card">
                <div class="card-body d-flex align-items-center justify-content-between">
                  <div>
                    <h3 class="fw-bolder mb-75">21,459</h3>
                    <span>Total Users</span>
                  </div>
                  <div class="avatar bg-light-primary p-50">
                    <span class="avatar-content">
                      <i data-feather="user" class="font-medium-4"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card">
                <div class="card-body d-flex align-items-center justify-content-between">
                  <div>
                    <h3 class="fw-bolder mb-75">4,567</h3>
                    <span>Paid Users</span>
                  </div>
                  <div class="avatar bg-light-danger p-50">
                    <span class="avatar-content">
                      <i data-feather="user-plus" class="font-medium-4"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card">
                <div class="card-body d-flex align-items-center justify-content-between">
                  <div>
                    <h3 class="fw-bolder mb-75">19,860</h3>
                    <span>Active Users</span>
                  </div>
                  <div class="avatar bg-light-success p-50">
                    <span class="avatar-content">
                      <i data-feather="user-check" class="font-medium-4"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card">
                <div class="card-body d-flex align-items-center justify-content-between">
                  <div>
                    <h3 class="fw-bolder mb-75">237</h3>
                    <span>Pending Users</span>
                  </div>
                  <div class="avatar bg-light-warning p-50">
                    <span class="avatar-content">
                      <i data-feather="user-x" class="font-medium-4"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- list and filter start -->
          <div class="card">
            <div class="card-body border-bottom">
              <h4 class="card-title">Clients List</h4>
              <div class="row">
                <div class="col-md-4 user_role"></div>
                <div class="col-md-4 user_plan"></div>
                <div class="col-md-4 user_status"></div>
              </div>
            </div>
            <div class="card-datatable table-responsive pt-0">
                <button class="dt-button add-new btn btn-primary m-1" style="float:right" tabindex="0" type="button" data-bs-toggle="modal" data-bs-target="#modals-slide-in"><span>Add New Client</span></button>
              <table class="user-list-table table">
                <thead class="table-light">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Plan</th>
                    <th>Billing</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>John Kunyuga</td>
                        <td>Admin</td>
                        <td>Basic</td>
                        <td>Monthly</td>
                        <td>Active</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>John Kunyuga</td>
                        <td>Admin</td>
                        <td>Basic</td>
                        <td>Monthly</td>
                        <td>Active</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>John Kunyuga</td>
                        <td>Admin</td>
                        <td>Basic</td>
                        <td>Monthly</td>
                        <td>Active</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>John Kunyuga</td>
                        <td>Admin</td>
                        <td>Basic</td>
                        <td>Monthly</td>
                        <td>Active</td>
                        <td></td>
                    </tr>
                </tbody>
              </table>
            </div>
            <!-- Modal to add new user starts-->
            <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in">
              <div class="modal-dialog">
                <form class="add-new-user modal-content pt-0">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                  <div class="modal-header mb-1">
                    <h5 class="modal-title" id="exampleModalLabel">Add Client</h5>
                  </div>
                  <div class="modal-body flex-grow-1">
                    <div class="mb-1">
                      <label class="form-label" for="basic-icon-default-fullname">Full Name</label>
                      <input
                        type="text"
                        class="form-control dt-full-name"
                        id="basic-icon-default-fullname"
                        placeholder="John Doe"
                        name="user-fullname"
                      />
                    </div>
                    <div class="mb-1">
                      <label class="form-label" for="basic-icon-default-uname">Username</label>
                      <input
                        type="text"
                        id="basic-icon-default-uname"
                        class="form-control dt-uname"
                        placeholder="Web Developer"
                        name="user-name"
                      />
                    </div>
                    <div class="mb-1">
                      <label class="form-label" for="basic-icon-default-email">Email</label>
                      <input
                        type="text"
                        id="basic-icon-default-email"
                        class="form-control dt-email"
                        placeholder="john.doe@example.com"
                        name="user-email"
                      />
                    </div>
                    <div class="mb-1">
                      <label class="form-label" for="basic-icon-default-contact">Contact</label>
                      <input
                        type="text"
                        id="basic-icon-default-contact"
                        class="form-control dt-contact"
                        placeholder="+1 (609) 933-44-22"
                        name="user-contact"
                      />
                    </div>
                    <div class="mb-1">
                      <label class="form-label" for="basic-icon-default-company">Company</label>
                      <input
                        type="text"
                        id="basic-icon-default-company"
                        class="form-control dt-contact"
                        placeholder="PIXINVENT"
                        name="user-company"
                      />
                    </div>
                    <div class="mb-1">
                      <label class="form-label" for="country">Country</label>
                      <select id="country" class="select2 form-select">
                        <option value="Australia">USA</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Brazil">Brazil</option>
                        <option value="Canada">Canada</option>
                        <option value="China">China</option>
                        <option value="France">France</option>
                        <option value="Germany">Germany</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Japan">Japan</option>
                        <option value="Korea">Korea, Republic of</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Russia">Russian Federation</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                      </select>
                    </div>
                    <div class="mb-1">
                      <label class="form-label" for="user-role">User Role</label>
                      <select id="user-role" class="select2 form-select">
                        <option value="subscriber">Subscriber</option>
                        <option value="editor">Editor</option>
                        <option value="maintainer">Maintainer</option>
                        <option value="author">Author</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                    <div class="mb-2">
                      <label class="form-label" for="user-plan">Select Plan</label>
                      <select id="user-plan" class="select2 form-select">
                        <option value="basic">Basic</option>
                        <option value="enterprise">Enterprise</option>
                        <option value="company">Company</option>
                        <option value="team">Team</option>
                      </select>
                    </div>
                    <button type="submit" class="btn btn-primary me-1 data-submit">Submit</button>
                    <button type="reset" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
                  </div>
                </form>
              </div>
            </div>
            <!-- Modal to add new user Ends-->
          </div>
          <!-- list and filter end -->
        </section>
        <!-- users list ends -->

        </div>
      </div>
    </section>
    <!-- END: Content-->
</template>

<script>
import axios from 'axios'

export default {
    name:"clientsList",
    data(){
        return {
        }
    },
    mounted(){
    },
    computed: {
    },
    methods:{
    }
}
</script>