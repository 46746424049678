<template>
    <!-- BEGIN: Content-->
    <div class="showInvoice" style="margin-top:-2.7rem">
      <div class="mx-0 px-0" style="position:relative">
          <v-row class="my-0 py-0" style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col cols="12" class="invoice-content py-0 my-0">
              <div class="column list-column">
                <div class="fill header list-header d-flex">
                  <div class="section_title"><span class="title">All Invoices&nbsp;&nbsp;</span> <div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader"
                  ></v-progress-circular></div></div>
                  <v-text-field
                    v-model="searchfilter"
                    label="Search"
                    solo
                    hide-details="auto"
                    class="search1_"
                  ></v-text-field>
                </div>
                <div class="fill body list-body d-flex scroll-y">
                  <div class="scroll-y scrollbox">
                    <ul class="content-list" id="itemList">
                      <li @click="viewInvoice(invoice.id)" v-for="(invoice,index) in itemsForList" :key="index">
                        <div class="list-title">
                          <span style="font-size: var(--ab-font-size-up) !important;">{{ invoice.organisations.length > 0 ? invoice.organisations[0].name : (invoice.passengers.length > 0 ? invoice.passengers[0].name : "Customer") }}</span>
                          <span style="font-size: var(--ab-font-size-up) !important;">KES{{ invoice.amount | currency('') }}</span>
                        </div>
                        <div class="list-subtitle">
                          <span style="font-size: var(--ab-font-size-up) !important;"><span style="color:#F98525 !important;text-decoration:none !important;font-size: var(--ab-font-size-up) !important;font-weight:500 !important">{{ invoice.invoice_no }}</span> | {{ moment(invoice.date).format('MMM Do, YYYY') }}</span>
                          <span style="color:#44C189;font-size: var(--ab-font-size-up) !important;" v-if="invoice.status === 'PAID'">PAID</span>
                          <span style="font-size: var(--ab-font-size-up) !important;" v-else>{{ invoice.status }}</span>
                        </div>
                      </li>
                    </ul>
                    <v-pagination
                              v-model="currentPage"
                              :length="Math.ceil(allinvoices.length/perPage)"
                              v-if="allinvoices.length > 0"
                            ></v-pagination>
                  </div>
                </div>
              </div>
              <div class="column content-column">
                <div class="fill header list-header d-flex">
                  <div class="section_title"><span class="title" style="color:#888888">{{ details !== null ? details.invoice_no : "" }}&nbsp;&nbsp;</span> <div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader1"
                  ></v-progress-circular></div></div>
                  <div style="width:250px;white-space:nowrap">
                    <v-switch
                      v-model="group"
                      label="Generate Grouped Report ?"
                      hide-details
                      @change="changeState"
                      class="mx-2 mb-2"
                    ></v-switch>
                  </div>
                </div>
                <div class="fill subheader list-subheader d-flex">
                  <v-btn depressed color="white" v-if="details !== null && details.status !== 'PAID'" variant="light" class="light" size="sm"><router-link link :to='{name:"editInvoice",params:{id:item.id}}'><v-icon class="mr-1">mdi-pencil</v-icon> Edit</router-link></v-btn>

                  <v-btn depressed color="white" class="light" size="sm" @click="showPayment(details.organisations)"><v-icon class="mr-1">mdi-record-circle-outline</v-icon> Record Payment</v-btn>
                  <v-btn depressed color="white" class="light" size="sm" @click="sendinvoice(item.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                      </svg>&nbsp;  Send Invoice
                  </v-btn>

                  <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon>
                        <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    
                    <v-list-item-group
                      color="primary"
                      class="actions"
                      >

                        <v-list-item
                          @click="downloadpdf"
                          >
                            <v-list-item-icon>
                              <v-icon color="#000000">mdi-file-pdf-box</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <span>Export Pdf</span>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click="downloadexcel"
                          >
                            <v-list-item-icon>
                              <v-icon color="#000000">mdi-file-excel</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <span>Generate Documents</span>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- <v-list-item
                          @click="downloadvoucher"
                          v-if="details !== null && details.custom_invoice ==='false'"
                          >
                            <v-list-item-icon>
                              <v-icon color="#000000">mdi-folder-zip-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <span>Generate Voucher</span>
                            </v-list-item-content>
                        </v-list-item> -->

                    </v-list-item-group>
                  </v-menu>

                  <v-btn depressed color="white" @click="showGeneratedInvoices(item.id)" variant="light" class="light" size="sm"><v-icon class="mr-1">mdi-eye-outline</v-icon> View Documents</v-btn>

                  <v-btn depressed color="white" @click="witholdtax(item.id,details.invoice_no)" variant="light" class="light" size="sm"><v-icon class="mr-1">mdi-checkbox-multiple-blank-circle</v-icon> Withold Tax</v-btn>
                </div>
                <div class="fill body list-body d-flex scroll-y">
                  <div class="scroll-y scrollbox">

                    <div class="pt-3 pb-0" style="padding-right:3rem !important;padding-left: 3rem !important;">
                      
                      <v-expansion-panels
                         v-if="details !== null"
                      >
                        <v-expansion-panel v-if="details.payments.length > 0">
                          <v-expansion-panel-header><span>Received Payments <v-chip>{{ details.payments.length }}</v-chip></span></v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <table class="custom-font-size">
                              <tr style="border-top:1px solid #dedede !important;border-bottom:1px solid #dedede !important">
                                <td><strong>Date</strong></td>
                                <td><strong>Payment #</strong></td>
                                <td><strong>Reference #</strong></td>
                                <td><strong>Mode</strong></td>
                                <td><strong>Amount</strong></td>
                              </tr>
                              <tr v-for="item in details.payments">
                                <td>{{ moment(item.date).format('MMM Do, YYYY') }}</td>
                                <td>{{ item.payment_no }}</td>
                                <td>{{ item.reference_no }}</td>
                                <td>{{ item.mode !== null ? item.mode.name : 'NA' }}</td>
                                <td>{{ item.paidamount | currency('') }}</td>
                              </tr>
                            </table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel v-if="details.witholdingtax.length > 0">
                          <v-expansion-panel-header><span>Witholding Tax <v-chip>{{ details.witholdingtax.length }}</v-chip></span></v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <table class="custom-font-size">
                              <tr style="border-top:1px solid #dedede !important;border-bottom:1px solid #dedede !important">
                                <td><strong>Date</strong></td>
                                <td><strong>Witholding #</strong></td>
                                <td><strong>Amount</strong></td>
                                <td><strong>Status</strong></td>
                              </tr>
                              <tr v-for="item in details.witholdingtax">
                                <td>{{ moment(item.date).format('MMM Do, YYYY') }}</td>
                                <td>{{ item.witholding_tax_no }}</td>
                                <td>{{ item.amount | currency('') }}</td>
                                <td>{{ item.status }}</td>
                              </tr>
                            </table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                      </v-expansion-panels>

                    </div>

                    <div class="invoice-template mt-5" v-if="details !== null">
                      <div class="ribbon ribbon-top-right" v-if="details.status === 'Settled'">
                        <span class="lgreen">{{ details.status }}</span>
                      </div>
                      <div v-else class="ribbon ribbon-top-right">
                        <span>{{ details.status }}</span>
                      </div>
                      <v-row class="mt-4">
                        <v-col cols="12" md="6">
                          <img src="/assets/images/logo/logo.png" style="width:50% !important;">
                          <p class="mt-2" style="font-size:.7rem !important;line-height:1.6">P.O Box 6137-00200-Nairobi</br>
                          Kandara Road, Hse 9, Kileleshwa</br>
                          Telephone: 0202011846/2011824</br>
                          Cellphone: 0721400422/0728600654/<br>0733523047/0735009952</br>
                          Email: <a href="mailto:info@absoluteadventuresafaris.com" target="_blank">info@absoluteadventuresafaris.com</a></br>
                          PIN NO: P051199717F | VAT NO: P051199717F</p>

                          <p style="font-size:.92rem !important;margin-top:2rem !important">Bill To</p>
                          <p style="margin-top:-.8rem !important">
                            <span v-for="(organisation,index) in details.organisations" :key="index" style="font-size:var(--ab-font-size-up) !important;text-transform:uppercase !important">{{ organisation.name }},<br>
                              <span style="color:#F98525;font-size:var(--ab-font-size-up) !important;text-transform:uppercase !important">PIN NO: {{ organisation.kra }}<br></span>
                            </span>
                            <span v-for="(project,index) in details.projects" :key="index" style="font-size:var(--ab-font-size-up) !important;text-transform:uppercase !important">{{ project.name }},<br></span>
                            <span v-for="(passenger,index) in details.passengers" :key="passenger.id" style="font-size:var(--ab-font-size-up) !important;text-transform:uppercase !important">{{ passenger.name }}<br></span>
                          </p>
                        </v-col>
                        <v-col cols="12" md="6" style="text-align:right">
                          <h2 style="font-size:1.7rem !important">INVOICE</h2>
                          <p>#{{ details.invoice_no }}</p>
                          <p style="margin-top:3rem !important;font-size:.75rem !important">Balance Due</p>
                          <p style="font-size:1.2rem !important;color:#333 !important;font-weight:500 !important;margin-top:-1.1rem !important">{{ 
                            ( 
                             parseFloat(details.amount )) | currency(' ') }}</p>
                          <p style="margin-top:5rem !important;display:flex;justify-content:end">
                              <table class="custom-font-size">
                                  <tr>
                                    <td style="padding-right:3rem !important">Invoice Date:</td>
                                    <td style="font-size: var(--ab-font-size-up) !important" class="py-2">{{ moment(details.date).format('Do MMM YYYY') }}</td>
                                  </tr>
                                  <tr>
                                    <td class="py-2" style="padding-right:3rem !important">Due Date:</td>
                                    <td style="font-size: var(--ab-font-size-up) !important" class="py-2">{{ moment(details.due_date).format('Do MMM YYYY') }}</td>
                                  </tr>
                              </table>
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                       <!--  <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="mt-1"
                            v-if="details.custom_invoice === 'false'"
                          >
                          <p style="font-size:12px !important;margin-top:0rem !important;">{{ details.subject !== null && details.subject !== 'null' ? details.subject : "" }}</p>
                        </v-col> -->
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          class="mt-0 pt-0"
                          v-if="details.custom_invoice === 'false'"
                          >
                          <table class="mt-2 custom-font-size">
                              <tr style="background:#dedede !important">
                                <td><strong>#</strong></td>
                                <td><strong>Description</strong></td>
                                <td><strong>Qty</strong></td>
                                <td><strong>Rate</strong></td>
                                <td><strong>Amount</strong></td>
                              </tr>
                              <tr>
                                <td>1</td>
                                <td>{{ details.request_category == "Client" ? "Ride" : "Delivery" }} requests - {{ moment(details.start_date).format('MMM Do, YYYY') }} to {{ moment(details.end_date).format('MMM Do, YYYY') }}</td>
                                <td>1</td>
                                <td>{{ details.amount | currency('') }}</td>
                                <td>{{ details.amount | currency('') }}</td>
                              </tr>
                            </table>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                          v-if="details.custom_invoice === 'true'"
                          >
                            <table class="mt-2 custom-font-size">
                              <tr style="background:#dedede !important">
                                <td><strong>#</strong></td>
                                <td><strong>Description</strong></td>
                                <td><strong>Qty</strong></td>
                                <td><strong>Rate</strong></td>
                                <td><strong>Amount</strong></td>
                              </tr>
                              <tr v-for="(item,index) in details.items" :key="index">
                                <td>{{ ++index }}</td>
                                <td>{{ item.details }}</td>
                                <td>{{ item.quantity | currency('') }}</td>
                                <td>{{ item.rate | currency('') }}</td>
                                <td>{{ item.amount | currency('') }}</td>
                              </tr>
                            </table>
                          </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                          class="subtotals mt-3"
                          >
                            <table class="custom-font-size" v-if="details.custom_invoice === 'false'">
                                <tr>
                                  <td><strong>Sub Total</strong></td>
                                  <td>
                                    {{ details.subamount | currency('') }}
                                  </td>
                                </tr>
                                <tr>
                                  <td><strong>Vat</strong><span v-if="vat != null">({{ vat.rate }}%)</span></td>
                                  <td>
                                    {{ details.vat | currency('') }}
                                  </td>
                                </tr>
                                <tr>
                                  <td><strong>Expressway</strong></td>
                                  <td>
                                    {{ details.expressway | currency('') }}
                                  </td>
                                </tr>
                                <tr>
                                  <td><strong>Total KES</strong></td>
                                  <td>
                                    {{ details.amount | currency('') }}
                                  </td>
                                </tr>
                            </table>
                            <table v-else class="mt-3 custom-font-size">
                                <tr>
                                  <td><strong>Sub Total</strong></td>
                                  <td>
                                    {{ details.subamount | currency('') }}
                                  </td>
                                </tr>
                                <tr>
                                  <td><strong>Vat</strong><span v-if="vat != null">({{ vat.rate }}%)</span></td>
                                  <td>
                                    {{ details.vat | currency('') }}
                                  </td>
                                </tr>
                                <tr>
                                  <td><strong>Total</strong></td>
                                  <td>
                                    {{ details.amount | currency('') }}
                                  </td>
                                </tr>
                            </table>
                          </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
      </div>

      <!-- components -->
      <Payment v-model="paymentdialog" :invoiceid="parseInt(item.id)" :organisationid="item.organisation_id"/>
      <SendInvoice v-model="invoicedialog"/>
      <GenerateInvoice v-model="generateinvoice"/>
      <Confirm v-model="confirmdialog"/>
      <WitholdInvoice v-model="witholdinvoicedialog"/>

    </div>

</template>
<style>
   .filters .v-input__slot,.filters .v-input.theme--light.v-text-field{
      width:220px !important;
      max-width:220px !important;
    }
    .invoice1 .v-virtual-scroll__item{
      width:100%;
      display: grid !important;

       /*grid-auto-columns: minmax(4%, 7.691%) !important;*/
       grid-template-columns:13% 13% 13% 13% 13% 7% 7% 7% 7% 7%;
       grid-auto-flow: column !important;
       grid-gap: 0px;
    }
</style>
<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import {ability} from '@/services/ability'
import $ from 'jquery';

import Payment from '@/components/invoices/payment'
import SendInvoice from '@/components/invoices/sendinvoice'
import GenerateInvoice from '@/components/invoices/generateinvoice'
import Confirm from '@/components/reports/confirm'
import WitholdInvoice from '@/components/witholding/witholdsingleinvoice'

export default {
    name:"showInvoice",
    components: { Payment,SendInvoice,GenerateInvoice,Confirm,WitholdInvoice },
    data(){
        return {
          perPage: 17,
          currentPage: 1,
          daterange: {
            start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
          },
          confirmdialog: false,
          searchfilter: '',
          search: '',
          dialog: false,
          searchdialog: false,
          generateinvoice:false,
          loading: false,
          paymentdialog: false,
          invoicedialog: false,
          witholdinvoicedialog: false,
          group: true,
          fields: [
            { text: 'Name', value: 'name' },
            { text: 'Date', value: 'date' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            { text: 'Vehicle', value: 'vehicletype' },
            { text: 'Dist', value: 'distance' },
           ],
          item:{
             id: 0,
             organisation_id: 0
          },
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.item.id = this.$route.params.id
      this.getDetails()
      if(!this.allinvoices || this.allinvoices.length == 0) {
        this.getInvoices()
      }

    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      loader1() {
        return this.$store.state.tableloader1
      },
      allinvoices() {
        return this.$store.state.showinvoices
      },
      details() {
        return this.$store.state.invoicedetail
      },
      rows() {
        return this.allinvoices.length
      },
      vat() {
        return this.$store.state.vat
      },
      itemsForList() {
        return this.allinvoices.filter(item => {

          this.start = 0

          let invoice_no = "";
          let organisation = "";
          if(item.organisations.length > 0) {
            organisation = item.organisations[0].name
          }
          if(item.invoice_no !== null) {
            invoice_no = item.invoice_no
          }
 
          return organisation.toLowerCase().includes(this.searchfilter.toLowerCase()) || invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase())
        }).slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage,
        );
      }
    },
    methods:{

      witholdtax(id,invoice_no) {
        this.$store.state.witholdsingleinvoicedata = {
          id: id,
          tolinkreceipt: invoice_no
        }
        this.witholdinvoicedialog = true
      },

      changeState(item) {
        this.$store.state.grouped = item
      },

      showPayment(organisations) {

        if(organisations.length > 0) {
          this.item.organisation_id = organisations[0].id
        }

        this.paymentdialog = true

      },
      viewInvoice(id) {
        this.item.id = id
        this.$store.state.itemid = id
        this.getDetails()
      },
      sendinvoice(id) {
        this.$store.state.itemid = id
        this.invoicedialog = true
      },
      showGeneratedInvoices(id) {
        this.$store.state.itemid = id
        this.generateinvoice = true
      },
      async getInvoices(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getShowInvoices')
      },
      async getDetails() {
        this.$store.state.tableloader1 = true
        await axios.get(`/invoice/vat/${this.item.id}`).then(response=>{
          this.$store.state.tableloader1 = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.vat = response.data.vat
              this.$store.state.invoicedetail = response.data.invoice
            }
        }).catch(error=>{
          this.$store.state.tableloader1 = false
            console.log(error)
        })
      },
      async downloadpdf() {
        this.$store.state.tableloader1 = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',this.item.id)

        axios.post('/invoice/download', fd, config).then(response=>{
         this.$store.state.tableloader1 = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', `${this.details.organisations.length > 0 ? this.details.organisations[0].name : ''}-${this.details.passengers.length > 0 ? this.details.passengers[0].name : ""}-${this.details.invoice_no}.pdf`);
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader1 = false
          console.log(error)
        })
      },

      async downloadvoucher() {
        this.$store.state.tableloader1 = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }};
          let fd = new FormData();
          fd.append('id',this.item.id)
          fd.append('grouped',this.group)

        axios.post('/invoice/downloadevoucher', fd, config).then(response=>{
         this.$store.state.tableloader1 = false
         if(response.data.status == parseInt(200)) {

            this.$store.state.confirm_message = "Your voucher will be generated in a short while. To download the voucher once generated click on the <b>View Documents</b> button.";
            this.$store.state.confirm_title = "Voucher Request";
            this.confirmdialog = true

         }
          
        })
        .catch(error => {
          this.$store.state.tableloader1 = false
          console.log(error)
        })
      },

      async downloadexcel() {
        this.$store.state.tableloader1 = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }};
          let fd = new FormData();
          fd.append('id',this.item.id)
          fd.append('grouped',this.group)

        axios.post('/invoice/export-excel', fd, config).then(response=>{
          this.$store.state.tableloader1 = false
          if(response.data.status == parseInt(200)) {

           this.$store.state.confirm_message = "Your excel will be generated in a short while. To download the excel once generated click on the <b>View Documents</b> button.";
            this.$store.state.confirm_title = "Excel Request";
            this.confirmdialog = true

         }
        })
        .catch(error => {
          this.$store.state.tableloader1 = false
          console.log(error)
        })
      },
      clear () {
        if(this.$refs.form != undefined) {
          this.$refs.form.reset()
        }
      
      },

    }
}
</script>