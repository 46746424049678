<template>
	<div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>
      </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'loader',
	components: {
	},
	data: () => ({
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        search: "",
        menu:false,

	}),
	methods: {
		
	},
	props: {},
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
	    loader() {
	        return this.$store.state.tableloader
	      },
	  },
	mounted() {

	},
	created() {
     
    }
}
</script>