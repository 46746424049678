<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>

        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <DriverReceiptAdvancedSearch/>
          <v-text-field
                v-model="searchfilter"
                label="Search"
                solo
                hide-details="auto"
                class="search_"
              ></v-text-field>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            @click="addPayment"
          >
            Payment
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="paymentList">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row>
            <v-col cols="12" class="pb-0 mb-0">
              <div class="summaryAlert px-4">
               <!--  <div class="mb-2">
                  <span class="summaryTitle">Payment Summary <span v-if="orgname !== null">| {{ orgname.name }}</span></span>
                </div> -->
                <div class="summaryTotals mt-2">
                  <div class="totals">
                    <span class="font-normal">Cummulative Total Payments</span>
                    <span class="font-medium">KES{{ 
                          allpayments.reduce((acc, val) => acc + val.paidamount, 0) | currency(' ')
                        }}</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row style="margin-top:1rem !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">
                <v-card-text class="px-0 py-3 cardtext0">
                  <div class="empty-state" v-if="allpayments.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>

                  <v-virtual-scroll
                    :items="allpayments"
                    :item-height="20"
                    renderless 
                    id="virtualscroll"
                   >
                    <template v-slot:default="{ item }">
                        <div class="tcell">{{ moment(item.date).format('MMMM Do YYYY') }}</div>
                        <div class="tcell"><RouterLink style="color:#F98525 !important;text-decoration:none !important" link
                                :to='{name:"driverReceipt",params:{id:item.driver_id}}'>{{ item.payment_no }}</RouterLink></div>
                        <div class="tcell">{{ item.reference_no }}</div>
                        <div class="tcell">
                          <span v-if="item.driver !== null">{{ item.driver.name }}</span>
                          <span v-else>N/A</span>
                        </div>
                        <div class="tcell">
                          <RouterLink v-if="item.invoice !== null" style="color:#F98525 !important;text-decoration:none !important" link
                                :to='{name:"driverInvoice",params:{id:item.driver_id}}'>{{ item.invoice.invoice_no }}</RouterLink>
                          <span v-else>N/A</span>
                        </div>
                        <div class="tcell">{{ item.mode.name }}</div>
                        <div class="tcell">KES<span style="font-weight:600 !important">{{ item.paidamount | currency(' ') }}</span></div>
                        <div class="tcell">
                          <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  v-bind="attrs"
                                  small
                                  v-on="on">
                                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            
                            <v-list-item-group
                              color="primary"
                              class="actions"
                              >

                                <v-list-item
                                    v-if="item.invoice_no_id == null"
                                    @click="linkpayment(item.id,item.driver_id, item.payment_no)"
                                    >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-link-variant</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Link Invoice</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item
                                    v-if="item.invoice_no_id !== null"
                                    @click="unlinkpayment(item.id)"
                                    >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-link-variant-off</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Unlink Invoice</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item
                                    @click="editReceipt(item)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-pencil</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                <v-list-item
                                  link
                                  @click="deletePayment(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon>mdi-delete</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Remove</v-list-item-title>
                                      </v-list-item-content>
                                </v-list-item>

                            </v-list-item-group>
                          </v-menu>
                        </div>
                    </template>
                  </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
       
        <!-- components -->
        <NewReceipt v-model="receiptdialog" />
        <EditReceipt v-model="editreceiptdialog" :receiptdata="receiptdata" />
        <LinkPayment v-model="linkpaymentdialog" />

      </div>
    </div>

</template>
<style>
  .paymentList .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     /*grid-auto-columns: minmax(12.5%, 12.5%) !important;*/
     grid-template-columns: 12.5% 10.5% 10.5% 18.5% 10.5% 12.5% 12.5% 12.5%;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
  .vue-daterange-picker .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    background-color: #fff !important;
  }
  .calendars{
    flex-direction: column !important;
  }
  .ranges ul{
    display: flex !important;
  }
  .daterangepicker .ranges ul{
    width:100% !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .daterangepicker {
    min-width: 0 !important;
  }
  .vue-daterange-picker .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot{
    padding: 0 !important;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import NewReceipt from '@/components/drivers/allreceipt'
import EditReceipt from '@/components/drivers/editreceipt'
import LinkPayment from '@/components/drivers/linkpayment'
import DriverReceiptAdvancedSearch from '@/components/drivers/driverreceiptadvancedsearch'

export default {
    name:"allreceipts",
    components: {EditReceipt,NewReceipt,LinkPayment,DriverReceiptAdvancedSearch},
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          searchfilter:'',
          search:null,
          valid: true,
          linkpaymentdialog: false,
          searchcategory:null,
          searchinvoice:null,
          searchstatus:null,
          dialog: false,
          tableloader: false,
          receiptdialog: false,
           editreceiptdialog: false,
           linkpaymentdialog: false,
           receiptdata: null,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Finance',
              disabled: false,
              href: '',
            },
            {
              text: 'Payments',
              disabled: false,
              href: '',
            },
          ],
          fields: [
             {
              text: 'DATE',
              align: 'start',
              value: 'date',
            },
            { text: 'PAYMENT#', value: 'paymentno' },
            { text: 'REF #', value: 'referenceno' },
            { text: 'DRIVER', value: 'driver' },
            { text: 'INVOICE#', value: 'invoice' },
            { text: 'MODE', value: 'mode' },
            { text: 'AMOUNT', value: 'amount' },
            { text: 'ACTION', value: 'action' },
           ],
          item:{
              invoice_id: "",
              id: "",
              tolinkreceipt: "",
              _method:"patch"
          },
            isBusy: false,
            loading: false,
           disabled: false,
           orgCategory: [],
           orgType: [],
           organisation: [],
           height: '100%',
            rowHeight: 25,
            perPage: 50,
            start: 0,
            timeout: null ,
            selectedItem: false,
            invoices: []
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = "Drivers Receipts"

      // if(!this.allpayments || this.allpayments.length == 0) {
        this.getdriverpayments()
      // }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    serverPrefetch () {
    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      payments() {
        return this.$store.state.driverreceipts.filter(item => {

          this.start = 0

          let invoice_no = "";
          let payment_no = "";
          let reference_no = "";
          let driver = "";

          if(item.driver != null) {
            driver = item.driver.name
          }
          if(item.payment_no !== null) {
            payment_no = item.payment_no
          }
          if(item.reference_no !== null) {
            reference_no = item.reference_no
          }
          if(item.invoice !== null) {
            invoice_no = item.invoice.invoice_no
          }
          return payment_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || driver.toLowerCase().includes(this.searchfilter.toLowerCase()) || reference_no.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
      },
      allpayments() {
        return this.payments
      },
      modes() {
        return this.$store.state.modes
      },
      organisations() {
        return this.$store.state.organisations
      },
    },
    methods:{

      async getdriverpayments(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getdriverpayments')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
  
      linkpayment(id, driverId, paymentNo) {
        this.$store.state.linkdriverpaymentdata = {
          id: id,
          driverid: driverId,
          tolinkreceipt: paymentNo
        }
        this.linkpaymentdialog = true
      },
      unlinkpayment(id) {
        axios.get(`/driver/payment/unlink/${id}`).then(response=>{
          if(response.data.status == parseInt(200)) {

            let index = this.$store.state.driverreceipts.findIndex(data => data.id == response.data.receipt.id);
            if (index > -1) {
              this.$set(this.$store.state.driverreceipts, index, response.data.receipt);
            }

            this.$toast.success('Payment unlinked successfully','Success',{position: 'topRight',timeout: 7000,})

          }
        }).catch(error=>{
            console.log(error)
        })
      },
      addPayment(data){
        this.receiptdialog = true
      },
      editReceipt(data){
        this.receiptdata = data
        this.editreceiptdialog = true
      },
      deletePayment(id){
          if(confirm("Are you sure to remove receipt ?")){
              axios.get(`/remove-receipt/${id}`).then(response=>{
                if(response.data.status === 200) {
                  let index = this.$store.state.driverreceipts.findIndex(data => data.id == response.data.id);
                  if (index > -1) {
                    this.$store.state.driverreceipts.splice(index, 1);
                  }
                  this.$toast.success('Payment removed successfully','Success',{position: 'topRight',timeout: 7000,})
                }
              }).catch(error=>{
                  console.log(error)
              })
          }
      }
    }
}
</script>