<template>
    <!-- BEGIN: Content-->
    <div class="mt-3">
      <loader/>
      <div class="pastBooking">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="7" xl="5" lg="7" sm="12" cols="12" class="my-1 py-0">
              <v-card class="card-shadow" v-if="isShow">
                <div style="width:100%;height:.3rem !important">
                    <v-progress-linear
                      color="#F9780D"
                      indeterminate
                      height="3"
                      :active="addbookingloader"
                    ></v-progress-linear>
                  </div>
                <v-form class="px-3" enctype="multipart/form-data" v-model="valid" ref="form">
                  <v-row class="py-4" style="padding-bottom:0 !important">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"

                      >
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            type="tel" 
                            v-mask="'####-##-##'"
                            label="Pickup date(YYYY-MM-DD)*"
                            :rules="fieldRules"
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                            clearable
                            @click:clear="clearpickupdate"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          scrollable
                          @input="menu = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>

                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-text-field
                        v-model="time"
                        label="Pickup Time*"
                        type="tel" 
                        v-mask="'##:##'"
                        :rules="fieldRules"
                        required
                        list="data"
                        hide-details
                        autocomplete="off"
                      ></v-text-field>
                      <datalist id="data">
                          <option v-for="time in $store.state.times" :value="time" />
                      </datalist>
                    </v-col>

                    <v-row
                      v-for="(passenger, indx) in passengers" 
                        :key="indx"
                        style="display:flex;align-items:center;justify-content:space-between"
                        class="mx-1 my-2"
                      >
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        >
                      
                            <v-autocomplete
                              v-if="passengers[indx]['isTextField'] === false"
                              :items="customers"
                              :label="Plabel"
                              v-model="passenger.id" 
                              :name="`passengers[${indx}][id]`"
                              item-text="name"
                              item-value="id"
                              :rules="fieldRules"
                              @change="(event) => updateCustomer(event,indx)"
                              hide-details
                            >
                              
                              <div
                                  slot="prepend-item"
                                  class="grey--text px-4 pt-4 newcustomer"
                                  @click="setText(indx)"
                                  >
                                <p>New Customer</p>
                              </div>
                              <template slot="selection" slot-scope="{ item, selected }">
                                  {{ item.name }}
                              </template>
                              <template slot="item" slot-scope="{ item, tile }">
                                  {{ item.name }}
                              </template>

                            </v-autocomplete>
                            <v-text-field
                              v-if="passengers[indx]['isTextField'] === true"
                              append-icon="mdi-selection-ellipse-arrow-inside"
                              @click:append="setText(indx)"
                              :label="Plabel"
                              v-model="passenger.name" 
                              :name="`passengers[${indx}][name]`"
                              required
                              :rules="fieldRules"
                              hide-details
                            ></v-text-field>

                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                            <v-text-field
                              label="Telephone*"
                              required
                              v-model="passenger.telephone" 
                              :name="`passengers[${indx}][telephone]`"
                                :rules="fieldRules"
                                hide-details
                              ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            >
                            <v-text-field
                              label="Email"
                              v-model="passenger.email" 
                              :name="`passengers[${indx}][email]`"
                              hide-details
                              readonly
                              @click="showEmailDialog(indx)"
                              ></v-text-field>
                              <span @click="showEmailDialog(indx)" style="font-size:11px;position:absolute;margin-top:.3rem;cursor:pointer;color:rgb(249, 120, 13)">Add | view emails</span>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            >
                            <v-autocomplete
                              :items="organisations"
                              label="Select organisation*"
                              v-model="passenger.organisation_id"
                              item-text="name"
                              item-value="id"
                              :rules="fieldRules"
                              :name="`passengers[${indx}][organisation_id]`"
                              @change="(event) => updateOrganisation(event,indx)"
                              hide-details
                            >
                              <template slot="selection" slot-scope="{ item, selected }">
                                  {{ item.name }}
                              </template>
                              <template slot="item" slot-scope="{ item, tile }">
                                  {{ item.name }}
                              </template>
                            </v-autocomplete>
                          </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                          >
                          <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                            <v-icon @click="addPassenger" class="mx-2">mdi-plus</v-icon>
                            <v-icon @click="removePassenger(indx)" class="mr-1">mdi-close</v-icon>
                          </div>
                      </v-col>
                    </v-row>

                    <v-col cols="12" class="pt-0 pb-0 mt-0 mb-0" v-if="!clientpanel">
                      <p class="mx-2" @click="clientpanel = !clientpanel" style="font-size:12px !important;color:#F9780D;text-decoration:underline;cursor:pointer">Booking for another client ?</p>
                    </v-col>

                    <v-row
                      style="display:flex;align-items:center;justify-content:space-between"
                      class="mx-1 mt-0 mb-2 py-0"
                      v-if="clientpanel"
                      >
                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                          class="pt-0 pb-0 mt-0 mb-0"
                          >
                            <v-text-field
                              label="Client Name*"
                              v-model="item.aname" 
                              required
                              :rules="fieldRules"
                              hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="3"
                          class="pt-0 pb-0 mt-0 mb-0"
                          >
                          <v-text-field
                            label="Client Mobile"
                            v-model="item.atelephone" 
                            hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                          class="pt-0 pb-0 mt-0 mb-0"
                          >
                          <v-text-field
                            label="Client Email"
                            v-model="item.aemail" 
                            hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="1"
                          class="pt-0 pb-0 mt-0 mb-0"
                          >
                          <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                            <v-icon @click="closepanel">mdi-close</v-icon>
                          </div>
                      </v-col>
                    </v-row>

                    <v-col 
                      cols="12"
                      sm="12"
                      md="12"
                      class="mb-2"
                      >
                        <v-switch
                          v-model="useSubProjects"
                          label="Use Sub Projects"
                          @change="onSwitchchange"
                          hide-details
                        ></v-switch>
                    </v-col>

                    <v-row class="mb-2 mx-2" v-if="!useSubProjects">

                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-autocomplete
                          :items="projects"
                          label="Select project"
                          v-model="item.project_id"
                          item-text="name"
                          item-value="id"
                          multiple
                          chips
                          hide-details
                          deletable-chips
                          :search-input.sync="searchproject"
                        ></v-autocomplete>
                      </v-col>

                    </v-row>

                    <v-row class="mb-2 mx-2" v-if="useSubProjects">
                      
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          v-for="(subproject,index) in projects"
                          :key="index"
                        >
                          <v-autocomplete
                            :items="subproject.subprojects"
                            :label="`Select ${capitalize(subproject.name.toLowerCase())}`"
                            :value="item.subproject_id"
                            item-text="name"
                            item-value="id"
                            chips
                            hide-details
                            deletable-chips
                            @change="(event) => updateSubprojects(event, index, subproject.id)"
                          ></v-autocomplete>
                        </v-col>

                    </v-row>
                    
                    <v-col cols="12"
                        sm="12"
                        md="12">

                        <v-row>

                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            >
                            <!-- <label>Pick-up*</label> -->
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedFrom"
                              @noresult="onNoResult"
                              >

                                <v-text-field
                                  label="Pick-up*"
                                  persistent-placeholder
                                  required
                                  :rules="fieldRules"
                                  v-model="item.from" 
                                  hide-details
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            >
                            <!-- <label>Drop-off*</label> -->
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedTo"
                              @noresult="onNoResult"
                              >
                              <v-text-field
                                  label="Drop-off*"
                                  persistent-placeholder
                                  required
                                  :rules="fieldRules"
                                  v-model="item.to" 
                                  hide-details
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="1"
                            md="1"
                            style="justify-content: center !important; display: flex !important;align-items:center !important"
                            >
                            <v-icon @click="shufflestops" style="width: 30px;height: 30px;">mdi-plus</v-icon>
                          </v-col>

                        </v-row>
                      
                    </v-col>

                    <v-col cols="12"
                        sm="12"
                        md="12"
                        v-show="hidestops"
                        >

                        <v-row>

                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            >
                            <!-- <label>Add Stop</label> -->
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedStop1"
                              @noresult="onNoResult"
                              >

                                <v-text-field
                                  label="Add Stop"
                                  persistent-placeholder
                                  v-model="item.stop1" 
                                  hide-details
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            >
                            <!-- <label>Add Stop</label> -->
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedStop2"
                              @noresult="onNoResult"
                              >
                              <v-text-field
                                  label="Add Stop"
                                  persistent-placeholder
                                  v-model="item.stop2" 
                                  hide-details
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="1"
                            md="1"
                            style="justify-content: center !important; display: flex !important;align-items:center !important"
                            >
                            <v-icon @click="shufflestops" style="width: 30px;height: 30px;">mdi-close</v-icon>
                          </v-col>

                        </v-row>
                      
                    </v-col>

                    <v-col sm="12" md="12" cols="12" class="d-flex align-center mb-1">
                      <label class="mt-1 mr-2">Request Type</label>
                      <v-radio-group
                        v-model="item.request_type"
                        row
                        mandatory
                        hide-details
                        class="mt-0 pt-0"
                      >
                        <v-radio
                          label="Official"
                          value="Official"
                        ></v-radio>
                        <v-radio
                          label="Private"
                          value="Private"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-autocomplete
                        :items="vehicletype"
                        label="Select vehicle type"
                        v-model="item.vehicle_type_id"
                        item-text="type"
                        item-value="id"
                        :rules="fieldRules"
                        hide-details
                        :search-input.sync="searchvehicletype"
                      ></v-autocomplete>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="Ordered by"
                        v-model="item.orderedby"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="Flight details"
                        hide-details
                        v-model="item.flight"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6" md="6"
                    >
                      <v-text-field
                        label="Remarks"
                        v-model="item.remarks"
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-autocomplete
                        :items="movements"
                        label="Select movement"
                        v-model="item.movement_id"
                        item-text="name"
                        item-value="id"
                        hide-details
                        :search-input.sync="searchmovement"
                      ></v-autocomplete>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6" 
                      md="6"
                      v-if="item.movement_id > 0"
                      >
                      <v-text-field
                        type="number"
                        label="Amount"
                        v-model="item.amount"
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-autocomplete
                        :items="freeDrivers" 
                        v-model="toassignuserdriver"
                        label="Select Driver"
                        item-text="name"
                        item-value="id"
                        :rules="fieldRules"
                        required
                        clearable
                        hide-details
                        color="yellow-lighten-2"
                        :search-input.sync="searchdriver"
                      >
                        <template slot="selection" slot-scope="{ item, selected }">
                            {{ item.name }} - {{ item.telephone }}
                        </template>
                        <template slot="item" slot-scope="{ item, tile }">
                            {{ item.name }} - {{ item.telephone }}
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-autocomplete
                        :items="freeVehicles" 
                        v-model="toassignuservehicle"
                        label="Select Vehicle"
                        item-text="registration"
                        item-value="id"
                        :rules="fieldRules"
                        required
                        clearable
                        hide-details
                        color="yellow-lighten-2"
                        :search-input.sync="searchvehicle"
                      >
                        <template slot="selection" slot-scope="{ item, selected }">
                            {{ item.name }} - {{ item.registration }}
                        </template>
                        <template slot="item" slot-scope="{ item, tile }">
                            {{ item.name }} - {{ item.registration }}
                        </template>
                      </v-autocomplete>
                    </v-col>

                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        depressed
                        color="primary darken-1"
                        :disabled="!valid"
                        @click="proceed"
                      >
                        Proceed
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-col>
            <v-col md="4" lg="5" sm="12" cols="12">
              <v-card class="card-shadow pa-0">
                
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <!--confirm details modal -->
        <v-dialog
          v-model="confirmdialog"
          max-width="420"
          >
            <v-card
              width="420"
              class="mx-auto"
            >
              <v-card-title class="white--text pt-2">
                <div style="color:#000;font-size:1rem !important;font-weight:500 !important">
                  Booking Details
                </div>
              </v-card-title>

              <v-card-text class="mt-2">
                <v-simple-table class="requestsummary" dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td style="white-space:nowrap;">Date:</td>
                        <td>{{ date }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">Time:</td>
                        <td>{{ time }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">From:</td>
                        <td>{{ item.from }}</td>
                      </tr>
                      <tr v-if="item.stop1 !== ''">
                        <td style="white-space:nowrap;">Stop:</td>
                        <td>{{ item.stop1 }}</td>
                      </tr>
                      <tr v-if="item.stop2 !== ''">
                        <td style="white-space:nowrap;">Stop:</td>
                        <td>{{ item.stop2 }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">To:</td>
                        <td>{{ item.to }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">Distance:</td>
                        <td>{{ (((parseFloat(stop1)+parseFloat(stop2)+parseFloat(end)))) | currency('',2) }}Km</td>
                      </tr>
                      <tr>
                          <td style="white-space:nowrap;">Time:</td>
                          <td>{{ (((parseFloat(stop1Time)+parseFloat(stop2Time)+parseFloat(endTime)))) | currency('',2) }}Min</td>
                        </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    depressed
                    color="dark darken-1"
                    @click="confirmdialog = false"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary darken-1"
                    :loading="loading"
                    :disabled="!valid"
                    @click="create"
                  >
                    Add Booking
                  </v-btn>
              </v-card-actions>
            </v-card>

        </v-dialog>
        <!-- end confirm details -->

        <!-- email dialog -->
        <v-dialog
          v-model="emaildialog"
          max-width="390px"
          >
          <v-card>
            <v-card-title>
              <span class="text-h6">Client Emails</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col
                cols="12"
                sm="12"
                md="12"
                >
                  <v-alert
                    text
                    color="info"
                    v-for="(data,index) in emails"
                    :key="index"
                    class="pa-0 my-1"
                    style="padding:3px 10px !important"
                    >
                    <v-row
                      align="center"
                      no-gutters
                      class="my-0 py-0"
                      style="display:flex;align-items:center;justify-content:space-between;"
                      >
                      <div class="d-flex" style="font-size:.8rem">{{ data.email }}</div>
                      <v-col class="shrink" style="display:flex;align-items:center;justify-content:space-between">
                        <v-btn
                          color="red"
                          outlined
                          @click="removeemail(index)"
                          class="ma-2"
                        >
                          Remove
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row
                  v-for="(data, indx) in addingEmails" 
                    :key="indx"
                    style="display:flex;align-items:end;justify-content:space-between"
                    class="mx-0 my-2"
                  >
                    <v-col
                        cols="12"
                        sm="12"
                        md="10"
                        >
                        <v-text-field
                          label="Email Address"
                          v-model="data.email" 
                          :name="`addingEmails[${indx}][email]`"
                          hide-details
                        ></v-text-field>

                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="2"
                      >
                      <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                        <v-icon @click="addEmail" class="mx-3">mdi-plus</v-icon>
                        <v-icon @click="removeEmail(indx)">mdi-close</v-icon>
                      </div>
                  </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click="emaildialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                      depressed
                      color="primary darken-1"
                      @click="saveemails"
                    >
                      Save
                    </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </div>
    </div>

</template>
<style>
.newcustomer{
  cursor: pointer;
}
.v-breadcrumbs a {
  color: #F9780D !important;
}
.dtable td{
  padding: .3rem!important;
}
.dtable td:nth-child(2) {
  white-space: normal !important;
}
.v-text-field {
  padding-top: 0 !important;
  margin-top: 0 !important; 
}
.v-input--selection-controls {
   margin-top: 6px !important; 
   padding-top: 4px !important; 
}
</style>
<script>
import axios from 'axios'
import { VueGooglePlaces } from 'vue-google-places'
import {gmapApi} from 'vue2-google-maps'
import {loaded} from 'vue2-google-maps'
import {ability} from '@/services/ability'
import loader from '@/components/loader'

export default {
    name:"pastBooking",
    components: { VueGooglePlaces,loader },
    data(){
        return {
          clientpanel:false,
          isDelivery:false,
          isShow:true,
          useSubProjects:false,
          sub_projects: [],
          bulkchecked:false,
          ratechecked:false,
          country: 'ke',
          apiKey: 'AIzaSyBOq3xAvXV2SsaJPxNk3iz4XNIKPdz4gRc',
          // apiKey: 'AIzaSyC7ys5xYYXQtN7jV0Oz4m8cu1jct9T1jc8',
          searchResults: [],
          service: null,
          map: null,
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          search:null,
          customer:null,
          valid: true,
          menu3:false,
          menu5:false,
          searchvehicletype:null,
          searchproject:null,
          searchfrequency:null,
          searchmovement:null,
          
          searchvehicle:null,
          searchdriver:null,
          toassignuserdriver: "",
          toassignuservehicle: "",

          dialog: false,
          updatedialog: false,
          menu: false,
          time: "",
          menu2: false,
          menu3: false,
          menu4: false,
          date: "", 
          returndate: "",
          returntime: "",
          enddate: "",
          isTextField: false, 
          movements: [
            {id: 0,name: "Urban"},
            {id: 1,name: "Non Urban"},
            {id: 2,name: "Half Day"},
            {id: 3,name: "Full Day"},
          ],
          item:{

              aname: '',
              aemail: '',
              atelephone: '',

              distance: "",
              to: "",
              from: "",
              fromlat: "",
              fromlong: "",
              tolat: "",
              tolong: "",

              stop1: "",
              stop1lat: null,
              stop1long: null,
              stop2: "",
              stop2lat: null,
              stop2long: null,

              request_type: "",
              project_id: [],
              name: "",
              customer_id: "",
              telephone:"",
              orderedby:"",
              pickupdate:"",
              pickuptime:"",
              frequency:"",
              enddate:"",
              organisation_id:"",
              vehicle_id:"",
              vehicle_type_id:2,
              movement_id:0,
              amount: "",
              flight:"",
              remarks:"",
              one_trip_rate:"",
              round_trip_rate:"",
            },
            passengers: [
              {
                organisation_id: "",
                id: "",
                name: "",
                telephone: "",
                email: "",
                emailarray: [],
                isTextField: false
              }
            ],
            loading: false,
           disabled: false,
           orgCategory: [],
           isBusy: false,
           updateModal: null,
           hidestops:false,
           confirmdialog: false,

           emaildialog: false,
           emailarrays: [],
           emailindex: null,
           addingEmails: [{email: ""}]
        }
    },
    mounted(){

      this.$store.state.loader_text = 'Past Booking'

      if(this.customers.length == 0 || this.organisations.length == 0 || this.vehicletype.length == 0){
        this.getAddBookings()
      }
      if(this.drivers.length == 0){
        this.getAssignVehicle()
      }

      this.$store.state.stop1 = 0
      this.$store.state.stop2 = 0
      this.$store.state.end = 0

    },
    created() {
        //do we support geolocation
        // if(!("geolocation" in navigator)) {
        //  console.log('Geolocation is not available.')
        //  return
        // }

    },
    computed: {
      emails() {
        return this.emailarrays
      },
      Plabel() {
        return !this.isDelivery ? "Passenger Name*" : "Contact Name*"
      },
      addbookingloader() {
        return this.$store.state.addbookingloader
      },
      projects() {
        if(this.passengers.length > 0){

          let res = []
          // let data = this.organisations.filter(organisation => organisation.id === parseInt(this.item.organisation_id) )
          let data = this.organisations.filter(organisation => this.passengers.some(item => parseInt(item.organisation_id) === organisation.id ))

          if(data.length > 0) {
            res = data[0].projects
          }
          return res
        }else{
          return []
        }
                
      },
      vehicletype() {
        return this.$store.state.bookingvehicletype
      },

      freeVehicles() {
        return this.$store.state.assignvehicles
      },
      drivers() {
        return this.$store.state.assigndrivers
      },
      freeDrivers() {
        return this.drivers
      },

      customers() {
        return this.$store.state.bookingcustomers
      },
      organisations() {
        return this.$store.state.bookingorganisations
      },
      distance() {
        return this.$store.state.distance
      },
      stop1() {
        return this.$store.state.stop1

      },
      stop2() {
        return this.$store.state.stop2

      },
      end() {
        return this.$store.state.end

      },
      distanceTime() {
        return this.$store.state.distanceTime
      },
      stop1Time() {
        return this.$store.state.stop1Time
      },
      stop2Time() {
        return this.$store.state.stop2Time
      },
      endTime() {
        return this.$store.state.endTime
      }
    },

    watch: {
        distance: function(newVal, oldVal) {
            this.$store.state.distance = newVal
        },
        stop1: function(newVal, oldVal) {
            this.$store.state.stop1 = newVal
        },
        stop2: function(newVal, oldVal) {
            this.$store.state.stop2 = newVal
        },
        end: function(newVal, oldVal) {
            this.$store.state.end = newVal
        }
    },

    methods:{

      addEmail () {
        this.addingEmails.push({
          email: ""
        })
      },
      removeEmail (index) {
        this.addingEmails.splice(index, 1);
      },
      removeemail (indx) {
        this.emailarrays.splice(indx, 1);

        let found = this.passengers[this.emailindex];
        if (found) {

          found.emailarray = []
          found.email = ""

          this.emailarrays.forEach((email) => found.emailarray.push(email));
          found.email = this.emailarrays.map(data => data.email).toString()

        }

      },

      showEmailDialog(indx) {

        let found = this.passengers[indx];
        if (found) {
          this.emailarrays = found.emailarray
          this.emailindex = indx
        }

        this.emaildialog = true
      },

      saveemails() {
        let found = this.passengers[this.emailindex];
        if (found) {
          this.addingEmails.forEach((email) => email != null ? found.emailarray.push(email) : false);
          found.email = found.emailarray.map(data => data.email).toString()
          this.emailarrays = found.emailarray

          this.addingEmails = []
          this.addEmail()
        }
        this.emaildialog = false
      },

      closepanel() {
        this.clientpanel = !this.clientpanel
        if(this.clientpanel == false) {
          this.item.aname = ''
          this.item.aemail = ''
          this.item.atelephone = ''
        }
      },

      clearreturndate() {
        this.returndate = ""
      },
      clearpickupdate() {
        this.date = ""
      },
      async getAssignVehicle(){

        this.$store.state.addbookingloader = true

        this.$store
          .dispatch('getAssignVehicle')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },
      getAddBookings(){

        this.$store.state.addbookingloader = true

        this.$store
          .dispatch('getAddBookings')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },
      addPassenger () {
        this.passengers.push({
          organisation_id: "",
          id: "",
          name: "",
          telephone: "",
          email: "",
          emailarray: [],
          isTextField: false
        })
      },
      removePassenger (index) {
        this.passengers.splice(index, 1);
      },
      onSwitchchange($event) {
        if($event === true || $event === 'true' || $event === 'on') {
          this.item.project_id = []
        }else {
          this.sub_projects = []
        }
      },
      capitalize(name) {
        const finalSentence = name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        return finalSentence
      },
      updateSubprojects(value, index, projectId) {

        if(value !== null) {

          const val = {
                        project_id: projectId,
                        subproject_id: value
                      }

          let index = this.sub_projects.findIndex(data => data.project_id == projectId && data.subproject_id == value);
          if (index > -1) {
            
            this.sub_projects.splice(index, 1, val);
          }else {
            this.sub_projects.unshift(val)
          }

        }else {

          let index = this.sub_projects.findIndex(data => data.project_id == projectId);
          if (index > -1) {
            this.sub_projects.splice(index, 1);
          }

        }
        
      },
      shufflestops() {
        this.hidestops = !this.hidestops
        this.item.stop1 = ""
        this.item.stop1lat = null
        this.item.stop1long = null
        this.item.stop2 = ""
        this.item.stop2lat = null
        this.item.stop2long = null

        this.$store.state.waypoints = []
      },
      setText(indx) {
        
        let find = this.passengers[indx];
        if (find) {
          const container = {};
          container.organisation_id = ''
          container.id = ''
          container.name = ''
          container.telephone = ''
          container.email = ''
          container.emailarray = []
          container.isTextField = !find.isTextField
          this.passengers.splice(indx, 1, container);
        }
        let found = this.passengers[indx];

        if(found.isTextField === true) {
          this.item.telephone = ''
          this.item.customer_id = null
          this.selectcustomer = null

          if (found) {
            const container = {};
            container.organisation_id = ''
            container.id = ''
            container.telephone = ''
            container.email = ''
            container.emailarray = []
            container.name = found.name
            container.isTextField = found.isTextField
            this.passengers.splice(indx, 1, container);
          }

        }
        if(found.isTextField === false) {
          this.item.name = ''
          if (found) {
            const container = {};
            container.organisation_id = ''
            container.id = ''
            container.telephone = ''
            container.email = ''
            container.name = ''
            container.emailarray = []
            container.isTextField = found.isTextField
            this.passengers.splice(indx, 1, container);
          }
        }
      },
      updateCustomer(value,indx) {
        if(value !== null) {
          let index = this.customers.filter(item => item.id === value )

          let found = this.passengers[indx];
          if (found) {
            found.organisation_id = index[0].organisation_id
            found.id = value
            found.name = index[0].name
            found.telephone = index[0].telephone
            found.email = index[0].email === undefined || index[0].email === null ? '' : index[0].email
            if(index[0].email === undefined || index[0].email === null) {

            }else {
              found.emailarray.push({email:index[0].email})
            }
          }
        }
      },
      updateOrganisation(value,indx) {
        if(value !== null) {
          let found = this.passengers[indx];
          if (found) {
            found.organisation_id = value
          }
        }
      },
      directionFilter (addressData) {
        this.direction = addressData.name+', '+addressData.locality+', '+addressData.country
      },
      onPlaceChangedFrom (addressData) {
        this.item.fromlat = addressData.latitude
        this.item.fromlong = addressData.longitude
        this.item.from = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.origin = { lat: addressData.latitude, lng: addressData.longitude }
      },
      onPlaceChangedTo (addressData) {
        this.item.tolat = addressData.latitude
        this.item.tolong = addressData.longitude
        this.item.to = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.destination = { lat: addressData.latitude, lng: addressData.longitude }
      },
      onPlaceChangedStop1 (addressData) {
        this.item.stop1lat = addressData.latitude
        this.item.stop1long = addressData.longitude
        this.item.stop1 = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.waypoints.push({
                        location: {lat: addressData.latitude, lng: addressData.longitude},
                        stopover: true
                      });
      },
      onPlaceChangedStop2 (addressData) {
        this.item.stop2lat = addressData.latitude
        this.item.stop2long = addressData.longitude
        this.item.stop2 = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.waypoints.push({
                        location: {lat: addressData.latitude, lng: addressData.longitude},
                        stopover: true
                      });
      },
      onNoResult () {},

      proceed() {

        if(this.item.fromlat === null || this.item.fromlat === "" || this.item.tolat === null || this.item.tolat === "") {
              this.$toast.warning('Every location should be picked from google suggestions','Warning',{position: 'topRight',timeout: 9000,})
              return
            }

        if(this.item.stop1 !== "" && this.item.stop2 !== "") {
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), 'stop1');
            this.$distance(parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), 'stop2');
            this.$distance(parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 !== "" && this.item.stop2 === "") {
          this.$store.state.stop2 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long),'stop1');
            this.$distance(parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 === "" && this.item.stop2 !== "") {
          this.$store.state.stop1 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long),'stop2');
            this.$distance(parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 === "" && this.item.stop2 === "") {
          this.$store.state.stop1 = 0
          this.$store.state.stop2 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');
        }

        this.confirmdialog = true

      },

       create(){

          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('customer_id',this.item.customer_id)
            fd.append('name',this.item.name)
            fd.append('telephone',this.item.telephone)
            fd.append('to',this.item.to)
            fd.append('from',this.item.from)

            fd.append('driver_id',this.toassignuserdriver)
            fd.append('vehicle_id',this.toassignuservehicle)

            fd.append('enddate',this.enddate)
            fd.append('frequency',this.item.frequency)
            fd.append('distance',(parseFloat(this.stop1) + parseFloat(this.stop2) + parseFloat(this.end)))
            fd.append('fromlat',this.item.fromlat)
            fd.append('fromlong',this.item.fromlong)
            fd.append('tolat',this.item.tolat)
            fd.append('tolong',this.item.tolong)

            fd.append('stop1lat',this.item.stop1lat)
            fd.append('stop1long',this.item.stop1long)
            fd.append('stop2lat',this.item.stop2lat)
            fd.append('stop2long',this.item.stop2long)
            fd.append('stop1',this.item.stop1)
            fd.append('stop2',this.item.stop2)

            fd.append('aname',this.item.aname)
            fd.append('atelephone',this.item.atelephone)
            fd.append('aemail',this.item.aemail)
            fd.append('triptime',(((parseFloat(this.stop1Time)+parseFloat(this.stop2Time)+parseFloat(this.endTime)))))

            fd.append('orderedby',this.item.orderedby)
            fd.append('pickupdate',this.date)
            fd.append('pickuptime',this.time)
            fd.append('flight',this.item.flight)
            fd.append('remarks',this.item.remarks)
            fd.append('one_trip_rate',this.item.one_trip_rate)
            fd.append('round_trip_rate',this.item.round_trip_rate)
            fd.append('organisation_id',this.item.organisation_id)
            fd.append('vehicle_type_id',this.item.vehicle_type_id)

            fd.append('movement_id',this.item.movement_id)
            fd.append('amount',this.item.amount)

            fd.append('project_id',JSON.stringify(this.item.project_id))
            fd.append('request_type',this.item.request_type)
            fd.append('subprojects',JSON.stringify(this.sub_projects))
            fd.append('passengers',JSON.stringify(this.passengers))
            fd.append('useSubProjects',this.item.useSubProjects)
            fd.append('portal',"admin")

            axios.post('/past/booking', fd, config).then(response=>{

              if(response.data.status == parseInt(200)) {
                  this.confirmdialog = false
                  this.$toast.success('Booking added successfully','Success',{position: 'topRight',timeout: 7000,})
                }
              
              
                this.clear()
                this.loading = false
                this.disabled = false

                this.$store.state.waypoints = []
                this.$store.state.origin = null
                this.$store.state.destination = null

                this.item.to= ""
                this.item.from= ""
                this.item.distance= ""
                this.item.fromlat= ""
                this.item.fromlong= ""
                this.item.tolat= ""
                this.item.returntime= ""
                this.item.returndate= ""
                this.item.frequency= ""
                this.item.enddate= ""
                this.item.tolong= ""
                this.item.name= ""
                this.item.telephone=""
                this.item.orderedby=""
                this.item.pickupdate=""
                this.item.pickuptime=""
                this.item.organisation_id=""
                this.item.vehicle_type_id=null
                this.item.flight=""
                this.item.remarks=""
                this.sub_projects = []
                this.item.project_id = []
                this.passengers = []

                this.toassignuserdriver = ""
                this.toassignuservehicle = ""

                this.time = ""
                this.date = ""

                this.passengers.push({
                  organisation_id: "",
                  id: "",
                  name: "",
                  telephone: "",
                  email: "",
                  emailarray: [],
                  isTextField: false
                })

                this.item.stop1 = ""
                this.item.stop1lat = null
                this.item.stop1long = null
                this.item.stop2 = ""
                this.item.stop2lat = null
                this.item.stop2long = null

                if(this.clientpanel == true) {
                  this.closepanel()
                }
                
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
              })

          }

      },
      clear () {
        this.$refs.form.reset()
      }
    }
}
</script>