<template>
    <!-- BEGIN: Content-->
    <div class="mt-0 driverinvoiceList">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>
        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <DriverInvoiceAdvancedSearch/>
          <v-text-field
                v-model="searchfilter"
                label="Search invoice"
                solo
                hide-details="auto"
                class="search_"
              ></v-text-field>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            @click="showInvoideDialog"
          >
            Invoice
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div>
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
           <v-row>
            <v-col cols="12" class="pb-0 mb-0 px-0">
              <div class="summaryAlert px-4">
               <!--  <div class="mb-2">
                  <span class="summaryTitle">Payment Summary <span v-if="orgname !== null">| {{ orgname.name }}</span></span>
                </div> -->
                <div class="summaryTotals mt-2">
                  <div class="totals">
                    <span class="font-normal">Cummulative Total</span>
                    <span class="font-medium">KES{{ 
                          allinvoices.reduce((acc, val) => acc + val.amount, 0) | currency(' ')
                        }}</span>
                  </div>
                  <div class="mx-5 arrow-right"></div>
                  <div class="totals">
                    <span class="font-normal">Cummulative Balance</span>
                    <span class="font-medium">KES{{ 
                          (
                          (allinvoices.reduce((acc, val) => acc + val.amount, 0)) -
                          (allinvoices.reduce((acc1, val1) => acc1 + (val1.total_payments != null && val1.total_payments != 'null' ? val1.total_payments : 0), 0))
                          ) | currency(' ')
                        }}</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row style="margin-top:1rem !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">
                <v-card-text  class="px-0 py-3 cardtext0">
                  <div class="empty-state" v-if="allinvoices.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>

                  <v-virtual-scroll
                    :items="allinvoices"
                    :item-height="20"
                    renderless 
                    id="virtualscroll"
                    class="allinvoices"
                   >
                    <template v-slot:default="{ item }">
                        <div class="tcell">{{ moment(item.start_date).format('MMM Do, YYYY') }} - {{ moment(item.end_date).format('MMM Do, YYYY') }}</div>
                        <div class="tcell"><RouterLink style="color:#F98525 !important;text-decoration:none !important" link
                              :to='{name:"driverInvoice",params:{id:item.driver_id}}'>{{ item.invoice_no }}</RouterLink></div>
                        <div class="tcell">
                          <span v-if="item.driver !== null">{{ item.driver.name }}</span>
                          <span v-else>N/A</span>
                        </div>
                        <div class="tcell">{{ item.status }}</div>
                        <div class="tcell">KES<span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span></div>
                        <div class="tcell">KES
                          <span style="font-weight:600 !important" v-if="item.total_payments !== null">{{ (parseFloat(item.amount) - parseFloat(item.total_payments)) | currency(' ') }}</span>
                          <span v-else>0.00</span>
                        </div>
                        <div class="tcell">
                          <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  v-bind="attrs"
                                  small
                                  v-on="on">
                                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            
                            <v-list-item-group
                              color="primary"
                              class="actions"
                              >

                              <v-list-item
                                @click="linkinvoice(item.id,item.driver_id, item.invoice_no)"
                                >
                                  <v-list-item-icon>
                                    <v-icon color="#000000">mdi-link-variant</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Record Payment</v-list-item-title>
                                  </v-list-item-content>
                              </v-list-item>

                              <v-list-item
                                link
                                @click="deleteInvoice(item.id)"
                                >
                                    <v-list-item-icon>
                                      <v-icon>mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Remove</v-list-item-title>
                                    </v-list-item-content>
                              </v-list-item>

                            </v-list-item-group>
                          </v-menu>
                        </div>
                    </template>
                  </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- components -->
      <CreateAllInvoice class="modal-allinvoices" v-model="allinvoicedialog"/>
      <LinkInvoice v-model="linkinvoicedialog" />

    </div>

</template>
<style>
  .driverinvoiceList .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     /*grid-auto-columns: minmax(16.667%, 16.667%) !important;*/
     grid-template-columns: 20% 10% 20% 10% 15% 10% 15%;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
  .modal-payment{
    max-width:550px !important;
  }
  .vue-daterange-picker .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    background-color: #fff !important;
  }
  .calendars{
    flex-direction: column !important;
  }
  .ranges ul{
    display: flex !important;
  }
  .daterangepicker .ranges ul{
    width:100% !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .daterangepicker {
    min-width: 0 !important;
  }
  .vue-daterange-picker .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot{
    padding: 0 !important;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import CreateAllInvoice from '@/components/drivers/allinvoices'
import LinkInvoice from '@/components/drivers/linkinvoice'
import DriverInvoiceAdvancedSearch from '@/components/drivers/driverinvoiceadvancedsearch'

export default {
    name:"allinvoices",
    components: { CreateAllInvoice,LinkInvoice,DriverInvoiceAdvancedSearch },
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          searchfilter:'',
          searchbookings:'',
          search:null,
          valid: true,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          tableloader: false,
          dialog: false,
          invoicedialog: false,
          allinvoicedialog: false,
          reverseinvoicesdialog: false,
          linkinvoicedialog: false,
          updatedialog: false,
          fields: [
             {
              text: 'DATE',
              align: 'start',
              value: 'date',
            },
            { text: 'INVOICE#', value: 'invoiceno' },
            { text: 'DRIVER', value: 'driver' },
            { text: 'STATUS', value: 'status' },
            // { text: 'DUE DATE', value: 'duedate' },
            { text: 'AMOUNT', value: 'amount' },
            { text: 'BALANCE', value: 'balance' },
            { text: 'ACTION', value: 'action' },
           ],
          item:{
            invoice_id: "",
            invoice_no: ""
          },
            isBusy: false,
            loading: false,
           disabled: false,
           bookings: [],
           reversebookings: [],
           orgCategory: [],
           orgType: [],
           organisation: [],
           height: '100%',
            rowHeight: 25,
            perPage: 50,
            start: 0,
            timeout: null,
            selectedItem: false
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = "Drivers Invoices"

      // if(!this.allinvoices || this.allinvoices.length == 0) {
        this.getDriverInvoices()
      // }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    serverPrefetch () {
      return this.getDriverInvoices()
    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      invoices() {
        return this.$store.state.driverinvoices.filter(item => {

          this.start = 0

          let invoice_no = "";
          let driver = "";
          let amount = "";
          let date = "";
          if(item.driver !== null) {
            driver = item.driver.name
          }
          if(item.invoice_no !== null) {
            invoice_no = item.invoice_no
          }
          if(item.amount !== null) {
            amount = item.amount
          }
          if(item.date !== null) {
            date = item.date
          }
          return driver.toLowerCase().includes(this.searchfilter.toLowerCase()) || invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
      },

      allinvoices() {
        return this.invoices
      },
      organisations() {
        return this.$store.state.organisations
      },
      startHeight() {
        return this.start * this.rowHeight - 32;
      },
      endHeight() {
        return this.rowHeight * (this.driverinvoices.length - this.start);
      }
    },
    methods:{
      linkinvoice(id, driverId, invoiceNo) {
        this.$store.state.linkdriverinvoicedata = {
          id: id,
          driverid: driverId,
          tolinkreceipt: invoiceNo
        }
        this.linkinvoicedialog = true
      },
      showInvoideDialog() {
        this.allinvoicedialog = true 
      },
      onScroll(e) {
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.driverinvoices.length ?
          this.driverinvoices.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
      },
      async getDriverInvoices(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getDriverInvoices')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
  
      clear () {
        if(this.$refs.form != undefined) {
          this.$refs.form.reset()
        }
      
      },
      deleteInvoice(id){
            if(confirm("Are you sure to remove invoice ?")){
                axios.get(`/driver-invoice/${id}`).then(response=>{
                  if(response.data.status === 200) {
                    let index = this.$store.state.driverinvoices.findIndex(data => data.id == response.data.id);
                    if (index > -1) {
                      this.$store.state.driverinvoices.splice(index, 1);
                    }
                    this.$toast.success('Invoice removed successfully','Success',{position: 'topRight',timeout: 7000,})
                  }
                }).catch(error=>{
                    console.log(error)
                })
            }
        },
 
        sendinvoice(id) {
          this.$store.state.itemid = id
          this.invoicedialog = true
        },
    }
}
</script>