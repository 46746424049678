<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>

        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-text-field
              v-model="searchfilter"
              label="Search driver"
              solo
              hide-details="auto"
              class="search_"
            ></v-text-field>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            @click.stop="dialog = true"
          >
            Driver
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="DriverList">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">
                <v-card-text class="px-0 py-3 cardtext">
                  <div class="empty-state" v-if="alldrivers.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>
                  <v-virtual-scroll
                      :items="alldrivers"
                      :item-height="20"
                      renderless 
                      id="virtualscroll"
                      class="drivers"
                     >
                      <template v-slot:default="{ item }">
                          <div class="tcell"><img :src="item.profile" v-if="item.profile !== null" style="border-radius:100%;width:20px !important;height:20px !important;"></div>
                          <div class="tcell">{{ item.name }}</div>
                          <div class="tcell">{{ item.telephone }}</div>
                          <div class="tcell">{{ item.idno }}</div>
                          <div class="tcell">{{ item.email }}</div>
                       <!--    <div class="tcell">
                            <v-chip
                              dark
                              small
                              v-if="item.organisation"
                            >
                              {{ item.organisation.name }}
                            </v-chip>
                          </div> -->
                          <div class="tcell">
                           <!--  <v-chip
                              dark
                              small
                              v-if="item.vname"
                            > -->
                              {{ item.vname }} - {{ item.registration }}
                            <!-- </v-chip> -->
                          </div>
                          <div class="tcell">
                            <div @click="trips(item.id,item.name)">
                              <span
                                v-if="item.status === 'true'"
                                style="cursor:pointer !important;color:green"
                              >
                                Available
                              </span>
                              <span
                                v-else
                                style="cursor:pointer !important;color:red"
                              >
                                Unavailable
                              </span>
                            </div>
                          </div>
                          <div class="tcell">
                            <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    small
                                    v-on="on">
                                  <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                </v-btn>
                              </template>
                              
                              <v-list-item-group
                                color="primary"
                                class="actions"
                                >

                                  <v-list-item
                                    link
                                    :to='{name:"driverProfile",params:{id:item.id}}'
                                    >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-eye</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Profile</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item
                                    @click="editOrg(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-pencil</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                <v-list-item
                                  link
                                  @click="deleteItem(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon>mdi-delete</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Remove</v-list-item-title>
                                      </v-list-item-content>
                                </v-list-item>

                              </v-list-item-group>
                            </v-menu>
                          </div>
                      </template>
                  </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- add user dialog -->
        <v-dialog
          v-model="dialog"
          max-width="550px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6 ml-5">Add Driver</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <div class="row pt-0 mt-0">
                      <div class="col-lg-8 pt-0 mt-0">
                        <img :src="previewImage" style="width:30%;"  />
                      </div>
                  </div>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                        :rules="imageRules"
                        accept="image/png, image/jpeg, image/bmp, image/webp"
                        prepend-icon="mdi-camera"
                        label="Select profile image"
                        v-on:change="onProfileChange"
                      ></v-file-input>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          label="Full Names*"
                          v-model="item.name"
                          required
                          :rules="fieldRules"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          label="Telephone*"
                          required
                          v-model="item.telephone"
                          :rules="fieldRules"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          label="ID NO"
                          v-model="item.idno"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="Email Address*"
                          required
                          v-model="item.email"
                          :rules="emailRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="Username"
                          v-model="item.username"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-autocomplete
                          :items="organisations"
                          label="Select organisation*"
                          v-model="item.organisation_id"
                          item-text="name"
                          item-value="id"
                          :rules="fieldRules"
                          :search-input.sync="search"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-autocomplete
                          :items="vehicles"
                          label="Select a vehicle*"
                          v-model="item.vehicle_id"
                          item-text="registration"
                          item-value="id"
                          :rules="fieldRules"
                          :search-input.sync="searchvehicle"
                          required
                        >
                          <template slot="selection" slot-scope="{ item, selected }">
                              {{ item.name }} - {{ item.registration }}
                          </template>
                          <template slot="item" slot-scope="{ item, tile }">
                              {{ item.name }} - {{ item.registration }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        color="lightgrey lighten-1"
                        text
                        @click="dialog = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :loading="loading"
                        :disabled="!valid"
                        @click="create"
                      >
                        Submit
                      </v-btn>
                    </v-col>
                  </v-row>
                     
                </v-container>
            </v-card-text>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- end user -->
        <!-- user update modal-->
        <v-dialog
          v-model="updatedialog"
          max-width="550px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6">Update Driver</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <div class="row pt-0 mt-0">
                        <div class="col-lg-8 pt-0 mt-0">
                          <img :src="previewImage" style="width:30%;"  />
                        </div>
                    </div>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          :rules="imageRules"
                          accept="image/png, image/jpeg, image/bmp, image/webp"
                          prepend-icon="mdi-camera"
                          label="Select profile image"
                          v-on:change="onProfileChange"
                        ></v-file-input>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            label="Full Names*"
                            v-model="item.updatename"
                            required
                            :rules="fieldRules"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            label="Telephone*"
                            required
                            v-model="item.updatetelephone"
                            :rules="fieldRules"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            label="ID NO"
                            v-model="item.updateidno"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            label="Email Address*"
                            required
                            v-model="item.updateemail"
                            :rules="emailRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            label="Username"
                            v-model="item.updateusername"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-autocomplete
                            :items="organisations"
                            label="Select organisation*"
                            v-model="item.updateorganisation_id"
                            :value="item.updateorganisation_id"
                            item-text="name"
                            item-value="id"
                            :rules="fieldRules"
                            :search-input.sync="search"
                            required
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-autocomplete
                            :items="vehicles"
                            label="Select a vehicle*"
                            v-model="item.updatevehicle_id"
                            :value="item.updatevehicle_id"
                            item-text="registration"
                            item-value="id"
                            :rules="fieldRules"
                            :search-input.sync="searchvehicle"
                            required
                          >
                            <template slot="selection" slot-scope="{ item, selected }">
                                {{ item.name }} - {{ item.registration }}
                            </template>
                            <template slot="item" slot-scope="{ item, tile }">
                                {{ item.name }} - {{ item.registration }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          color="lightgrey lighten-1"
                          text
                          @click="updatedialog = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          :loading="loading"
                          :disabled="!valid"
                          @click="update"
                        >
                          Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
          </v-form>
          </v-card>
        </v-dialog>
        <!-- update user -->

      </div>
    </div>

</template>
<style>
  .drivers .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     /*grid-auto-columns: minmax(11.11%, 11.11%) !important;*/
     grid-template-columns: 6.11% 21.11% 12.11% 12.11% 14.11% 15.11% 11.11% 8.22%;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

export default {
    name:"DriverList",
    components: {},
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          imageRules: [
            value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
          ],
          drivername: '',
          searchfilter:'',
          search:'',
          searchvehicle:null,
          valid: true,
          searchrole:null,
          dialog: false,
          updatedialog: false,
          tripsdialog: false,
          updatetripsdialog: false,
          linerloading: false,
          statusdialog: false,
          menu: false,
          date: "", 
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Driver',
              disabled: false,
              href: '',
            },
            {
              text: 'Driver List',
              disabled: false,
              href: '',
            },
          ],
          fields: [
            { text: 'Image', value: 'profile' },
            { text: 'Name', value: 'name' },
            { text: 'Telephone', value: 'telephone' },
            { text: 'ID NO', value: 'idno' },
            { text: 'Email', value: 'email' },
            // { text: 'Organisation', value: 'organisation' },
            { text: 'Assigned Vehicle', value: 'vehicle' },
            { text: 'Status', value: 'status' },
            { text: 'Action', value: 'action' },
           ],
           docs: [{ filename: "", document: null }],
           previewImage: null,
           documents: null,
          item:{
                id:"",
                name:"",
                telephone:"",
                idno:"",
                email:"",
                username:"",
                organisation_id:"",
                vehicle_id:"",
                licence: null,
                goodconduct: null,
                profile: null,

                opening_mileage: "",
                closing_mileage: "",
                fuel:"",
                toll:"",
                miscellaneous: "",

                updatename:"",
                updatetelephone:"",
                updateidno:"",
                updateemail:"",
                updateusername:"",
                updateorganisation_id:"",
                updatevehicle_id:"",

                updateID: "",
                _method:"patch"
            },
            latitude: null,
            longitude: null,
            isBusy: false,
            loading: false,
           disabled: false,
           Organisation: [],
           Drivers: [],
            updateModal: null,
            e6: 1,
            docname: '',
            file: null,
            rowHeight: 25,
            perPage: 250,
            start: 0,
            timeout: null,
            height: '100%'  
          }
    },
    beforeMount() {
      if(!ability.can('user-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = "Drivers"

      // this.setLocationLatLng();

      if(!this.alldrivers || this.alldrivers.length == 0) {
        this.getDriver()
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    serverPrefetch () {
      return this.getDriver()
    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      drivers() {
        return this.$store.state.drivers.filter(item => {

          this.start = 0

          let driver = "";
          let telephone = "";
          let vehicle = "";
          let registration = "";
          if(item.name !== null) {
            driver = item.name
          }
          if(item.vehicle !== null) {
            vehicle = item.vname
          }
          if(item.registration !== null) {
            registration = item.registration
          }
          if(item.telephone !== null) {
            telephone = item.telephone
          }
          return driver.toLowerCase().includes(this.searchfilter.toLowerCase()) || telephone.includes(this.searchfilter) || registration.toLowerCase().includes(this.searchfilter.toLowerCase()) || vehicle.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
        
      },
      alldrivers() {
        return this.drivers
      },
      vehicles() {
        return this.$store.state.componentvehicles
      },
      organisations() {
        return this.$store.state.minorganisations
      }
    },
    created() {
        //do we support geolocation
        // if(!("geolocation" in navigator)) {
        //  console.log('Geolocation is not available.')
        //  return
        // }

    },
    methods:{
      viewDoc(file, name) {
        // const response = await axios.get(file, { responseType: "blob" });
        // const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
        const fileLink = document.createElement('a');
        var filename = null;
        if(name == "Licence") {
          filename = "driver-licence";
        }else if(name == "Good Conduct") {
          filename = "driver-good-conduct";
        }
         fileLink.href = file;
         fileLink.setAttribute('download', filename+'.pdf');
         document.body.appendChild(fileLink);
         fileLink.click();
      },
      onChange(index,e) {
        if(e) {
          this.docs[index].document = e;
          this.images.push(e);
        }else {
          this.docs[index].document = null;
        }
      },
      onProfileChange(e) {
        if(e) {
          this.item.profile = e;
          this.previewImage = URL.createObjectURL(this.item.profile);
        }else {
          this.item.profile = null;
          this.previewImage = null;
        }
      },
      onGoodConductChange(e) {
        if(e) {
          this.item.goodconduct = e
        }else {
          this.item.goodconduct = null;
        }
      },
      onLicenceChange(e) {
        if(e) {
          this.item.licence = e
        }else {
          this.item.licence = null;
        }
      },
      addField(value, fieldType) {
        fieldType.push({});
      },
      removeField(index, fieldType) {
        fieldType.splice(index, 1);
      },
      setLocationLatLng: function() {
        navigator.geolocation.getCurrentPosition(geolocation => {
            this.latitude = geolocation.coords.latitude
            this.longitude = geolocation.coords.longitude
        })
      },
      getDriver(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getDrivers')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
      create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('name',this.item.name)
            fd.append('telephone',this.item.telephone)
            fd.append('email',this.item.email)
            fd.append('idno',this.item.idno)
            fd.append('username',this.item.username)
            fd.append('organisation_id',this.item.organisation_id)
            fd.append('vehicle_id',this.item.vehicle_id)
            fd.append('profile',this.item.profile)
            fd.append('expiry',this.date)


            axios.post('/driver', fd, config).then(res=>{
                this.loading = false
                this.disabled = false
                this.dialog = false

                if(res.data.status === 200) {
                  let found = this.$store.state.drivers.find(data => data.id == res.data.driver.id);
                  if (!found) {
                    this.$store.state.drivers.unshift(res.data.driver)
                  }
                  this.$toast.success('Driver added successfully','Success',{position: 'topRight',timeout: 7000,})
                }

                this.previewImage = null
                this.item.profile = null
                this.clear()
                  
              }).catch(error=>{
                this.loading = false
                this.disabled = false
                console.log(error)
              })

          }

        },
        clear () {
          this.$refs.form.reset()
        },
        update(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('name',this.item.updatename)
          fd.append('telephone',this.item.updatetelephone)
          fd.append('email',this.item.updateemail)
          fd.append('idno',this.item.updateidno)
          fd.append('username',this.item.updateusername)
          fd.append('organisation_id',this.item.updateorganisation_id)
          fd.append('vehicle_id',this.item.updatevehicle_id)
          fd.append('profile',this.item.profile)
          fd.append('_method',this.item._method)

          axios.post(`/driver/${this.item.updateID}`, fd, config).then(res=>{
              this.loading = false
              this.disabled = false
              this.updatedialog = false

              if(res.data.status === 200) {
                let index = this.$store.state.drivers.findIndex(data => data.id == res.data.driver.id);
                if (index > -1) {
                  this.$store.state.drivers.splice(index, 1, res.data.driver);
                }
                this.$toast.success('Driver updated successfully','Success',{position: 'topRight',timeout: 7000,})
              }

              this.previewImage = null
              this.item.profile = null
              this.item.updateID = ''

              this.clear()
                
            }).catch(error=>{
              this.loading = false
              this.disabled = false
              console.log(error)
            })

            }

        },
      editOrg(id) {
        let index = this.alldrivers.filter(item => item.id === id );
          this.item.updatename = index[0].name
          this.item.updatetelephone = index[0].telephone
          this.item.updateemail = index[0].email
          this.item.updateidno = index[0].idno
          this.item.updateusername = index[0].username
          this.item.updateorganisation_id = parseInt(index[0].organisation_id)
          this.item.updatevehicle_id = parseInt(index[0].vid)
          this.previewImage = index[0].profile
          this.documents = index[0].document
        this.item.updateID = index[0].id
        this.updatedialog = true

      },
      deleteItem(id){
            if(confirm("Are you sure to delete driver ?")){

              this.$store
              .dispatch('deleteDriver', {id: id})
              .then(response => {
                if(response.data.status === 200) {
                  let index = this.$store.state.drivers.findIndex(data => data.id == response.data.driver.id);
                  if (index > -1) {
                    this.$store.state.drivers.splice(index, 1);
                  }
                  this.$toast.success('Driver deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                }
                
              })
              .catch(() => {
                console.log("Unexpected error occured")
              })
              
            }
        }
    }
}
</script>