<template>
	<div>
	  <!-- client profile modal -->
      <v-dialog
        v-model="show"
        max-width="380px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add Waitings</span>
          </v-card-title>

          <v-card-text>
              <v-row
                v-for="(waiting, indx) in waitings" 
                  :key="indx"
                  style="display:flex;align-items:center;justify-content:space-between"
                  class="mx-0 my-2"
                >
                <v-col
                  cols="12"
                  sm="12"
                  md="5"
                  style="margin-top:-.7rem"
                  >
                    <v-text-field
                      v-model="waiting.starttime"
                      label="Select start time*"
                      type="tel" 
                      v-mask="'##:##'"
                      :rules="fieldRules"
                      required
                      list="data"
                      hide-details
                      autocomplete="off"
                    ></v-text-field>
                    <datalist id="data">
                        <option v-for="time in $store.state.times" :value="time" />
                    </datalist>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="5"
                  style="margin-top:-.7rem"
                  >
                    <v-text-field
                      v-model="waiting.endtime"
                      label="Select end time*"
                      type="tel" 
                      v-mask="'##:##'"
                      :rules="fieldRules"
                      required
                      list="data"
                      hide-details
                      autocomplete="off"
                    ></v-text-field>
                    <datalist id="data">
                        <option v-for="time in $store.state.times" :value="time" />
                    </datalist>
                </v-col>

                <v-col
                    cols="12"
                    sm="12"
                    md="2"
                    >
                    <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                      <v-icon @click="addWaiting" class="mx-3">mdi-plus</v-icon>
                      <v-icon @click="removeWaiting(indx)">mdi-close</v-icon>
                    </div>
                </v-col>
              </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="show = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="submit"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end client profile -->
	</div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'waiting',
	components: {},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        waitings: [
          {
            starttime: "",
            endtime: "",
          }
        ],
	}),
	methods: {
    addWaiting () {
        this.waitings.push({
          starttime: "",
          endtime: "",
        })
      },
      removeWaiting (index) {
        this.waitings.splice(index, 1);
      },
    submit() {
      if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('waitings',JSON.stringify(this.waitings))
          fd.append('id',this.waitingdetails[0].id)

          axios.post('/booking/savewaitings', fd, config).then(response=>{

            if(response.data.status === parseInt(201)) {
              this.$toast.success("Waiting time added successfully",'Success',{position: 'topRight',timeout: 7000,})
            }

            this.waitings = []
            this.waitings.push({
              starttime: "",
              endtime: "",
            })

            this.show = false
            this.loading = false
            this.disabled = false
            
          })
          .catch(() => {
            this.loading = false
            this.disabled = false
          })

      }
    }
  },
	props: {
	     value: Boolean,
	     waitingdetails: Array
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {},
	created() {}
}
</script>