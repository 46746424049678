<template>
	<div class="pt-0 mt-0 d-flex">
        <v-col class="px-1 py-0 my-0" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;width:200px !important;">
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="$store.state.todaysearchdate"
                  label="Date"
                  v-bind="attrs"
                  v-on="on"
                  solo
                  hide-details
                  clearable
                  @click:clear="clearDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="$store.state.todaysearchdate"
                no-title
                scrollable
                @input="menu = false"
                @click:date="selectedDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="#f9780d"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="#f9780d"
                  @click="cleardate"
                >
                  Clear
                </v-btn>
                <v-btn
                  text
                  color="#f9780d"
                  @click="$refs.menu.save($store.state.todaysearchdate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
        </v-col>
        <v-col class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;width:200px !important">
          <v-text-field
            v-model="search"
            label="Search bookings"
            solo
            hide-details="auto"
            clearable
            @click:clear="onClearClicked"
            @input="searchBooking"
          ></v-text-field>
        </v-col>
      </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'

export default {
	name: 'todayTookingSearch',
	components: {
	},
	data: () => ({
		messageclientdialog: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        search: "",
        menu:false,

	}),
	methods: {
		selectedDate(dateselected) {
			this.$store.state.todaysearchdate = dateselected
			this.$store.state.tableloader = true
			this.$store.dispatch('loadtodaybookingsbydate',dateselected)
		},
		onClearClicked () {
        this.search = ''
      },
       cleardate () {
        this.$store.commit('clearDate')
        this.$store.state.tableloader = true
		    this.$store.dispatch('getTodayBookings')
        this.menu = false
      },
      clearDate() {
      	this.$store.commit('clearDate')
      	this.$store.state.tableloader = true
		    this.$store.dispatch('getTodayBookings')
      },
      searchBooking() {
      	this.$store.commit('todaysearchBooking',this.search)
      }
	},
	props: {},
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
	    date: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$store.state.todaysearchdate = value
	      }
	    },
	  },
	mounted() {
    this.$store.state.todaysearchdate = moment().format('YYYY-MM-DD')
	},
	created() {
     
    }
}
</script>