<template>
    <!-- BEGIN: Content-->
    <div class="column list-column">
      <v-fade-transition>
        <div class="classloader">
           <Spinner name="circle" color="#008000" size="20" fadeIn="quarter" v-show="overlayprofile"/>
        </div>
      </v-fade-transition>
      <div v-if="details !== null" style="margin-top:3rem;display:flex;flex-direction:column;align-items:center;justify-content:center">
            <v-avatar size="100" v-if="details.profile !== null">
              <v-img :src="details.profile"
                :alt="details.name"></v-img>
            </v-avatar>
            <v-avatar size="100" v-else>
              <v-img src="@/assets/no_user.png"
                alt="profile"></v-img>
            </v-avatar>
            <div style="display:flex;flex-direction:column">
              <h3 class="text-center mt-2">{{ details.name }}</h3>
              <star-rating v-bind:increment="0.5"
                           v-bind:max-rating="5"
                           v-model:rating="rating"
                           inactive-color="#c8c8c8"
                           active-color="#F9780D"
                           v-bind:star-size="25"
                           class="mt-2"></star-rating>
            </div>
            <div class="d-flex flex-column justify-start" style="margin-top:1.5rem;width:100%;">
              <table class="ttable">
                <tr><td>Telephone</td><td>{{ details.telephone }}</td></tr>
                <!-- <tr><td>National ID</td><td>{{ details.idno }}</td></tr>
                <tr><td>Email</td><td>{{ details.email }}</td></tr> -->
                <tr><td>Vehicle</td><td>{{ details.vname }}</td></tr>
                <tr><td>Registration</td><td>{{ details.registration }}</td></tr>
                <tr><td>Organisation</td><td><div v-if="details.organisation !== null">{{ details.organisation.name }}</div><div v-else>N/A</div></td></tr>
              </table>
            </div>

            <v-tabs
              color="deep-purple accent-4"
              v-model="tab"
              vertical
             >
              <v-tab link :to='{name:"driverProfile",params:{id:details.id}}'>Commisions</v-tab>
              <v-tab link :to='{name:"driverInvoice",params:{id:details.id}}'>Invoices</v-tab>
              <v-tab link :to='{name:"driverReceipt",params:{id:details.id}}'>Payment Receipts</v-tab>
              <v-tab link :to='{name:"driverRating",params:{id:details.id}}'>Customer Ratings</v-tab>
              <v-tab link :to='{name:"driverDocument",params:{id:details.id}}'>Documents</v-tab>
              <v-tab link :to='{name:"driverExpiry",params:{id:details.id}}'>Psv Expiry</v-tab>
              <v-tab link :to='{name:"driverDisciplinary",params:{id:details.id}}'>Displinary Record</v-tab>
            </v-tabs>
        </div>
    </div>
</template>
<style>
  .profile .v-list-item__title{
    margin-bottom:.5rem !important;
  }
  .profile .v-list-item__subtitle, .profile .v-list-item__title, .profile .v-list-item__action-text{
    white-space: normal !important;
    text-transform: none !important;
  }
  .profile .v-list-item__subtitle, .profile .v-list-item__action-text{
    font-size:.8rem !important;
  }
  .v-list-item--link:before{
    background-color: #fff !important;
  }
  .v-pagination__item{
    box-shadow: none !important;
  }
  .theme--light.v-tabs>.v-tabs-bar,.v-tabs-slider{
      background: transparent !important;
    }
  /*  .v-slide-group__wrapper{
      margin-top: -3rem !important;
    }*/
    .v-pagination__navigation{
      box-shadow: none !important;
    }
    /*.v-window.v-item-group{
      margin-top:-3rem !important;
      padding:1rem !important;
    }*/
    .v-tabs-bar,.v-tabs-bar__content{
      background-color: #fff !important;
    }
    .v-tabs-items{
      background-color: #fff !important;
      margin-top:.3rem !important;
    }
    .driverProfile .v-slide-group__content {
      /*background:#cecece !important;*/
      padding: 1rem .3rem !important;
    }

</style>
<style scoped>

    .v-tabs ::v-deep .v-tabs-slider-wrapper {
      transition: none !important;
    }
    @media (min-width: 576px){
        .modal-slide-in .modal-dialog {
            width: 48rem;
        }
    }

    .vs__selected, .vs__search,.vs__search:focus{
        color: #B4B7BD !important;
    }
    .vs__open-indicator,.vs__clear{
        fill:  #B4B7BD !important;
    }

    .v-tabs-slider-wrapper{
      display: none !important;
    }
    .v-slide-group__content{
      width:100% !important;
      justify-content: flex-end !important;
    }
    
    .v-pagination__item, .v-pagination__navigation{
      box-shadow: none !important;
    }
    .v-pagination__navigation{
      background:#F1F3F4 !important;
    }
    .v-pagination{
      display: flex;
      justify-content: flex-end;
      margin-top:1.2rem;
    }

    .v-tab{
      padding:.5rem 1.5rem !important;
      background:#FFFFFF;
      border-radius: 50px !important;
      height:2.4rem !important;
      margin:.4rem;
    }
    .v-tab.v-tab--active{
      background:#DEC7FF;
    }

</style>
<script>
import axios from 'axios'
import StarRating from 'vue-star-rating'
import {ability} from '@/services/ability'

import Spinner from 'vue-spinkit'

export default {
    name:"profile",
    components: {
      StarRating,Spinner
    },
    data(){
        return {
          overlayprofile:false,
          tab: null,
          dialog: false,
          updatedialog: false,
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          imageRules: [
            value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
          ],
           menu: false,
           date: "", 
           rowHeight: 20,
           previewImage: null,
           documents: null,
           valid: true,
           start: 0,
            timeout: null,
            isBusy: false,
            loading: false,
           disabled: false,
           height: '100%' 
          }
    },
    beforeMount() {
      if(!ability.can('user-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = "Driver Profile"
      // if(this.details == null) {
        this.showDetails(this.driverid)
      // }
    },
    props: {
       driverid: Number
    },
    // watch: {
    //     driver: function (newVal,oldVal) {
    //       if(newVal == true) {
    //         this.showDetails(newVal)
    //       }
    //     }
    // },
    computed: {   
      loader() {
        return this.$store.state.tableloader
      }, 
      details() {
        return this.$store.state.driverdetails
      }, 
      rating() {
        return this.$store.state.driverrating
      }, 
      driver() {
        return this.$store.state.driverid
      },  
    },
    methods:{
      showDetails(id) {
        this.overlayprofile = true
        axios.get(`/driver/${id}`).then(response=>{
            this.overlayprofile = false
            this.$store.state.driverdetails = response.data.details
            this.$store.state.driverrating = parseInt(response.data.rating_avg)
        }).catch(error=>{
            console.log(error)
        })
      },
    }
}
</script>