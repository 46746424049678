<template>
    <!-- BEGIN: Content-->
    <div class="showPayment" style="margin-top:-2.8rem">
      <div class="mx-0 px-0" style="position:relative">
      <v-row class="my-0 py-0" style="margin-top:0 !important;margin-bottom:0 !important">
        <v-col cols="12" class="invoice-content py-0 my-0">
          <div class="column list-column">
            <div class="fill header list-header d-flex">
              <div class="section_title"><span class="title">All Payments&nbsp;&nbsp;</span> <div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader"
                  ></v-progress-circular></div></div>
                  <v-text-field
                    v-model="searchfilter"
                    label="Search"
                    solo
                    hide-details="auto"
                    class="search1_"
                  ></v-text-field>
            </div>
            <div class="fill body list-body d-flex scroll-y">
              <div class="scroll-y scrollbox">
                <ul class="content-list" id="itemList">
                  <li @click="viewPayment(payment.id)" v-for="(payment,index) in itemsForList" :key="index">
                    <div class="list-title">
                      <span style="font-size: var(--ab-font-size-up) !important;">{{ payment.organisation !== null ? payment.organisation.name : "Private" }}</span>
                      <span style="font-size: var(--ab-font-size-up) !important;">KES{{ payment.paidamount | currency('') }}</span>
                    </div>
                    <div class="list-subtitle">
                      <span style="font-size: var(--ab-font-size-up) !important;"><a href="" style="color:#F98525 !important;text-decoration:none !important;font-size: var(--ab-font-size-up) !important;font-weight:500 !important">{{ payment.payment_no }}</a> | {{ payment.reference_no }} | {{ moment(payment.date).format('MMM Do, YYYY') }}</span>
                      <span style="color:#44C189;font-size: var(--ab-font-size-up) !important;" v-if="payment.invoice !== null">CLOSED</span>
                      <span style="font-size: var(--ab-font-size-up) !important;" v-else>OPEN</span>
                    </div>
                  </li>
                </ul>
                <v-pagination
                              v-model="currentPage"
                              :length="Math.ceil(allpayments.length/perPage)"
                              v-if="allpayments.length > 0"
                            ></v-pagination>
              </div>
            </div>
          </div>
          <div class="column content-column">
            <div class="fill header list-header d-flex">
              <div class="section_title"><span class="title" style="color:#888888">{{ details !== null ? details.payment_no : "" }}&nbsp;&nbsp;</span> <div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader1"
                  ></v-progress-circular></div></div>
            </div>
            <div class="fill subheader list-subheader d-flex">
              <v-btn depressed color="white" variant="light" class="light" size="sm"><router-link link :to='{name:"editPayment",params:{id:item.id}}'><v-icon class="mr-1">mdi-pencil</v-icon> Edit</router-link></v-btn>
              <v-btn depressed color="white" v-if="details !== null && details.invoice === null" variant="light" class="light" size="sm" @click="linkpayment(details.id,details.organisation_id,details.payment_no)"><v-icon class="mr-1">mdi-record-circle-outline</v-icon> Link Payment</v-btn>

              <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon>
                    <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                
                <v-list-item-group
                  color="primary"
                  class="actions"
                  >
                    <v-list-item
                      @click="downloadpdf"
                      >
                        <v-list-item-icon>
                          <v-icon color="#000000">mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <span>Export Pdf</span>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
              </v-menu>              
            </div>
            <div class="fill body list-body d-flex scroll-y">
              <div class="scroll-y scrollbox">
                <div class="invoice-template" v-if="details !== null">
                  <div class="ribbon ribbon-top-right" v-if="details.invoice !== null">
                    <span>CLOSED</span>
                  </div>
                  <div v-else class="ribbon ribbon-top-right">
                    <span>OPEN</span>
                  </div>
                  <v-row class="mt-4">
                    <v-col cols="12" md="6">
                      <img src="/assets/images/logo/logo.png" class="mt-2" style="width:60% !important;">
                    </v-col>
                    <v-col cols="12" md="6" style="text-align:right">
                      <p class="mt-2" style="font-size:.75rem !important;line-height:1.6">P.O Box 6137-00200-Nairobi</br>
                      Kandara Road, Hse 9, Kileleshwa</br>
                      Telephone: 0202011846/2011824</br>
                      Cellphone: 0721400422/0728600654/<br>0733523047/0735009952</br>
                      Email: <a href="mailto:info@absoluteadventuresafaris.com" target="_blank">info@absoluteadventuresafaris.com</a></br>
                      PIN NO: P051199717F | VAT NO: P051199717F</p>
                    </v-col>
                  </v-row>
                  <hr class="mt-0">
                  <v-row class="mb-3 mt-0 pt-2 text-center"><v-col cols="12"><span class="title"><u>PAYMENT RECEIPT</u></span></v-col></v-row>

                  <v-row>
                    <v-col cols="12" sm="12" lg="7" md="6">
                      <p style="margin-top:2rem !important;">
                        <table class=" custom-font-size">
                          <tr>
                            <td style="padding:.4rem !important;width:200px !important;font-size: var(--ab-font-size-up) !important;">Payment #</td>
                            <td style="border-bottom:1px solid #dedede !important;font-size: var(--ab-font-size-up) !important;">{{ details.payment_no }}</td>
                          </tr>
                          <tr>
                            <td style="padding:.4rem !important;width:200px !important;font-size: var(--ab-font-size-up) !important;">Payment Date</td>
                            <td style="border-bottom:1px solid #dedede !important;font-size: var(--ab-font-size-up) !important;">{{ moment(details.date).format('Do MMM YYYY') }}</td>
                          </tr>
                          <tr>
                            <td style="padding:.4rem !important;width:200px !important;font-size: var(--ab-font-size-up) !important;">Reference Number</td>
                            <td style="border-bottom:1px solid #dedede !important;font-size: var(--ab-font-size-up) !important;">{{ details.reference_no }}</td>
                          </tr>
                          <tr>
                            <td style="padding:.4rem !important;width:200px !important;font-size: var(--ab-font-size-up) !important;">Payment Mode</td>
                            <td style="border-bottom:1px solid #dedede !important;font-size: var(--ab-font-size-up) !important;">{{ details.mode.name }}</td>
                          </tr>
                        </table>
                      </p>
                    </v-col>
                    <v-col cols="12" sm="12" lg="5" md="6" class="mt-5" style="display:flex;justify-content:center;align-items:center">
                      <div style="width:100%;height:140px !important;background:#78ae54;display:flex;justify-content:center;align-items:center;flex-direction:column;color:#fff">
                        <small style="font-size:.9rem !important">Amount Received</small>
                        <p style="font-size:1.4rem !important;color:#fff">KES{{ details.paidamount | currency(' ') }}</p>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <p style="font-size:.92rem !important;margin-top:2rem !important">Bill To</p>
                      <p style="color:#F98525;margin-top:-.8rem !important"><span style="font-size:var(--ab-font-size-up) !important;text-transform:uppercase !important">{{ details.organisation.name }}</span></p>
                    </v-col>
                  </v-row>
                  <v-row v-if="details.invoice !== null" class="mt-3 pb-2">
                    <v-col cols="12" lg="12">
                      <hr class="mt-0">
                    </v-col>
                    <v-col cols="12" lg="12" class="mt-1">
                      <span style="font-weight:500 !important" class="title mb-2">Payment for</span>
                      <p>
                        <table class=" custom-font-size">
                          <tr style="background:#eff0f1">
                            <td style="padding:.7rem !important;font-size:var(--ab-font-size-up) !important;font-weight:500 !important">Invoice Number</td>
                            <td style="padding:.7rem !important;font-size:var(--ab-font-size-up) !important;font-weight:500 !important">Invoice Date</td>
                            <td style="padding:.7rem !important;font-size:var(--ab-font-size-up) !important;font-weight:500 !important">Invoice Amount</td>
                            <td style="padding:.7rem !important;font-size:var(--ab-font-size-up) !important;font-weight:500 !important">Payment Amount</td>
                          </tr>
                          <tr>
                            <td style="padding:.7rem !important;font-size:var(--ab-font-size-up) !important">{{ details.invoice.invoice_no }}</td>
                            <td style="padding:.7rem !important;font-size:var(--ab-font-size-up) !important">{{ moment(details.invoice.date).format('Do MMM, YYYY') }}</td>
                            <td style="padding:.7rem !important;font-size:var(--ab-font-size-up) !important">KES{{ details.invoice.amount | currency(' ') }}</td>
                            <td style="padding:.7rem !important;font-size:var(--ab-font-size-up) !important">KES{{ details.paidamount | currency(' ') }}</td>
                          </tr>
                        </table>
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

    </div>

    <!-- components -->
    <LinkPayment v-model="paymentdialog"/>
    </div>

</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import {ability} from '@/services/ability'
import LinkPayment from '@/components/payments/linkpayment'

export default {
    name:"showPayment",
    components: { LinkPayment },
    data(){
        return {
          perPage: 17,
          currentPage: 1,
          daterange: {
            start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
          },
          searchfilter: '',
          search: '',
          dialog: false,
          searchdialog: false,
          loading: false,
          paymentdialog: false,
          fields: [
             {
              text: '#',
              align: 'start',
              value: '#',
            },
            { text: 'Date', value: 'date' },
            { text: 'Time', value: 'time' },
            { text: 'Ref', value: 'ref' },
            { text: 'From', value: 'from' },
            { text: 'To', value: 'to' },
            { text: 'Passenger', value: 'passenger' },
            { text: 'RequestType', value: 'requesttype' },
            { text: 'VehicleType', value: 'vehicletype' },
            { text: 'Distance(Km)', value: 'distance' },
            { text: 'Amount', value: 'amount' },
           ],
          item:{
             id: 0,
             organisationid: null,
             tolinkreceipt: ""
          },
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){
      this.item.id = this.$route.params.id
      this.getDetails()
      if(!this.allpayments || this.allpayments.length == 0) {
        this.getpayments()
      }

    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      loader1() {
        return this.$store.state.tableloader1
      },
      payments() {
        return this.$store.state.showpayments
      },
      allpayments() {
        return this.payments
      },
      details() {
        return this.$store.state.paymentdetail
      },
      rows() {
        return this.allpayments.length
      },
      itemsForList() {
        return this.allpayments.filter(item => {

          this.start = 0

          let payment_no = "";
          let reference_no = "";
          let organisation = "";
          if(item.organisation !== null) {
            organisation = item.organisation.name
          }
          if(item.payment_no !== null) {
            payment_no = item.payment_no
          }
          if(item.reference_no !== null) {
            reference_no = item.reference_no
          }
 
          return organisation.toLowerCase().includes(this.searchfilter.toLowerCase()) || payment_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || reference_no.toLowerCase().includes(this.searchfilter.toLowerCase())
        }).slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage,
        );
      }
    },
    watch: {
      details: function(newVal, oldVal) {},
    },
    methods:{
      linkpayment(id,organisationId,paymentNo) {
        this.$store.state.linkpaymentdata = {
          id: id,
          organisationid: organisationId,
          tolinkreceipt: paymentNo
        }
        this.paymentdialog = true
      },
      viewPayment(id) {
        this.item.id = id
        this.getDetails()
      },
      async getpayments(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getShowPayments')
      },
      async getDetails() {
        this.$store.state.tableloader1 = true
        await axios.get(`/viewsinglepayment/${this.item.id}`).then(response=>{
          this.$store.state.tableloader1 = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.paymentdetail = response.data.payment
            }
        }).catch(error=>{
          this.$store.state.tableloader1 = false
            console.log(error)
        })
      },
      async downloadpdf() {
        this.$store.state.tableloader1 = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',this.item.id)

        axios.post('/payment/download', fd, config).then(response=>{
         this.$store.state.tableloader1 = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', `${this.details.organisation.name}-${this.details.payment_no}.pdf`);
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader1 = false
          console.log(error)
        })
      },

    }
}
</script>