<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>
        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-text-field
              v-model="searchfilter"
              label="Search"
              solo
              hide-details="auto"
              class="search_"
            ></v-text-field>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            link
            :to="{name:'newQuotation'}"
          >
            Quotation
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="quotationList">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">
                <v-card-text  class="px-0 py-3 cardtext">
                  <div class="empty-state" v-if="allquotations.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>

                  <v-virtual-scroll
                    :items="allquotations"
                    :item-height="20"
                    renderless 
                    id="virtualscroll"
                    class="allquotations"
                   >
                    <template v-slot:default="{ item }">
                        <div class="tcell"> {{ moment(item.date).format('MMM Do YYYY') }}</div>
                        <div class="tcell">
                          <RouterLink style="color:#F98525 !important;font-weight:500 !important;text-decoration:none !important;font-size: 10px !important;" link
                                  :to='{name:"showQuotation",params:{id:item.id}}'>{{ item.quotation_no }}</RouterLink>
                                </div>
                        <div class="tcell">
                          <div>
                            <span v-if="item.organisation !== null">{{ item.organisation.name }},</span>
                            <span v-if="item.project !== null">{{ item.project.name }},</span>
                            <span v-if="item.customer !== null">{{ item.customer.name }},</span>
                          </div>
                        </div>
                        <div class="tcell">
                          <span v-if="item.status === 'INVOICED'">{{ item.status }}</span>
                          <span v-else>{{ item.status }}</span>
                        </div>
                        <div class="tcell">{{ moment(item.due_date).format('MMM Do YYYY') }}</div>
                        <div class="tcell">KES <span style="font-weight:500 !important">{{ item.amount | currency(' ') }}</span></div>
                        <div class="tcell">
                          <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  v-bind="attrs"
                                  small
                                  v-on="on">
                                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            
                            <v-list-item-group
                              color="primary"
                              class="actions"
                              >

                                <v-list-item
                                  @click="sendquote(item.id)"
                                  >
                                    <v-list-item-icon>
                                      <v-icon color="#000000">mdi-send-variant-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Send Quotation</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item
                                  link
                                  :to='{name:"convertQuotation",params:{id:item.id}}'
                                >
                                    <v-list-item-icon>
                                      <v-icon color="#000000">mdi-receipt-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Convert to Invoice</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item
                                  link
                                  :to='{name:"editQuotation",params:{id:item.id}}'
                                >
                                    <v-list-item-icon>
                                      <v-icon color="#000000">mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                              <v-list-item
                                link
                                @click="deleteQuotation(item.id)"
                                >
                                    <v-list-item-icon>
                                      <v-icon>mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Remove</v-list-item-title>
                                    </v-list-item-content>
                              </v-list-item>

                            </v-list-item-group>
                          </v-menu>
                        </div>
                    </template>
                  </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <!-- components -->
        <SendQuotation v-model="quotationdialog"/>

      </div>
    </div>

</template>
<style>
  .quotationList .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     grid-auto-columns: minmax(14.286%, 14.286%) !important;
     /*grid-template-columns: 20% 10% 30% 10% 15% 15%;*/
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

import SendQuotation from '@/components/quotations/sendquotation'

 export default {
  name: 'quotationList',
  components: { SendQuotation },
  computed: {
    mini() {
      return this.$store.state.mini
    },
    loader() {
      return this.$store.state.tableloader
    },
    quotations() {
      return this.$store.state.quotations.filter(item => {

        this.start = 0

        let quotation_no = "";
        let organisation = "";
        let amount = "";
        let date = "";
        // if(item.organisation !== null) {
        //   organisation = item.organisation.name
        // }
        if(item.quotation_no !== null) {
          quotation_no = item.quotation_no
        }
        if(item.amount !== null) {
          amount = item.amount
        }
        if(item.date !== null) {
          date = item.date
        }
        return quotation_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || date.includes(this.searchfilter)
      })
    },
    allquotations() {
      return this.quotations
    },
  },
  data: () => ({
      searchfilter: '',
      search: '',
      quotationdialog: false,
      loading: false,
      fields: [
         {
          text: 'DATE',
          align: 'start',
          value: 'date',
        },
        { text: 'QUOTATION#', value: 'quotationno' },
        { text: 'ORGANISATION NAME', value: 'organisation' },
        { text: 'STATUS', value: 'status' },
        { text: 'DUE DATE', value: 'duedate' },
        { text: 'AMOUNT', value: 'amount' },
        { text: 'ACTION', value: 'action' },
       ],
      item:{
        quotation_id: "",
        quotation_no: ""
      },
  }),

  mounted() {

    this.$store.state.loader_text = "Quotations"

    let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    if(!this.allquotations || this.allquotations.length == 0) {
      this.getQuotations()
    }
      
  },
  beforeMount() {
    if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
  },
  serverPrefetch () {
    // return this.getQuotations()
  },
  methods: {

    sendquote(id) {
      this.$store.state.itemid = id
      this.quotationdialog = true
    },

    getQuotations(){
      this.$store.state.tableloader = true
      this.$store
        .dispatch('getQuotations')
    },

    deleteQuotation(id){
        if(confirm("Are you sure to delete quotation ?")){
            axios.delete(`/quotations/${id}`).then(response=>{
              let index = this.$store.state.quotations.findIndex(data => data.id == response.data.quotation.id);
              if (index > -1) {
                this.$store.state.quotations.splice(index, 1);
              }
              this.$toastpopup('success','Success','Invoice removed successfully')
            }).catch(error=>{
                console.log(error)
            })
        }
    },

  }
};

</script>
