<template>
	<div>
    <v-dialog
      max-width="450px"
      v-model="show"
      >
        <v-card>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">
            <v-card-title class="d-flex justify-space-between">
              <div class="loadflex"><span class="text-h6">Link Debit Note&nbsp;&nbsp;<span style="font-size:14px !important;color:#888888">{{ item.tolinkreceipt }}&nbsp;&nbsp;</span></span><div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader"></v-progress-circular></div></div>
            </v-card-title>

            <v-card-text>
                <v-container class="mt-0 mx-0 px-0 mb-0">
                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-autocomplete
                          :items="invoices"
                          label="Select invoice"
                          v-model="item.invoice_no_id"
                          item-text="invoice_no"
                          item-value="id"
                          :search-input.sync="invoicesync"
                        >
                        </v-autocomplete>
                      </v-col>
                  </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click.stop="show = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="submit"
                >
                  Submit
                </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
    </v-dialog>

	</div>
</template>

<style>
  .modal-linkcreditnote{
    max-width:450px !important;
  }
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'

export default {
	name: 'linkdebitnote',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        invoicesync: null,
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        item:{
           invoice_no_id: null,
           organisationid: null,
           id: null,
           tolinkreceipt: ""
        },
       invoices: []
	}),
  watch: {
      data: function(newVal, oldVal) {
        if(newVal !== null) {
          this.item.id = newVal.id
          this.item.organisationid = newVal.organisationid
          this.item.tolinkreceipt = newVal.tolinkreceipt
          // this.getData()
        }
      },
      show: function (newVal,oldVal) {
        if(newVal == true) {
          this.getData()
        }
      }
  },
	methods: {
    async getData(){
        this.$store.state.componentloader = true
        await axios.get(`/payment/invoices/${this.item.organisationid}`).then(response=>{
            this.$store.state.componentloader = false
            if(response.data.status == parseInt(200)) {
              this.invoices = response.data.invoices
            }
        }).catch(error=>{
            this.$store.state.componentloader = false
            console.log(error)
        })
      },
    submit(){
      this.loading = true
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('id',this.item.id)
      fd.append('invoice_id',this.item.invoice_no_id)

      axios.post('/debit-note/link-invoice', fd, config).then(response=>{
        if(response.data.status == parseInt(200)) {

          let index = this.$store.state.debitnotes.findIndex(data => data.id == response.data.debitnote.id);
          if (index > -1) {
            this.$store.state.debitnotes.splice(index, 1, response.data.debitnote);
          }

          this.$toast.success('Debit note linked successfully','Success',{position: 'topRight',timeout: 7000,})
          this.clear()
          this.show = false
        }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    clear() {
      this.item.invoice_no_id = null
    }
	},
	props: {
	     value: Boolean
	  },
	  computed: {
      loader() {
        return this.$store.state.componentloader
      },
      data() {
        return this.$store.state.linkdebitdata
      },
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {
  },
	created() {}
}
</script>