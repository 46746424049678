<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>

        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-text-field
                v-model="search"
                label="Search organisation"
                solo
                hide-details="auto"
                class="search_"
              ></v-text-field>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            @click="edit(0)"
          >
            Rate
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="orgRateList">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">
                <v-card-text class="px-0 py-3 cardtext">
                  <div class="empty-state" v-if="allrates.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>
                  <v-virtual-scroll
                      :items="allrates"
                      :item-height="20"
                      renderless 
                      id="virtualscroll"
                      class="rates"
                     >
                      <template v-slot:default="{ item }">
                          <div class="tcell">{{ item.organisation.name }}</div>
                          <div class="tcell">{{ item.type.type }}</div>
                          <div class="tcell">{{ item.rate }}</div>
                          <div class="tcell">{{ item.base_rate}}</div>
                          <div class="tcell">
                            <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    small
                                    v-on="on">
                                  <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                </v-btn>
                              </template>
                              
                              <v-list-item-group
                                color="primary"
                                class="actions"
                                >

                                  <v-list-item
                                    @click="edit(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-pencil</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                <v-list-item
                                  link
                                  @click="deleteItem(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon>mdi-delete</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Remove</v-list-item-title>
                                      </v-list-item-content>
                                </v-list-item>

                              </v-list-item-group>
                            </v-menu>
                          </div>
                      </template>
                    </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- user update modal-->
        <v-dialog
          v-model="updatedialog"
          max-width="450px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6" v-if="item.updateID !== ''">Update organisation rate</span>
              <span class="text-h6" v-else>Add organisation rate</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-autocomplete
                        :items="organisations"
                        label="Select organisation*"
                        v-model="item.updateorganisation_id"
                        item-text="name"
                        item-value="id"
                        :rules="fieldRules"
                        :search-input.sync="searchorg"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-autocomplete
                        :items="vehicletypes"
                        label="Select vehicle type*"
                        v-model="item.updatevehicle_type_id"
                        item-text="type"
                        item-value="id"
                        :rules="fieldRules"
                        :search-input.sync="searchtype"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        label="Rate*"
                        v-model="item.updaterate"
                        :rules="fieldRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        label="Base Rate*"
                        v-model="item.updatebaserate"
                        :rules="fieldRules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click="updatedialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="update"
                  v-if="item.updateID !== ''"
                >
                  Save
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="create"
                  v-else
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- update user -->
      </div>
    </div>

</template>
<style>
  .rates .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     /*grid-auto-columns: minmax(20%, 20%) !important;*/
     grid-template-columns: 40% 20% 15% 15% 10%;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

export default {
    name:"organisationRates",
    components: { },
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          searchfilter:'',
          search:"",
          searchorg:"",
          searchtype:"",
          valid: true,
          dialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Business Config',
              disabled: false,
              href: '',
            },
            {
              text: 'Organisations',
              disabled: false,
              href: '',
            },
            {
              text: 'Rates',
              disabled: false,
              href: '',
            },
          ],
          fields: [
            { text: 'Organisation', value: 'organisation' },
            { text: 'Vehicle Type', value: 'type' },
            { text: 'Rate(per km)', value: 'rate' },
            { text: 'Base Rate', value: 'baserate' },
            { text: 'Action', value: 'action' },
           ],
          item:{
              updateorganisation_id:"",
              updatevehicle_type_id:"",
              updaterate:"",
              updatebaserate:"",
              updateID: "",
              _method:"patch"
          },
            isBusy: false,
            loading: false,
           disabled: false,
           height: '100%',
            rowHeight: 25,
            perPage: 150,
            start: 0,
            timeout: null 
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = "Organisation Rates"

      if(this.rates.length == 0) {
        this.getRates()
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)
      
    },
    serverPrefetch () {
      return this.getRates()
    },
    computed: {
      rates() {
        return this.$store.state.rates.filter(item => {
          this.start = 0
          return item.organisation.name.toLowerCase().includes(this.search.toLowerCase())
        })
      },
      allrates() {
        return this.rates
      },
      organisations() {
        return this.$store.state.organisations
      },
      vehicletypes() {
        return this.$store.state.vehicletypes
      },
      loader() {
        return this.$store.state.tableloader
      },
    },
    methods:{
      getRowClass(item) {
        return 'row-color' 
      },
      getRates(){
        this.$store.state.tableloader = true
        axios.get('/orgrate').then(response=>{
            this.$store.state.tableloader = false
            this.$store.state.vehicletypes = response.data.vehicle_types
            this.$store.state.organisations = response.data.organisation
            this.$store.state.rates = response.data.rates
        }).catch(error=>{
          this.$store.state.tableloader = false
            console.log(error)
        })
      },
      create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('organisation_id',this.item.updateorganisation_id)
            fd.append('vehicle_type_id',this.item.updatevehicle_type_id)
            fd.append('rate',this.item.updaterate)
            fd.append('baserate',this.item.updatebaserate)

            axios.post('/orgrate', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === 200) {

                let found = this.$store.state.rates.find(data => data.id == response.data.rate.id);
                if (!found) {
                  this.$store.state.rates.unshift(response.data.rate)
                }

                this.$toast.success('Rate added successfully','Success',{position: 'topRight',timeout: 7000,})
                this.item.updatevehicle_type_id = ''
                this.item.updateorganisation_id = ''
                this.item.updaterate = ''
                this.item.updatebaserate = ''

                this.updatedialog = false

              }
         
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
        update(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('organisation_id',this.item.updateorganisation_id)
            fd.append('vehicle_type_id',this.item.updatevehicle_type_id)
            fd.append('rate',this.item.updaterate)
            fd.append('baserate',this.item.updatebaserate)
            fd.append('_method',this.item._method)

            axios.post(`/orgrate/${this.item.updateID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === 200) {

                let index = this.$store.state.rates.findIndex(data => data.id == response.data.rate.id);
                  if (index > -1) {
                    this.$store.state.rates.splice(index, 1, response.data.rate);
                  }

                this.$toast.success('Rate updated successfully','Success',{position: 'topRight',timeout: 7000,})
                this.item.updatevehicle_type_id = ''
                this.item.updateorganisation_id = ''
                this.item.updaterate = ''
                this.item.updatebaserate = ''

                this.updatedialog = false

              }
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
      edit(id) {
        let index = this.rates.filter(item => item.id === id )
        if(index.length > 0) {

          this.item.updatevehicle_type_id = parseInt(index[0].vehicle_type_id)
          this.item.updateorganisation_id = parseInt(index[0].organisation_id)
          this.item.updaterate = index[0].rate
          this.item.updatebaserate = index[0].base_rate
          this.item.updateID = index[0].id
          this.updatedialog = true

        }else {
          this.item.updateID = ''
          this.item.updatevehicle_type_id = ''
          this.item.updateorganisation_id = ''
          this.item.updaterate = ''
          this.item.updatebaserate = ''
          this.updatedialog = true
        }
        
      },
      deleteItem(id){
            if(confirm("Are you sure to delete rate ?")){
                axios.delete(`/orgrate/${id}`).then(response=>{
                  if(response.data.status === 200) {

                    let index = this.$store.state.rates.findIndex(data => data.id == response.data.rate.id);
                    if (index > -1) {
                      this.$store.state.rates.splice(index, 1);
                    }

                    this.$toast.success('Rate deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                  }
                }).catch(error=>{
                    console.log(error)
                })
            }
        }
    }
}
</script>