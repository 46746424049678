<template>
  <div>
    <!-- client profile modal -->
      <v-dialog
        v-model="show"
        max-width="480px"
        >
        <v-card>
          <div style="width:100%;height:.3rem !important">
            <v-progress-linear
              color="#F9780D"
              buffer-value="0"
              stream
              height="4"
              :active="tableloader"
            ></v-progress-linear>
          </div>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Deductions</span>
          </v-card-title>

          <v-card-text>
            <v-row  v-if="deductions.length > 0">
              <v-col
              cols="12"
              sm="12"
              md="12"
              >
                <v-alert
                  text
                  color="info"
                  v-for="(deduction,index) in deductions"
                  :key="index"
                  class="pt-3 my-1"
                  >
                  <v-row
                    align="center"
                    no-gutters
                    class="my-0 py-0"
                    style="display:flex;align-items:center;justify-content:space-between"
                    >
                    <div class="d-flex" style="font-size:.75rem">{{ deduction.name }}</div>
                    <div class="d-flex" style="font-size:.75rem">{{ deduction.amount | currency('') }}</div>
                    <v-col class="shrink" style="display:flex;align-items:center;justify-content:space-between">
                      <v-btn
                        color="red"
                        outlined
                        :loading="loading1"
                        @click="removedeductions(deduction.id)"
                        class="mx-1"
                      >
                        Remove
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
            <v-row
                v-for="(add, indx) in adds" 
                  :key="indx"
                  style="display:flex;align-items:end;justify-content:space-between"
                  class="mx-0 my-2"
                >
                  <v-col
                      cols="12"
                      sm="12"
                      md="5"
                      >
                      <v-text-field
                        label="Deduction*"
                        v-model="add.name" 
                        :name="`adds[${indx}][name]`"
                        required
                        :rules="fieldRules"
                        hide-details
                      ></v-text-field>

                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="5"
                    >

                      <v-text-field
                        label="Amount*"
                        v-model="add.amount" 
                        :name="`adds[${indx}][amount]`"
                        required
                        :rules="fieldRules"
                        hide-details
                      ></v-text-field>
                  </v-col>


                  <v-col
                    cols="12"
                    sm="12"
                    md="2"
                    >
                    <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                      <v-icon @click="addDeduction" class="mx-3">mdi-plus</v-icon>
                      <v-icon @click="removeDeduction(indx)">mdi-close</v-icon>
                    </div>
                </v-col>
              </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="show = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="submit"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end client profile -->
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
// import VuePdfApp from "vue-pdf-app";
// import "vue-pdf-app/dist/icons/main.css";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: 'deductions',
  components: {
    VuePdfEmbed
  },
  data: () => ({
    textLayer: true,
    annotationLayer: true,
    isLoading: true,
    pdfdialog: false,
    loading1: false,
    fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    imageRules: [
      value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
    ],
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    adds: [
      {
        name: "",
        amount: "",
      }
    ],
    results: []
  }),
  methods: {
    addDeduction () {
        this.adds.push({
          name: "",
          amount: "",
        })
      },
      removeDeduction (index) {
        this.adds.splice(index, 1);
      },
    async getDeduction(){
        this.$store
          .dispatch('getDeduction',this.bookingid)
          .then(() => {})
          .catch(() => {})
      },
      submit(){
          if (this.$refs.form.validate()){

            this.loading = true
            this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.bookingid)
            fd.append('data',JSON.stringify(this.adds))

            axios.post('/driver/deduction/save', fd, config).then(response=>{

              if(response.data.status === parseInt(200)) {

                let index = this.$store.state.commissions.findIndex(data => data.id == response.data.commission.id);
                if (index > -1) {
                  this.$set(this.$store.state.commissions, index, response.data.commission);
                }

                this.$toast.success("Amount added successfully",'Success',{position: 'topRight',timeout: 7000,})
              }

              this.adds = []
              this.adds.push({
                name: "",
                amount: "",
              })

              this.show = false
              this.loading = false
              this.disabled = false
              
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

        }
      },
      removedeductions(id){
        this.$store.state.tptableloader = true
        this.loading1 = true
        axios.get(`/remove-deduction/${id}`).then(response=>{
          this.$store.state.tptableloader = false
          this.loading1 = false
          if(response.data.status == parseInt(200)) {
            let index = this.$store.state.deductions.findIndex(data => data.id == response.data.id);
            if (index > -1) {
              this.$store.state.deductions.splice(index, 1);
            }

            let index1 = this.$store.state.commissions.findIndex(data => data.id == response.data.commission.id);
            if (index1 > -1) {
              this.$set(this.$store.state.commissions, index1, response.data.commission);
            }

            this.$toast.success('Amount removed successfully','Success',{position: 'topRight',timeout: 7000,})
          }
        }).catch(error=>{
          this.$store.state.tptableloader = false
          this.loading1 = false
          console.log(error)
        })
      }
  },
  watch: {
      show: function (newVal,oldVal) {
        if(newVal == true) {
          this.getDeduction()
        }
      }
  },
  props: {
       value: Boolean,
       bookingid: Number
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
           this.$emit('input', value)
        }
      },
      deductions() {
        return this.$store.state.deductions
      },
      tableloader() {
        return this.$store.state.tptableloader
      }
    },
  mounted() {},
  created() {}
}
</script>