<template>
  <div class="Login">
       <v-container fluid fill-height style="margin-top:2rem;">
          <v-layout align-center justify-center>
              <v-form  enctype="multipart/form-data" v-model="valid" ref="form" class="forms">
                <v-card class="card-shadow px-3">
                   <v-card-text style="padding:0 !important">
                      <v-col cols="12" class="px-2">
                        <img src="/assets/images/logo/logo.png" style="width:25% !important;text-align:center">
                        <h2 class="card-title fw-bold mt-3">Welcome to Absolute Cabs! 👋</h2>
                          <p class="card-text mb-0">Please sign-in to your account</p>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                         <v-text-field
                            prepend-icon="mdi-email"
                            name="email"
                            label="Email Address"
                            v-model="form.email"
                            :rules="emailRules"
                         ></v-text-field>
                       </v-col>
                       <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                         <v-text-field
                            id="password"
                            prepend-icon="mdi-lock"
                            name="password"
                            label="Password"
                            :append-icon="value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="() => (value = !value)"
                            :type="value ? 'password' : 'text'"
                            v-model="form.password"
                            :rules="fieldRules"
                         ></v-text-field>
                       </v-col>
                         <v-col cols="12">
                           <router-link :to="{ name: 'ForgotPassword' }" style="font-size:.8rem ">Forgot Password?</router-link>
                         </v-col>
                   </v-card-text>
                   <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary"
                      :loading="loading"
                      :disabled="!valid"
                      @click="create"
                      >Login</v-btn>
                   </v-card-actions>
                  <!--  <p class="text-center" style="padding:1rem"><span>New on our platform?</span><router-link :to="{ name: 'Signup' }"><span>&nbsp;Create an account</span></router-link></p> -->
                </v-card>
               </v-form>
          </v-layout>
       </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
    components: {
    },
    data: () => ({
      value: String,
      valid: true,
      fieldRules: [
      (v) => !!v || 'Field is required'
      ],
      emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      type: 'password',
      loading: false,
      disabled: false,
      showPassword: false,
      form: {
        email: null,
        password: null
      }
    }),
    computed: {
      ...mapGetters([
        'isLogged'
        ])
    },
    methods: {
        create() {

          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true

            this.$store
            .dispatch('login', {
              email: this.form.email,
              password: this.form.password
            })
            .then(() => {

              this.loading = false
              this.disabled = false

              if(this.$store.state.user.status === parseInt(200))
              {

                this.$toast.success('Login was successful','Success',{position: 'topRight',timeout: 7000,})
                
                this.$router.push({ name: 'Dashboard' })
                // window.location.href = '/'

              }else{
                this.$toast.error('Credentials does not match','Error',{position: 'topRight',timeout: 7000,})
              }
            })
            .catch(() => {

              this.loading = false
              this.disabled = false

              this.$toast.error('Confirm details and try again','Error',{position: 'topRight',timeout: 7000,})
            })

          }

        }
    }
}
</script>