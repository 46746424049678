<template>
  <v-col cols="12" sm="12" class="solo-wrapper px-0 mx-0">
    <v-form class="py-1"  enctype="multipart/form-data" v-model="valid" ref="form">
      <v-row>
        <div class="ml-4 py-0 d-flex align-center">
          <h5>New Invoice</h5>
        </div>
        <v-col
          cols="12"
          xl="2"
          md="3"
          class="py-0 d-flex align-center sinvoice"
          >
          <date-range-picker
            ref="picker"
            :showWeekNumbers="showWeekNumbers"
            :showDropdowns="showDropdowns"
            :autoApply="autoApply"
            opens="right"
            :always-show-calendars="alwaysshowcalendars"
            v-model="dateRange"
            @select="updateValues"
          >
              <template v-slot:input="picker" style="min-width: 350px;">
                  <v-text-field
                    label="Select date range"
                    :rules="fieldRules"
                    v-model="date"
                    hide-details
                    solo
                  ></v-text-field>
              </template>
          </date-range-picker>
        </v-col>

        <v-col
          cols="12"
          xl="2"
          md="2"
          class="py-0 d-flex align-center"
          >
            <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="submit"
              >
                Submit
              </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>

<style>
  .v-select:not(.v-select--is-multi).v-text-field--single-line .v-select__selections{
    text-transform: uppercase !important;
  }
  .sinvoice .reportrange-text input{
      background-color: #f5f5f5 !important;
      padding-left: .5rem !important;
      padding-right: .5rem !important;
      border-radius: 5px !important;
    }
  .sinvoice .reportrange-text label{
      margin-left: .5rem !important;
    }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { format, parseISO } from 'date-fns'

export default {
  name: 'newinvoice',
  components: {
    DateRangePicker
  },
  data: () => ({
    menu: false,
    tableloader: false,
    loading1: false,
    fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    organisation_id: "",
    updateaccount: null,

    dateRange: {
      startDate: null,
      endDate: null
     },
    singleDatePicker: true,
    showWeekNumbers: true,
    showDropdowns: true,
    autoApply: true,
    alwaysshowcalendars: true,
    date: "",
    dates: [],
    daterange: {
      start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    },
    rangedate: "",
  }),
  methods: {

      updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.date = this.daterange.start+" - "+this.daterange.end
      },

      submit(){
          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.driverid)
            fd.append('start_date',this.daterange.start)
            fd.append('end_date',this.daterange.end)

            axios.post('/driver/invoice/new', fd, config).then(response=>{

              if(response.data.status === parseInt(200)) {

                let found = this.$store.state.driverinvoices.find(data => data.id == response.data.invoice.id);
                if (!found) {
                  this.$store.state.driverinvoices.unshift(response.data.invoice)
                }else {
                  let index = this.$store.state.driverinvoices.findIndex(data => data.id == response.data.invoice.id);
                  if (index > -1) {
                    this.$set(this.$store.state.driverinvoices, index, response.data.invoice);
                  }
                }

                this.$toast.success("Invoice created successfully",'Success',{position: 'topRight',timeout: 7000,})
              }


              this.show = false
              this.loading = false
              this.disabled = false
              
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

        }
      },

  },
  props: {
       value: Boolean,
       driverid: Number
    },
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
      show: {
        get () {
          return this.value
        },
        set (value) {
           this.$emit('input', value)
        }
      },
    },
  mounted() {
  },
  watch: {
  
  },
  created() {}
}
</script>