<template>
  <v-col sm="10" md="10" cols="10" class="mx-0 px-0">
    <v-row class="mx-0 px-0">
      <v-col
        cols="12"
        md="3"
        xl="3"
        class="px-0 mx-0"
        style="padding-top:0 !important;padding-bottom:0 !important;display:flex;"
        >
          <date-range-picker
            ref="picker"
            :showWeekNumbers="showWeekNumbers"
            :showDropdowns="showDropdowns"
            :autoApply="autoApply"
            opens="right"
            :always-show-calendars="alwaysshowcalendars"
            v-model="dateRange"
            @select="updateValues"
           >
              <template v-slot:input="picker" style="min-width: 350px;">
                  <v-text-field
                    label="Select date range"
                    :rules="fieldRules"
                    v-model="date"
                    hide-details
                    solo
                  ></v-text-field>
              </template>
          </date-range-picker>
        </v-col>

        <v-col
          cols="12"
          md="3"
          xl="3"
          class="custom_1"
          style="padding-top:0 !important;padding-bottom:0 !important;display:flex;"
          >
          <v-autocomplete
              :items="organisations"
              label="Select organisation*"
              v-model="item.organisation_id"
              clearable
              @change="selectOrg"
              @click:clear="item.organisation_id = ''"
              item-text="name"
              item-value="id"
              :search-input.sync="searchorganisation"
              hide-details
              solo
            ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
          xl="2"
          class="custom_1btn"
          style="padding-top:0 !important;padding-bottom:0 !important;display:flex;"
          >
          <v-btn
            color="primary darken-1"
            :loading="loading"
            :disabled="!valid"
            @click="fetchreport"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
    </v-row>
  </v-col>
</template>
<style>
  .modal-search{
    max-width:450px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { format, parseISO } from 'date-fns'

export default {
	name: 'statementdialog',
	components: { DateRangePicker },
	data: () => ({
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    item: {
      organisation_id: "",
      project_id: ""
   },
   fieldRules: [
          (v) => !!v || 'Field is required'
          ],
    searchorganisation: null,
    dateRange: {
      startDate: null,
      endDate: null
     },
    singleDatePicker: true,
    showWeekNumbers: true,
    showDropdowns: true,
    autoApply: true,
    alwaysshowcalendars: true,
    date: "",
    dates: {},
    daterange: {
      start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    },

	}),
	methods: {

    selectOrg(id) {
      let index = this.organisations.filter(item => item.id === id )
      if(index.length > 0)
        this.$store.state.statementorganisation = index[0]
      else
        this.$store.state.statementorganisation = null
    },

    updateValues(val) { 
      this.daterange.start = Vue.filter('date')(val.startDate)
      this.daterange.end = Vue.filter('date')(val.endDate)
      this.date = this.daterange.start+" - "+this.daterange.end
    },
    fetchreport(){
      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('organisation_id',this.item.organisation_id)
      fd.append('project_id',this.item.project_id)
      fd.append('start',this.daterange.start)
      fd.append('end',this.daterange.end)

      axios.post('/organisations/statement', fd, config).then(response=>{
        this.loading = false
        if(response.data.status == parseInt(200)) {
          this.$store.state.statements = response.data.statements
          // this.$store.state.statementorganisation = this.item.organisation_id
          this.$store.state.statementdata = {
              organisation_id: this.item.organisation_id,
              project_id: null,
              start: this.daterange.start,
              end: this.daterange.end
            }
          this.show = false
        }
        
      })
      .catch(() => {
        this.loading = false
        console.log("Unexpected error occured")
      })
    },
	},
	props: {
     value: Boolean
  },
  computed: {
    organisations() {
      return this.$store.state.minorganisations
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    },
  },
	mounted() {},
	created() {
     
    }
}
</script>