<template>
    <!-- BEGIN: Content-->
    <div class="usersList mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>

        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-text-field
              v-model="searchfilter"
              label="Search"
              solo
              hide-details="auto"
              class="search_"
            ></v-text-field>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            @click.stop="dialog = true"
          >
            User
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="usersList">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">
                <v-card-text class="px-0 py-3 cardtext">
                  <div class="empty-state" v-if="allusers.length === 0 && !loader">
                      <div class="empty-state__content">
                        <div class="empty-state__icon">
                          <v-img src="@/assets/icon.png" alt=""></v-img>
                        </div>
                        <div class="empty-state__message">No records available.</div>
                      </div>
                    </div>
                  <v-virtual-scroll
                      :items="allusers"
                      :item-height="20"
                      renderless 
                      id="virtualscroll"
                      class="users"
                     >
                      <template v-slot:default="{ item }">
                          <div class="tcell"> {{ item.name }}</div>
                          <div class="tcell">{{ item.telephone }}</div>
                          <!-- <div class="tcell">{{ item.idno }}</div> -->
                          <div class="tcell">{{ item.email }}</div>
                          <div class="tcell">
                            <span
                              v-if="item.organisation"
                            >
                              {{ item.organisation.name }}
                            </span>
                          </div>
                          <div class="tcell">
                             <span
                                v-for="(role, index) in item.roles" :key="index"
                              >
                                {{ role.name }},
                              </span>
                          </div>
                          <div class="tcell">
                            <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    small
                                    v-on="on">
                                  <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                </v-btn>
                              </template>
                              
                              <v-list-item-group
                                color="primary"
                                class="actions"
                                >
                                  <v-list-item
                                    @click="editOrg(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-pencil</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                <v-list-item
                                  link
                                  @click="deleteItem(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon>mdi-delete</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Remove</v-list-item-title>
                                      </v-list-item-content>
                                </v-list-item>

                              </v-list-item-group>
                            </v-menu>
                          </div>
                      </template>
                    </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- add user dialog -->
        <v-dialog
          v-model="dialog"
          max-width="480px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6">Add User</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="Full Names*"
                        v-model="item.name"
                        required
                        :rules="fieldRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="Telephone*"
                        required
                        v-model="item.telephone"
                        :rules="fieldRules"
                      ></v-text-field>
                    </v-col>
                  <!--   <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="ID NO*"
                        v-model="item.idno"
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Email Address*"
                        required
                        v-model="item.email"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Username"
                        v-model="item.username"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-autocomplete
                        :items="organisations"
                        label="Select organisation"
                        v-model="item.organisation_id"
                        item-text="name"
                        item-value="id"
                        :search-input.sync="search"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-autocomplete
                        :items="roles"
                        label="Select roles"
                        v-model="item.role_id"
                        item-text="name"
                        item-value="id"
                        :rules="fieldRules"
                        multiple
                        :search-input.sync="searchrole"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="create"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- end user -->
        <!-- user update modal-->
        <v-dialog
          v-model="updatedialog"
          max-width="480px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6">Update User</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="Full Names*"
                        v-model="item.updatename"
                        required
                        :rules="fieldRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="Telephone*"
                        required
                        v-model="item.updatetelephone"
                        :rules="fieldRules"
                      ></v-text-field>
                    </v-col>
                <!--     <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="ID NO*"
                        v-model="item.updateidno"
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Email Address*"
                        required
                        v-model="item.updateemail"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Username"
                        v-model="item.updateusername"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-autocomplete
                        :items="organisations"
                        label="Select organisation"
                        v-model="item.updateorganisation_id"
                        :value="item.updateorganisation_id"
                        item-text="name"
                        item-value="id"
                        :search-input.sync="search"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-autocomplete
                        :items="roles"
                        label="Select roles"
                        v-model="item.updaterole_id"
                        :value="item.updaterole_id"
                        item-text="name"
                        item-value="id"
                        :rules="fieldRules"
                        multiple
                        :search-input.sync="searchrole"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="updatedialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="update"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- update user -->
      </div>
    </div>

</template>
<style>
  .users .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     /*grid-auto-columns: minmax(14.28%, 14.28%) !important;*/
     grid-template-columns: 18.36% 12.28% 21.28% 20.56% 13.24% 14.28%;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

export default {
    name:"usersList",
    components: {},
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          searchfilter: '',
          search:null,
          valid: true,
          searchrole:null,
          dialog: false,
          tableloader: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'User Config',
              disabled: false,
              href: '',
            },
            {
              text: 'Users',
              disabled: false,
              href: '',
            },
          ],
          item:{
                name:"",
                telephone:"",
                idno:"",
                email:"",
                username:"",
                organisation_id:"",
                role_id: [],

                updatename:"",
                updatetelephone:"",
                updateidno:"",
                updateemail:"",
                updateusername:"",
                updateorganisation_id:"",
                updaterole_id: [],

                updateID: "",
                _method:"patch"
            },
            isBusy: false,
            loading: false,
            tableloading:true,
           disabled: false,
           Organisation: [],
           Drivers: [],
           fields: [
            { text: 'Name', value: 'name' },
            { text: 'Telephone', value: 'telephone' },
            // { text: 'ID NO', value: 'idno' },
            { text: 'Email', value: 'email' },
            { text: 'Organisation', value: 'organisation' },
            { text: 'Roles', value: 'roles' },
            { text: 'Action', value: 'action' },
           ],
            updateModal: null,
            height: '100%',
            rowHeight: 25,
            perPage: 50,
            start: 0,
            timeout: null  
        }
    },
    mounted(){

      this.$store.state.loader_text = "User Accounts"

      if(!this.allusers || this.allusers.length == 0) {
        this.getUsers()
      }

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    serverPrefetch () {
      return this.getUsers()
    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      users() {
        this.start = 0
        return this.$store.state.users.filter(item => {
          this.start = 0
          let name = ""
          if(item.name != null) {name = item.name}
          return item.name.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
      },

      allusers() {
        return this.users
      },
      roles() {
        return this.$store.state.roles
      },
      organisations() {
        return this.$store.state.minorganisations
      }
    },
    beforeMount() {
      // if(!ability.can('user-management', 'all')) {
      //   this.$router.push({ name: 'Dashboard' })
      //   return
      // }
    },
    methods:{
      getUsers(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getUsers')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
      create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('name',this.item.name)
            fd.append('telephone',this.item.telephone)
            fd.append('email',this.item.email)
            fd.append('idno',this.item.idno)
            fd.append('roles',JSON.stringify(this.item.role_id))
            fd.append('username',this.item.username)
            fd.append('organisation_id',this.item.organisation_id)

            axios.post('/users', fd, config).then(response=>{
                this.$toast.success('User added successfully','Success',{position: 'topRight',timeout: 7000,})
                this.loading = false
                this.disabled = false
                this.dialog = false
                this.item.name = ''
                this.item.telephone = ''
                this.item.email = ''
                this.item.role_id = ''
                this.item.idno = ''
                this.item.username = ''
                this.item.organisation_id = ''
                this.clear()
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
                this.dialog = false
              })

          }

        },
        clear () {
          this.$refs.form.reset()
        },
        update(){

          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true

              const config = { headers: { 'Content-Type': 'multipart/form-data' } };
              let fd = new FormData();
              fd.append('name',this.item.updatename)
              fd.append('telephone',this.item.updatetelephone)
              fd.append('email',this.item.updateemail)
              fd.append('idno',this.item.updateidno)
              fd.append('roles',JSON.stringify(this.item.updaterole_id))
              fd.append('username',this.item.updateusername)
              fd.append('organisation_id',this.item.updateorganisation_id)
              fd.append('_method',this.item._method)

              axios.post(`/users/${this.item.updateID}`, fd, config).then(response=>{
                  this.$toast.success('User updated successfully','Success',{position: 'topRight',timeout: 7000,})
                  this.loading = false
                  this.disabled = false
                  this.updatedialog = false
                  this.item.updatename = ''
                  this.item.updatetelephone = ''
                  this.item.updateemail = ''
                  this.item.updateidno = ''
                  this.item.updateusername = ''
                  this.item.updaterole_id = ''
                  this.item.updateorganisation_id = ''
                  this.item.updateID = ''
                })
                .catch(() => {
                  this.loading = false
                  this.disabled = false
                  this.dialog = false
                })

            }

        },
      editOrg(id) {
        let index = this.allusers.filter(item => item.id === id );

         axios.get(`/users/${index[0].id}`).then(response=>{
          this.item.updaterole_id = response.data.userRole

          this.item.updatename = index[0].name
          this.item.updatetelephone = index[0].telephone
          this.item.updateemail = index[0].email
          this.item.updateidno = index[0].idno
          this.item.updateusername = index[0].username
          if(index[0].organisation !== null)
           this.item.updateorganisation_id = parseInt(index[0].organisation.id)
          this.item.updateID = index[0].id
          this.updatedialog = true
          
        }).catch(error=>{
          this.loading = false
          this.disabled = false
            console.log(error)
        })

      },
      deleteItem(id){
            if(confirm("Are you sure to delete user ?")){

              axios.delete(`/users/${id}`).then(response=>{
                this.$toast.success('User deleted successfully','Success',{position: 'topRight',timeout: 7000,})
              })
              .catch(() => {
                console.log("Unexpected error occured")
              })
              
            }
        }
    }
}
</script>