<template>
    <!-- BEGIN: Content-->
    <div class="mt-3">
      <loader/>
      <div class="newQuotation">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
            <v-row style="margin-top:0 !important;margin-bottom:0 !important">
              <v-col cols="12" sm="12" xl="9" md="12" lg="10" class="my-1 py-0">
                <v-card class="card-shadow py-2 px-4 invoice" style="margin-bottom:3rem !important">
                  <v-form class="pt-0" enctype="multipart/form-data" v-model="valid" ref="form">
                    <v-row>
                      <v-col cols="6"  style="display:flex;justify-content:flex-start;align-items:center;padding-bottom:0 !important;margin-bottom:0 !important">
                        <v-switch
                          v-model="includevat" 
                          label="Include VAT ?"
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row class="mt-3">
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                          <v-text-field
                            label="Quotation #"
                            v-model="requests.quotation_no"
                            required
                            :rules="fieldRules"
                            hide-details
                          ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                          <v-autocomplete
                            :items="organisations"
                            label="Select organisation*"
                            v-model="requests.organisation_id"
                            :value="requests.organisation_id"
                            item-text="name"
                            item-value="id"
                            :rules="fieldRules"
                            clearable
                            :search-input.sync="searchorg"
                            hide-details
                          >
                          </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                        <v-autocomplete
                          :items="projects"
                          label="Select project"
                          v-model="requests.project_id"
                          :value="requests.project_id"
                          item-text="name"
                          item-value="id"
                          clearable
                          :search-input.sync="searchproject"
                          hide-details
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                        <v-autocomplete
                          :items="selectedcustomers"
                          label="Passenger Name*"
                          v-model="requests.customer_id"
                          item-text="name"
                          item-value="id"
                          clearable
                          :search-input.sync="customer"
                          hide-details
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Quotation date*"
                              :rules="fieldRules"
                              :value="timepickerdate"
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                              @click:clear="date = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            no-title
                            scrollable
                            @input="menu = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        class="mb-3"
                        >
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Due date*"
                              :rules="fieldRules"
                              :value="timepickerduedate"
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="duedate = null"
                              hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="duedate"
                            no-title
                            scrollable
                            @input="menu2 = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="menu2 = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="$refs.menu2.save(duedate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="4"
                          class="mb-3"
                        >
                          <v-text-field
                            label="Subject"
                            v-model="requests.subject"
                            hide-details
                          ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-row style="margin-top:2rem !important;" class="pb-5">
                    <v-col cols="12">
                          <span style="font-size:.95rem;font-weight:500">Items Section</span>
                        </v-col>
                    <v-col cols="12" style="padding:0 !important;margin:0 !important;">
                      <v-row class="mb-4" style="padding:0 !important;margin:0 !important;" v-for="(item, indx) in items" :key="indx">
                        <v-col sm="12" md="5">
                            <v-text-field
                              label="Item Details"
                              v-model="item.details" 
                              :name="`items[${indx}][details]`"
                              required
                              hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col sm="12" md="2">
                          <v-text-field
                            label="Qnty"
                            v-model="item.quantity" 
                            :name="`items[${indx}][quantity]`"
                            required
                            size="sm"
                            @input="onQuantityInput($event,indx)"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col sm="12" md="2">
                            <v-text-field
                              label="Rate"
                              v-model="item.rate" 
                              :name="`items[${indx}][rate]`"
                              required
                              min="1"
                              @input="onRateInput($event,indx)"
                              hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col sm="12" md="2">
                            <v-text-field
                              label="Amount"
                              v-model="item.amount" 
                              :name="`items[${indx}][amount]`"
                              required
                              readonly
                              hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col sm="12" md="1" class="addremoveicons mt-1">
                          <v-icon class="mx-2" @click="addItem">mdi-plus</v-icon>
                          <v-icon @click="removeItem(indx)">mdi-close</v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-bottom-navigation
                  fixed
                  horizontal
                  class="pt-3"
                >
                <div>
                  <v-btn
                      color="#F98525"
                      class="mx-2"
                      style="height:35px !important;border-radius:3px;"
                      :loading="loading"
                      :disabled="!valid"
                      @click="savedraft"
                    >
                    <span style="font-size:.82rem !important;color:#111 !important">Save as Draft</span>
                  </v-btn>

                  <!-- <v-btn
                    color="#F98525"
                    class="mx-2"
                    style="height:35px !important;border-radius:3px;"
                    :loading="loading1"
                    :disabled="!valid"
                    @click="save"
                  >
                    <span style="font-size:.82rem !important;color:#111 !important">Save and Download</span>
                  </v-btn> -->

                  <v-btn
                      color="#dedede"
                      class="mx-2"
                      depressed
                      link
                      :to="{name:'invoiceList'}"
                      style="height:35px !important;border-radius:3px;"
                    >
                    <span style="font-size:.82rem !important;color:#111 !important">Cancel</span>
                  </v-btn>
                </div>
                <div>
                  <div class="summaryTotals">
                    <div class="totals">
                      <span class="font-normal"> SubTotal</span>
                      <span class="font-medium">
                        <p v-if="calcitems.length > 0">
                            {{

                              (calcitems.reduce( (acc, item) => 
                              {  

                                return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                              }, 0)) | currency('')

                            }}
                          </p>
                          <p v-else>0.00</p>
                      </span>
                    </div>
                    <div class="mx-5 arrow-right"></div>
                    <div class="totals">
                      <span class="font-normal">Vat({{ vat != null ? vat.rate : 0 }}%)</span>
                      <span class="font-medium">
                        <p v-if="calcitems.length > 0">
                          {{

                            ((((calcitems.reduce( (acc, item) => 
                              {  

                                return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                              }, 0))*(vat != null && includevat == true ? vat.rate : 0))/100)) | currency('')

                          }}
                        </p>
                        <p v-else>
                          0.0
                        </p>
                      </span>
                    </div>
                    <div class="mx-5 arrow-right"></div>
                    <div class="totals">
                      <span class="font-normal">Total</span>
                      <span class="font-medium">
                        <p v-if="calcitems.length > 0">
                          {{

                            (calcitems.reduce( (acc, item) => 
                              {  

                                return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                              }, 0) + ((((calcitems.reduce( (acc, item) => 
                              {  

                                return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                              }, 0))*(vat != null && includevat == true ? vat.rate : 0))/100)) ) | currency('')

                          }}
                        </p>
                        <p v-else>
                          0.00
                        </p>
                      </span>
                    </div>
                  </div>
                </div>

                </v-bottom-navigation>
              </v-col>
            </v-row>
        </v-container>

      </div>
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'

import {ability} from '@/services/ability'
import DateRangePicker from 'vue2-daterange-picker'

import loader from '@/components/loader'

 export default {
  name: 'newInvoice',
  components: { loader },
  data() {
    return {
      fieldRules: [
          (v) => !!v || 'Field is required'
          ],
      profileid: null,
      selected: [],
      selectAll: false,
      daterange: {
          start: '',
          end: ''
        },
      dateRange: {
        startDate: null,
        endDate: null
       },
       readonly:true,
      singleDatePicker: true,
      showWeekNumbers: true,
      showDropdowns: true,
      autoApply: true,
      alwaysshowcalendars: true,
      rangedate: "",
      searchrequestcategory: "",
      date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      fetchloading: false,
      menu: false,
      duedate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      menu2: false,
      searchfilter:'',
      individualchecked: false,
      custominvoice: false,
      includevat: false,
      searchorg:null,
      search:"",
      customer: null,
      searchproject: null,
      valid: true,
      dialog: false,
      updatedialog: false,
      items: [
          {
            details: "",
            quantity: "1",
            rate: "0",
            amount: "0"
          }
        ],
      requests:{
         organisation_id: "",
         project_id: "",
         customer_id: "",
         quotation_no: "",
         subject: "",
         amount: "",
      },
      isBusy: false,
      loading: false,
      loading1: false,
      loading2: false,
       disabled: false,
       organisations: [],
       customers: [],
       expresswaydialog: false,
       editdistancedialog: false,
       bookingid: null,
       expresswaydetails: [],
       waitingdialog: false,
       waitingdetails: [],
       profiledetails: [],
       profiledialog: false,
       updatedetails: [],
       updatedialog: false,
       updateall: [],
       updatealldialog: false,
    }
  },

  mounted(){

    this.$store.state.loader_text = 'New Quotation'

      this.$store.state.invoicebookings = []
      this.getData()

  },
  computed: {
    loader() {
      return this.$store.state.tableloader
    },
    vat() {
      return this.$store.state.vat
    },
    timepickerdate () {
      return this.date ? moment(this.date).format('Do MMM YYYY') : ''
    },
    timepickerduedate () {
      return this.duedate ? moment(this.duedate).format('Do MMM YYYY') : ''
    },
    projects() {
      if(this.requests.organisation_id !== null){
        let projects = []
        let data = this.organisations.filter(organisation =>  parseInt(this.requests.organisation_id) == parseInt(organisation.id) )
        for (let i in data) {
          if(data[i].projects.length > 0) {
            projects.push(...data[i].projects);
          }
        }

        return projects
      }else{
        return []
      }       
    },
    selectedcustomers() {
      if(this.requests.organisation_id !== null){

        let data = this.customers.filter(customer =>  parseInt(this.requests.organisation_id) == parseInt(customer.organisation_id) )

        return data
      }else{
        return []
      }       
    },
    calcitems() {
      return this.items
    },
  },
  methods: {

    onQuantityInput(value,index) {
      let found = this.items[index];
      if (found) {
        found.amount = parseFloat(found.quantity)*parseFloat(found.rate)
      }
    },
    onRateInput(value,index) {
      let found = this.items[index];
      if (found) {
        found.amount = parseFloat(found.quantity)*parseFloat(found.rate)
      }
    },

    addItem () {
      this.items.push({
        details: "",
        quantity: "1",
        rate: "0",
        amount: "0"
      })
    },
    removeItem (index) {
      this.items.splice(index, 1);
    },
    updateValues(val) { 
      this.daterange.start = Vue.filter('date')(val.startDate)
      this.daterange.end = Vue.filter('date')(val.endDate)
      this.rangedate = this.daterange.start+" - "+this.daterange.end
    },
    async savedraft(){

      if (this.$refs.form.validate()) {

        this.loading = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('organisation_id',this.requests.organisation_id)
        fd.append('project_id',this.requests.project_id)
        fd.append('customer_id',this.requests.customer_id)
        fd.append('quotation_no',this.requests.quotation_no)
        fd.append('items',JSON.stringify(this.items))
        fd.append('subject',this.requests.subject)
        fd.append('date',this.date)
        fd.append('duedate',this.duedate)
        fd.append('includevat',this.includevat)

        axios.post('/quotations/draft', fd, config).then(response=>{
          if(response.data.status == parseInt(200)) {

            let found = this.$store.state.quotations.find(data => data.id == response.data.quotation.id);
            if (!found) {
              this.$store.state.quotations.unshift(response.data.quotation)
            }

            this.$toast.success('Quotation saved successfully','Success',{position: 'topRight',timeout: 7000,})

          }
          this.requests.project_id = []
            this.requests.customer_id = []
            this.requests.organisation_id = []

            this.$router.push({ name: 'quotationList' })

            this.loading = false
          })
          .catch(error=> {
            this.loading = false
          })
      }
    },

    async save(){

      if (this.$refs.form.validate()) {

        this.loading2 = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob'};
        let fd = new FormData();
        fd.append('organisation_id',this.requests.organisation_id)
        fd.append('project_id',this.requests.project_id)
        fd.append('customer_id',this.requests.customer_id)
        fd.append('quotation_no',this.requests.quotation_no)
        fd.append('items',JSON.stringify(this.items))
        fd.append('subject',this.requests.subject)
        fd.append('date',this.date)
        fd.append('duedate',this.duedate)
        fd.append('includevat',this.includevat)

        axios.post('/quotations', fd, config).then(response=>{
         this.loading2 = false

        this.requests.project_id = null
        this.requests.customer_id = null
        this.requests.organisation_id = null

        this.getData()

        this.$toast.success('Quotation saved successfully','Success',{position: 'topRight',timeout: 7000,})

         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', 'Quotation.pdf');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch((error) => {
          this.loading2 = false
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser 
            // and an instance of http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        })
      }
    },

    async getData(){
      this.$store.state.tableloader = true
      await axios.get('/new/quotation').then(response=>{
          this.$store.state.tableloader = false
          if(response.data.status == parseInt(200)) {
            this.$store.state.vat = response.data.vat

            this.organisations = response.data.organisations
            this.customers = response.data.customers
            this.requests.quotation_no = response.data.quotation_no

            this.date = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
            this.duedate = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
          }
      }).catch(error=>{
          this.$store.state.tableloader = false
          console.log(error)
      })
    }

  }
};

</script>
