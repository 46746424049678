<template>
	<div>
	  <!-- client profile modal -->
      <v-dialog
        v-model="show"
        max-width="420px"
        >
        <v-card>
          <div style="width:100%;height:.3rem !important">
                    <v-progress-linear
                      color="#F9780D"
                      buffer-value="0"
                      stream
                      height="4"
                      :active="tableloader"
                    ></v-progress-linear>
                  </div>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add Expressway</span>
          </v-card-title>

          <v-card-text v-if="expresswaydetails.length > 0">
            <v-row>
              <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="expresswaydetails[0].expressway.length > 0"
              >
                <v-alert
                  text
                  color="primary py-1"
                >
                  <v-row
                    align="center"
                    no-gutters
                    v-for="(express,index) in expresswaydetails[0].expressway"
                    :key="index"
                    class="my-0 py-0"
                    style="display:flex;align-items:center;justify-content:space-between"
                    >
                    <div class="d-flex" style="font-size:.75rem">{{ express.rate.entrance.name }} | {{ express.rate.exit.name }}</div>
                    <v-col class="shrink">
                      <v-btn
                        color="red"
                        outlined
                        :loading="loading1"
                        @click="removeexpress(express.id)"
                      >
                        Remove
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
            <v-row
                v-for="(expressway, indx) in expressways" 
                  :key="indx"
                  style="display:flex;align-items:center;justify-content:space-between"
                  class="mx-0 my-2"
                >
                    <v-col
                      cols="12"
                      sm="12"
                      md="5"
                      >
                      <v-autocomplete
                        :items="entrances"
                        label="Select entrance*"
                        v-model="expressway.entrance_id"
                        item-text="name"
                        item-value="id"
                        :rules="fieldRules"
                        :name="`expressways[${indx}][entrance_id]`"
                        @change="(event) => updateEntrance(event,indx)"
                        hide-details
                        solo
                      >
                        <template slot="selection" slot-scope="{ item, selected }">
                            {{ item.name }}
                        </template>
                        <template slot="item" slot-scope="{ item, tile }">
                            {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="5"
                      >
                      <v-autocomplete
                        :items="exits"
                        label="Select exit*"
                        v-model="expressway.exit_id"
                        item-text="name"
                        item-value="id"
                        :rules="fieldRules"
                        :name="`expressways[${indx}][exit_id]`"
                        @change="(event) => updateExit(event,indx)"
                        hide-details
                        solo
                      >
                        <template slot="selection" slot-scope="{ item, selected }">
                            {{ item.name }}
                        </template>
                        <template slot="item" slot-scope="{ item, tile }">
                            {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="2"
                    >
                    <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                      <v-icon @click="addExpressway" class="mx-3">mdi-plus</v-icon>
                      <v-icon @click="removeExpressway(indx)">mdi-close</v-icon>
                    </div>
                </v-col>
              </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="show = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="submit"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end client profile -->
	</div>
</template>

<style scoped>
  .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th, .v-data-table-header > th {
    font-weight: 500;
    height: 25px !important;
}
.clientprofile td{
  white-space: normal !important; 
}
</style>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'expressway',
	components: {
	},
	data: () => ({
    tableloader: false,
    loading1: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        entrance_id:"",
        exit_id:"",
        searchentrance: "",
        searchexit: "",
        expressways: [
          {
            entrance_id: "",
            exit_id: "",
          }
        ],
	}),
	methods: {
    addExpressway () {
        this.expressways.push({
          entrance_id: "",
          exit_id: "",
        })
      },
      removeExpressway (index) {
        this.expressways.splice(index, 1);
      },
      updateEntrance(value,indx) {
        if(value !== null) {
          let found = this.expressways[indx];
          if (found) {
            found.entrance_id = value
          }
        }
      },
      updateExit(value,indx) {
        if(value !== null) {
          let found = this.expressways[indx];
          if (found) {
            found.exit_id = value
          }
        }
      },
		async getExpressway(){
        this.$store
          .dispatch('getExpressway')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },
      submit(){
          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('expressways',JSON.stringify(this.expressways))
            fd.append('id',this.expresswaydetails[0].id)

            axios.post('/booking/saveexpressway', fd, config).then(response=>{

              if(response.data.status === parseInt(201)) {
                this.$toast.success("Expressway added successfully",'Success',{position: 'topRight',timeout: 7000,})
              }

              this.expressways = []
              this.expressways.push({
                entrance_id: "",
                exit_id: "",
              })

              this.show = false
              this.loading = false
              this.disabled = false
              
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

        }
      },
      removeexpress(id){
        this.tableloader = true
        this.loading1 = true
        axios.get(`/remove-expressway/${this.expresswaydetails[0].id}/${id}`).then(response=>{
          this.tableloader = false
          this.loading1 = false
          if(response.data.status == parseInt(200)) {
            this.show = false
            this.$toast.success('Expressway removed successfully','Success',{position: 'topRight',timeout: 7000,})
          }
        }).catch(error=>{
          this.tableloader = false
          this.loading1 = false
          this.show = false
          console.log(error)
        })
      }
	},
	props: {
     value: Boolean,
     expresswaydetails: Array
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    },
    entrances() {
      return this.$store.state.entrances
    },
    exits() {
      return this.$store.state.exits
    },
  },
  watch: {
      show: function (newVal,oldVal) {
        if(newVal == true) {
          this.getExpressway()
        }
      }
  },
	mounted() {
    
  },
	created() {}
}
</script>