<template>
    <!-- BEGIN: Content-->
    <section>
       <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper container-xxl p-0">
        <div class="content-header row">
          <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
              <div class="col-12">
                <h3 class="content-header-title float-start mb-0">Profile</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link :to="{ name: 'Profile' }">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item active"> User Profile
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-body"><div class="row">
          <div class="col-12 col-lg-8 col-sm-12">
            <!-- profile -->
            <div class="card">
              <div class="card-header border-bottom">
                <h4 class="card-title">Profile Details</h4>
              </div>
              <div class="card-body py-2 my-25">
                <!-- header section -->
                <div class="d-flex">
                  <a href="javascript:void(0)" class="me-25">
                    <img
                      src="/app-assets/images/no_user.png"
                      id="account-upload-img"
                      class="uploadedAvatar rounded me-50"
                      alt="profile image"
                      height="100"
                      width="100"
                    />
                  </a>
                  <!-- upload and reset button -->
                  <div class="d-flex align-items-end mt-75 ms-1">
                    <div>
                      <label for="account-upload" class="btn btn-sm btn-primary mb-75 me-75">Upload</label>
                      <input type="file" id="account-upload" hidden accept="image/*" />
                      <button type="button" id="account-reset" class="btn btn-sm btn-outline-secondary mb-75">Reset</button>
                      <p class="mb-0">Allowed file types: png, jpg, jpeg.</p>
                    </div>
                  </div>
                  <!--/ upload and reset button -->
                </div>
                <!--/ header section -->

                <!-- form -->
                <form class="validate-form mt-2 pt-50" @submit.prevent="update" enctype="multipart/form-data" method="post">
                  <div class="row">
                    <div class="col-12 col-sm-6 mb-1">
                      <label class="form-label" for="accountName">Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="accountName"
                        placeholder="e.g John"
                        v-model="item.name"
                      />
                    </div>
                    <div class="col-12 col-sm-6 mb-1">
                      <label class="form-label" for="accountIdno">ID NO</label>
                      <input
                        type="text"
                        class="form-control"
                        id="accountIdno"
                        placeholder="e.g 3454666"
                        v-model="item.idno"
                      />
                    </div>
                    <div class="col-12 col-sm-6 mb-1">
                      <label class="form-label" for="accountPhoneNumber">Phone Number</label>
                      <input
                        type="text"
                        class="form-control account-number-mask"
                        id="accountPhoneNumber"
                        placeholder="e.g 017xxxxxxx"
                        v-model="item.telephone"
                      />
                    </div>
                    <div class="col-12 col-sm-6 mb-1">
                      <label class="form-label" for="accountEmail">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="accountEmail"
                        placeholder="e.g johndoe@gmail.com"
                        v-model="item.email"
                      />
                    </div>
                    
                    <div class="col-12 col-sm-6 mb-1">
                      <label class="form-label" for="accountPassword">Password</label>
                      <input type="password" class="form-control" id="accountPassword" v-model="item.password" placeholder="***********" />
                    </div>
                    <div class="col-12 col-sm-6 mb-1">
                      <label class="form-label" for="accountCPassword">Confirm Password</label>
                      <input type="password" class="form-control" id="accountCPassword" v-model="item.confirm_password" placeholder="***********" />
                    </div>
                    <div class="col-12">
                      <button type="submit" class="btn btn-primary mt-1 me-1">Update changes <div v-show="loading" class="spinner-border spinner-border-sm ml-4" role="status">
                                    <span class="sr-only"></span>
                                </div></button>
                    </div>
                  </div>
                </form>
                <!--/ form -->
              </div>
            </div>
            <!--/ profile -->
          </div>
        </div>

        </div>
      </div>
    </section>
    <!-- END: Content-->
</template>
<style scope>
    @media (min-width: 576px){
        .modal-slide-in .modal-dialog {
            width: 48rem;
        }
    }
    .vs__selected, .vs__search,.vs__search:focus{
        color: #B4B7BD !important;
    }
    .vs__open-indicator,.vs__clear{
        fill:  #B4B7BD !important;
    }
</style>
<script>
import axios from 'axios'

export default {
    name:"Profile",
    data(){
        return {
          item:{
                name:"",
                telephone:"",
                idno:"",
                email:"",
                password:"",
                confirm_password:"",
                updateID: "",
                _method:"patch"
            },
            isBusy: false,
            loading: false,
           disabled: false,
         }
    },
    mounted(){

      this.item.name = this.$store.state.user.user.name
      this.item.telephone = this.$store.state.user.user.telephone
      this.item.idno = this.$store.state.user.user.idno
      this.item.email = this.$store.state.user.user.email
      this.item.email = this.$store.state.user.user.email
      this.item.updateID = this.$store.state.user.user.id

    },

    computed: {
      userdetails() {
        return this.$store.state.drivers
      }
    },
    methods:{
   
       async update(){
          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('name',this.item.name)
            fd.append('telephone',this.item.telephone)
            fd.append('email',this.item.email)
            fd.append('idno',this.item.idno)
            fd.append('password',this.item.password)
            fd.append('confirm_password',this.item.confirm_password)
            // fd.append('_method',this.item._method)

            await axios.post(`/user-profile/update/${this.item.updateID}`, fd, config).then(response=>{
              this.loading = false
                this.disabled = false
              if(response.data.status === 402) {

                this.$toastr.error("Sorry, passwords does not match", 'Error', {timeOut: 5000})
              }
              if(response.data.status === 200) {
                this.$toastr.success("User updated successfully", 'Success', {timeOut: 5000})
              }

              })
              .catch(() => {
                this.loading = false
                this.disabled = false
              })

        }
    }
}
</script>