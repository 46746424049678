<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <loader/>
      <div class="newPaymentReversal">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-form class="pt-4"  enctype="multipart/form-data" v-model="valid" ref="form">
            <v-row style="margin-top:0 !important;margin-bottom:0 !important">
              <v-col  cols="12" md="8" lg="7" sm="12" class="my-1 py-0">
                <v-card class="card-shadow py-3 px-4">
                  <v-card-text>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-autocomplete
                            :items="organisations"
                            label="Select Organisation*"
                            v-model="item.organisation_id"
                            :value="item.organisation_id"
                            item-text="name"
                            item-value="id"
                            :rules="fieldRules"
                            :search-input.sync="searchfilter"
                            @change="selectOrg"
                            @click:clear="item.organisation_id = ''"
                          ></v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-autocomplete
                            :items="payments"
                            label="Select Payments*"
                            v-model="item.payment_id"
                            :value="item.payment_id"
                            item-text="payment_no"
                            item-value="id"
                            :rules="fieldRules"
                            :search-input.sync="searchpayment"
                            @change="selectPayment"
                          ></v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            label="Reversal Amount*"
                            v-model="item.amount"
                            :rules="fieldRules"
                          ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        >
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Reversal date*"
                              :rules="fieldRules"
                              :value="timepickerdate"
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="date = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            no-title
                            scrollable
                            @input="menu = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                          <v-text-field
                            label="Notes"
                            v-model="item.notes"
                          ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-bottom-navigation
                  fixed
                  horizontal
                >
                  <div>
                    <v-btn
                      color="#F98525"
                      class="mx-2"
                      style="height:35px !important;border-radius:3px;"
                      :loading="loading1"
                      :disabled="!valid"
                      @click="save"
                    >
                      <span style="font-size:.82rem !important;color:#111 !important">Save</span>
                    </v-btn>

                    <v-btn
                        color="#dedede"
                        class="mx-2"
                        depressed
                        link
                        :to="{name:'paymentList'}"
                        style="height:35px !important;border-radius:3px;"
                      >
                      <span style="font-size:.82rem !important;color:#111 !important">Cancel</span>
                    </v-btn>
                  </div>
                </v-bottom-navigation>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </div>

</template>
<style scoped>
  .v-bottom-navigation{
    align-items: center !important;
  }
</style>
<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import {ability} from '@/services/ability'
import loader from '@/components/loader'

export default {
    name:"newPaymentReversal",
    components: { loader },
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu: false,
          duedate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu2: false,
          searchfilter:null,
          search:'',
          searchpayment:null,
          paymentmodesearch:null,
          valid: true,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          dialog: false,
          updatedialog: false,
          item:{
             organisation_id: "",
             payment_id: "",
             amount: "",
             notes: ""
          },
            isBusy: false,
            loading: false,
            loading1: false,
           disabled: false,
           organisations: [],
           payments: [],
           modes: [],
           height: '100%',
            rowHeight: 24,
            perPage: 32,
            start: 0,
            timeout: null,
            selectedItem: false
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = 'Add Reversal Payment'

      this.getData()
    },
    computed: {
      timepickerdate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
      timepickerduedate () {
        return this.duedate ? moment(this.duedate).format('Do MMM YYYY') : ''
      },
    },
    methods:{
      async save(){

        if (this.$refs.form.validate()) {

          this.loading1 = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('payment_id',this.item.payment_id)
          fd.append('amount',this.item.amount)
          fd.append('notes',this.item.notes)
          fd.append('date',this.date)

          axios.post('/reversals', fd, config).then(response=>{

            if(response.data.status == parseInt(200)) {

              let found = this.$store.state.paymentreversals.find(data => data.id == response.data.reversal.id);
              if (!found) {
                this.$store.state.paymentreversals.unshift(response.data.reversal)
              }

              this.$toast.success('Payment reversal submitted successfully','Success',{position: 'topRight',timeout: 7000,})

              this.$router.push({ name: 'paymentReversalsList' })
            }
              this.loading1 = false
              this.disabled = false
            })
            .catch(() => {
              this.loading1 = false
              this.disabled = false
            })

          }
      },

      async getData(){
        this.$store.state.tableloader = true
        await axios.get('/new/reversal').then(response=>{
          this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.organisations = response.data.organisations
            }
        }).catch(error=>{
          this.$store.state.tableloader = false
            console.log(error)
        })
      },

      async selectOrg(id) {
        this.$store.state.tableloader = true
        await axios.get(`/reversal/payment/${id}`).then(response=>{
          this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.payments = response.data.payments
            }
        }).catch(error=>{
          this.$store.state.tableloader = false
            console.log(error)
        })
      },

      async selectPayment(id) {
        let index = this.payments.filter(item => item.id === id )
        this.item.amount = index[0].paidamount
      },
  
      clear () {
        if(this.$refs.form != undefined) {
          this.$refs.form.reset()
        }
      
      }
    }
}
</script>