<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>

        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            @click.stop="dialog = true"
          >
            Vehicle ownership
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="vehicleOwnershipList">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">

                <v-card-text class="px-0 py-3 cardtext">
                        <div class="empty-state" v-if="vehicleOwnership.length === 0 && !loader">
                          <div class="empty-state__content">
                            <div class="empty-state__icon">
                              <v-img src="@/assets/icon.png" alt=""></v-img>
                            </div>
                            <div class="empty-state__message">No records available.</div>
                          </div>
                        </div>
                        <v-virtual-scroll
                          :items="vehicleOwnership"
                          :item-height="20"
                          renderless 
                          id="virtualscroll"
                          class="vehicleOwnership"
                         >
                          <template v-slot:default="{ index,item }">
                              <div class="tcell">{{ index + 1 }}</div>
                              <div class="tcell">{{ item.ownership }}</div>
                              <div class="tcell">
                                <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        small
                                        v-on="on">
                                      <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                    </v-btn>
                                  </template>
                                  
                                  <v-list-item-group
                                    color="primary"
                                    class="actions"
                                    >

                                      <v-list-item
                                        @click="editType(item.id)"
                                      >
                                          <v-list-item-icon>
                                            <v-icon color="#000000">mdi-pencil</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>Edit</v-list-item-title>
                                          </v-list-item-content>
                                      </v-list-item>

                                    <v-list-item
                                      link
                                      @click="deleteItem(item.id)"
                                      >
                                          <v-list-item-icon>
                                            <v-icon>mdi-delete</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>Remove</v-list-item-title>
                                          </v-list-item-content>
                                    </v-list-item>

                                  </v-list-item-group>
                                </v-menu>
                              </div>
                          </template>
                        </v-virtual-scroll>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- add user dialog -->
        <v-dialog
          v-model="dialog"
          max-width="400px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6">Add vehicle ownership</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        label="Ownership*"
                        v-model="item.ownership"
                        required
                        :rules="fieldRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="create"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- end user -->
        <!-- user update modal-->
        <v-dialog
          v-model="updatedialog"
          max-width="400px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6">Update vehicle ownership</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        label="Ownership*"
                        v-model="item.updateownership"
                        required
                        :rules="fieldRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="updatedialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="update"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- update user -->
      </div>
    </div>

</template>
<style>
  .vehicleOwnership .v-virtual-scroll__item{
    width:100%;
    display: grid !important;
     grid-template-columns: 33.333% 33.333% 33.333%;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

export default {
    name:"vehicleOwnershipList",
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          search:null,
          valid: true,
          searchrole:null,
          dialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Business Config',
              disabled: false,
              href: '',
            },
            {
              text: 'Vehicles',
              disabled: false,
              href: '',
            },
            {
              text: 'Vehicle Ownership',
              disabled: false,
              href: '',
            },
          ],
          fields: [
            {
              text: '#',
              align: 'start',
              value: '#',
            },
            { text: 'Ownership', value: 'ownership' },
            { text: 'Action', value: 'action' },
           ],
          item:{
                ownership:"",
                updateownership: "",
                updateID: "",
                _method:"patch"
            },
            loading: false,
           disabled: false,
           vehicleOwnership: [],
           isBusy: false,
            updateModal: null
        }
    },
    beforeMount() {
      if(!ability.can('vehicle-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = "Vehicle Ownership"

      this.getOwnership()

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
    },
    methods:{
      getRowClass(item) {
        return 'row-color' 
      },
      async getOwnership(){
        this.$store.state.tableloader = true
        await axios.get('/vehicle-ownership').then(response=>{
            this.$store.state.tableloader = false
            this.vehicleOwnership = response.data.vehicleOwnership
        }).catch(error=>{
            console.log(error)
            this.$store.state.tableloader = false
            this.vehicleOwnership = []
        })
      },
      clear () {
          this.$refs.form.reset()
        },
       async create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('ownership',this.item.ownership)

            await axios.post('/vehicle-ownership', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              this.res = response.status
              if(this.res === parseInt(200))
                this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
                this.getOwnership()
                this.clear()
              this.item.ownership = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
        async update(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('ownership',this.item.updateownership)
            fd.append('_method',this.item._method)

            await axios.post(`/vehicle-ownership/${this.item.updateID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              this.res = response.status
              if(this.res === parseInt(200))
                this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
                this.updatedialog = false
                this.getOwnership()
              this.item.updateownership = ''
              this.item.updateID = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
      async editType(id) {
        let index = this.vehicleOwnership.filter(item => item.id === id );
        this.item.updateownership = index[0].ownership
        this.item.updateID = index[0].id
        this.updatedialog = true
      },
      deleteItem(id){
            if(confirm("Are you sure to delete ownership ?")){
                axios.delete(`/vehicle-ownership/${id}`).then(response=>{
                  this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
                    this.getOwnership()
                }).catch(error=>{
                    console.log(error)
                })
            }
        }

    }
}
</script>