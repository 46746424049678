<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <div class="flex">
        <div class="section_title"><span class="big-size weight-bold">{{ $store.state.loader_text }}&nbsp;&nbsp;</span>
          <div class="load"><v-progress-circular
            color="primary"
            indeterminate
            :size="18"
            :width="3"
            v-show="loader"
          ></v-progress-circular></div>
        </div>

        <div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
           <v-text-field
                v-model="search"
                label="Search"
                solo
                hide-details="auto"
                class="search_"
              ></v-text-field>
          <v-btn
            color="#000000"
            class="ma-1 white--text"
            depressed
            @click.stop="dialog = true"
          >
            Rate
            <v-icon
              right
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="expresswayRates">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-row style="margin-top:0 !important;margin-bottom:0 !important">
            
            <v-col md="12" sm="12" class="my-1 py-0 px-0">
              <v-card class="card-shadow py-0">
                <v-card-text class="px-0 py-3 cardtext">
                  <div class="empty-state" v-if="rates.length === 0 && !loader">
                    <div class="empty-state__content">
                      <div class="empty-state__icon">
                        <v-img src="@/assets/icon.png" alt=""></v-img>
                      </div>
                      <div class="empty-state__message">No records available.</div>
                    </div>
                  </div>
                  <v-virtual-scroll
                      :items="rates"
                      :item-height="20"
                      renderless 
                      id="virtualscroll"
                      class=""
                     >
                      <template v-slot:default="{ item }">
                          <div class="tcell">{{ item.entrance.name }}</div>
                          <div class="tcell">{{ item.exit.name }}</div>
                          <div class="tcell">{{ item.rate | currency('') }}</div>
                          <div class="tcell">
                            <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    small
                                    v-on="on">
                                  <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                </v-btn>
                              </template>
                              
                              <v-list-item-group
                                color="primary"
                                class="actions"
                                >
                                  <v-list-item
                                    @click="edit(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon color="#000000">mdi-pencil</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>

                                <v-list-item
                                  link
                                  @click="deleteItem(item.id)"
                                  >
                                      <v-list-item-icon>
                                        <v-icon>mdi-delete</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Remove</v-list-item-title>
                                      </v-list-item-content>
                                </v-list-item>

                              </v-list-item-group>
                            </v-menu>
                          </div>
                      </template>
                  </v-virtual-scroll>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- user update modal-->
        <v-dialog
          v-model="dialog"
          max-width="500px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6">Add Rate</span>
            </v-card-title>

            <v-card-text>
                <v-container>

                  <v-row
                      v-for="(rate, indx) in grouprates" 
                        :key="indx"
                        style="display:flex;align-items:center;justify-content:space-between"
                      >
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        >
                      
                            <v-autocomplete
                              :items="entrances"
                              label="Select Entrance"
                              v-model="rate.entrance_id" 
                              :name="`grouprates[${indx}][entrance_id]`"
                              item-text="name"
                              item-value="id"
                              required
                              :rules="fieldRules"
                              @change="(event) => updateEntrance(event,indx)"
                              hide-details
                              solo
                            >
                              <template slot="selection" slot-scope="{ item, selected }">
                                  {{ item.name }}
                              </template>
                              <template slot="item" slot-scope="{ item, tile }">
                                  {{ item.name }}
                              </template>

                            </v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            >
                            <v-autocomplete
                              :items="exits"
                              label="Select Exit*"
                              v-model="rate.exit_id"
                              item-text="name"
                              item-value="id"
                              required
                              :rules="fieldRules"
                              :name="`grouprates[${indx}][entrance_id]`"
                              @change="(event) => updateExit(event,indx)"
                              hide-details
                              solo
                            >
                              <template slot="selection" slot-scope="{ item, selected }">
                                  {{ item.name }}
                              </template>
                              <template slot="item" slot-scope="{ item, tile }">
                                  {{ item.name }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            class="textinput"
                            >
                            <v-text-field
                              label="Rate*"
                              required
                              v-model="rate.rate" 
                              :name="`grouprates[${indx}][rate]`"
                                :rules="fieldRules"
                                hide-details
                              ></v-text-field>
                          </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="2"
                          >
                          <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                            <v-icon @click="addRate" class="mx-3">mdi-plus</v-icon>
                            <v-icon @click="removeRate(indx)">mdi-close</v-icon>
                          </div>
                      </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="create"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- update user -->
        <!-- user update modal-->
        <v-dialog
          v-model="updatedialog"
          max-width="500px"
          >
          <v-card>

            <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

            <v-card-title>
              <span class="text-h6">Update Rate</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-autocomplete
                        :items="entrances"
                        label="Select entrance*"
                        v-model="item.updateentrance_id"
                        item-text="name"
                        item-value="id"
                        :rules="fieldRules"
                        :search-input.sync="searchorg"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-autocomplete
                        :items="exits"
                        label="Select exit*"
                        v-model="item.updateexit_id"
                        item-text="name"
                        item-value="id"
                        :rules="fieldRules"
                        :search-input.sync="searchtype"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        label="Rate*"
                        v-model="item.updaterate"
                        :rules="fieldRules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click="updatedialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="update"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
          </v-card>
        </v-dialog>
        <!-- update user -->
      </div>
    </div>

</template>
<style>
  .expresswayRates .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     grid-auto-columns: minmax(25%, 25%) !important;
     /*grid-template-columns: 18.36% 12.28% 21.28% 20.56% 13.24% 14.28%;*/
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
</style>
<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

export default {
    name:"RateList",
    components: {},
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          searchfilter:'',
          search:"",
          searchorg:"",
          searchtype:"",
          valid: true,
          tableloader: false,
          dialog: false,
          updatedialog: false,
          grouprates: [
              {
                entrance_id: "",
                exit_id: "",
                rate: ""
              }
            ],
          fields: [
            { text: 'Entrance', value: 'entrance' },
            { text: 'Exit', value: 'exit' },
            { text: 'Rate(Kes)', value: 'rate' },
            { text: 'Action', value: 'action' },
           ],
          item:{
              updateentrance_id:"",
              updateexit_id:"",
              updaterate:"",
              updateID: "",
              _method:"patch"
          },
            isBusy: false,
            loading: false,
           disabled: false,
           height: '100%',
            rowHeight: 25,
            perPage: 150,
            start: 0,
            timeout: null 
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = "Expressway Rates"

      this.getRates()

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    serverPrefetch () {
    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      rates() {
        return this.$store.state.expresswayrates.filter(item => {
          this.start = 0
          return item.entrance.name.toLowerCase().includes(this.search.toLowerCase()) || item.exit.name.toLowerCase().includes(this.search.toLowerCase())
        })
      },
      entrances() {
        return this.$store.state.entrances
      },
      exits() {
        return this.$store.state.exits
      },
    },
    methods:{
       addRate () {
        this.grouprates.push({
          entrance_id: "",
          exit_id: "",
          rate: ""
        })

      },
      removeRate (index) {
        this.grouprates.splice(index, 1);
      },
      updateEntrance(value,indx) {
        if(value !== null) {
          let found = this.grouprates[indx];
          if (found) {
            found.entrance_id = value
          }
        }
      },
      updateExit(value,indx) {
        if(value !== null) {
          let found = this.grouprates[indx];
          if (found) {
            found.exit_id = value
          }
        }
      },
      getRowClass(item) {
        return 'row-color' 
      },
      async getRates(){
        this.$store.state.tableloader = true
        await axios.get('/expressway/rates').then(response=>{
            this.$store.state.tableloader = false
            this.$store.state.entrances = response.data.entrances
            this.$store.state.exits = response.data.exits
            this.$store.state.expresswayrates = response.data.rates
        }).catch(error=>{
          this.$store.state.tableloader = false
            console.log(error)
        })
      },
      async create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('rates',JSON.stringify(this.grouprates))

            await axios.post('/expressway/rates', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === 200) {

                this.$toast.success('Rate added successfully','Success',{position: 'topRight',timeout: 7000,})
                
                this.grouprates = []
                this.addRate()

                this.getRates()

                this.dialog = false

              }
         
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
        async update(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('entrance_id',this.item.updateentrance_id)
            fd.append('exit_id',this.item.updateexit_id)
            fd.append('rate',this.item.updaterate)
            fd.append('_method',this.item._method)

            await axios.post(`/expressway/rates/${this.item.updateID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === 200) {

                let index = this.$store.state.expresswayrates.findIndex(data => data.id == response.data.rate.id);
                  if (index > -1) {
                    this.$store.state.expresswayrates.splice(index, 1, response.data.rate);
                  }

                this.$toast.success('Rate updated successfully','Success',{position: 'topRight',timeout: 7000,})
                this.item.updateexit_id = ''
                this.item.updateentrance_id = ''
                this.item.updaterate = ''

                this.getRates()

                this.updatedialog = false

              }
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
      async edit(id) {
        let index = this.rates.filter(item => item.id === id )
        if(index.length > 0) {

          this.item.updateexit_id = parseInt(index[0].exit_id)
          this.item.updateentrance_id = parseInt(index[0].entrance_id)
          this.item.updaterate = index[0].rate
          this.item.updateID = index[0].id
          this.updatedialog = true

        }
        
      },
      deleteItem(id){
            if(confirm("Are you sure to delete rate ?")){
                axios.delete(`/expressway/rates/${id}`).then(response=>{
                  if(response.data.status === 200) {

                    let index = this.$store.state.expresswayrates.findIndex(data => data.id == response.data.rate.id);
                    if (index > -1) {
                      this.$store.state.expresswayrates.splice(index, 1);
                    }

                    this.$toast.success('Rate deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                  }
                }).catch(error=>{
                    console.log(error)
                })
            }
        }
    }
}
</script>