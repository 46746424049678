<template>
	<div>
		<!-- map modal  -->
	      <div class="modal modal-slide-down fade mapmodal" id="mapModal" tabindex="-1" aria-labelledby="mapModalTitle" aria-hidden="true">
	        <div class="modal-dialog modal-fullscreen" role="document">
	          <div class="modal-content">
	            <div class="modal-body" style="padding:0;">
	                <div class="modal-header" style="position:absolute;padding:0;margin:0;width:auto !important;height:auto !important"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="position:fixed !important;right:2.2rem;top:.6rem;z-index:111001111 !important"></button></div>
	                
	                <section class="map" ref="map" style="width:100%;height:100%;"></section>
	               
	            </div>
	          </div>
	        </div>
	      </div>
	      <!-- / map modal  -->

      <b-modal ref="showmdetails" hide-footer title="" :no-enforce-focus="true" centered id="mappopup" hide-backdrop>
        <div class="card card-developer-meetup">
          <div class="meetup-img-wrapper rounded-top text-center">
            <img src="https://pixinvent.com/demo/vuexy-html-bootstrap-admin-template/app-assets/images/illustration/email.svg" alt="" height="170" />
          </div>
          <div class="card-body">
            <div class="meetup-header d-flex align-items-center">
              <div class="meetup-day">
                <h6 class="mb-0" style="text-transform:uppercase" v-html="dateName"></h6>
                <h3 class="mb-0" style="text-transform:uppercase" v-html="dateNo"></h3>
              </div>
              <div class="my-auto">
                <h4 class="card-title mb-25" v-html="passenger"></h4>
                <p class="card-text mb-0" v-html="organise"></p>
              </div>
            </div>
            <div class="d-flex flex-row meetings">
              <div class="avatar bg-light-primary rounded me-1">
                <div class="avatar-content">
                  <i class="avatar-icon font-medium-3 fa fa-plane"></i>
                </div>
              </div>
              <div class="content-body">
                <h6 class="mb-0" v-html="pdate"></h6>
                <small v-html="ptime"></small>
              </div>
            </div>
            <div class="row border-top mt-2 text-center mx-0">
              <div class="col-6 border-end d-flex align-items-center">
                <div class="avatar bg-light-primary rounded me-1">
                  <div class="avatar-content">
                    <i class="avatar-icon font-medium-2 fa fa-clock-o"></i>
                  </div>
                </div>
                <div class="content-body">
                  <h6 class="mb-0" v-html="travelstatus"></h6>
                </div>
              </div>
              <div class="col-6 d-flex align-items-center">
                <div class="avatar bg-light-primary rounded me-1">
                  <div class="avatar-content">
                    <i class="avatar-icon font-medium-2 fa fa-phone"></i>
                  </div>
                </div>
                <div class="content-body">
                  <h6 class="mb-0" v-html="ptelephone"></h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
	</div>
</template>

<script>
import axios from 'axios'
import { VueGooglePlaces } from 'vue-google-places'
import {gmapApi} from 'vue2-google-maps'
import {loaded} from 'vue2-google-maps'
import Vue from 'vue'

export default {
	name: 'bookingcomponent',
	components: {
	},
	data: () => ({
		google:gmapApi,
		lat: 0,
        lng: 0,
        watchPositionId: null,
        center: { 
            lat: 39.7837304, 
            lng: -100.4458825 
        },
        details: null,
        dateNo:null,
        dateName:null,
        passenger:null,
        organise:null,
        pdate:null,
        ptime:null,
        travelstatus:null,
        ptelephone:null,
        filteredclients:null,
        directionsService: null,
        directionsDisplay: null,
        mapStyle: {
		    // other properties... 
		    styles: [
		        {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
		        {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
		        {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
		        {
		            featureType: 'administrative.locality',
		            elementType: 'labels.text.fill',
		            stylers: [{color: '#d59563'}]
		        },
		        {
		            featureType: 'poi',
		            elementType: 'labels.text.fill',
		            stylers: [{color: '#d59563'}]
		        },
		        {
		            featureType: 'poi.park',
		            elementType: 'geometry',
		            stylers: [{color: '#263c3f'}]
		        },
		        {
		            featureType: 'poi.park',
		            elementType: 'labels.text.fill',
		            stylers: [{color: '#6b9a76'}]
		        },
		        {
		            featureType: 'road',
		            elementType: 'geometry',
		            stylers: [{color: '#38414e'}]
		        },
		        {
		            featureType: 'road',
		            elementType: 'geometry.stroke',
		            stylers: [{color: '#212a37'}]
		        },
		        {
		            featureType: 'road',
		            elementType: 'labels.text.fill',
		            stylers: [{color: '#9ca5b3'}]
		        },
		        {
		            featureType: 'road.highway',
		            elementType: 'geometry',
		            stylers: [{color: '#746855'}]
		        },
		        {
		            featureType: 'road.highway',
		            elementType: 'geometry.stroke',
		            stylers: [{color: '#1f2835'}]
		        },
		        {
		            featureType: 'road.highway',
		            elementType: 'labels.text.fill',
		            stylers: [{color: '#f3d19c'}]
		        },
		        {
		            featureType: 'transit',
		            elementType: 'geometry',
		            stylers: [{color: '#2f3948'}]
		        },
		        {
		            featureType: 'transit.station',
		            elementType: 'labels.text.fill',
		            stylers: [{color: '#d59563'}]
		        },
		        {
		            featureType: 'water',
		            elementType: 'geometry',
		            stylers: [{color: '#17263c'}]
		        },
		        {
		            featureType: 'water',
		            elementType: 'labels.text.fill',
		            stylers: [{color: '#515c6d'}]
		        },
		        {
		            featureType: 'water',
		            elementType: 'labels.text.stroke',
		            stylers: [{color: '#17263c'}]
		        }
		    ]
		  },
		  night: [
		    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
		    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
		    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
		    {
		      featureType: "administrative.locality",
		      elementType: "labels.text.fill",
		      stylers: [{ color: "#d59563" }],
		    },
		    {
		      featureType: "poi",
		      elementType: "labels.text.fill",
		      stylers: [{ color: "#d59563" }],
		    },
		    {
		      featureType: "poi.park",
		      elementType: "geometry",
		      stylers: [{ color: "#263c3f" }],
		    },
		    {
		      featureType: "poi.park",
		      elementType: "labels.text.fill",
		      stylers: [{ color: "#6b9a76" }],
		    },
		    {
		      featureType: "road",
		      elementType: "geometry",
		      stylers: [{ color: "#38414e" }],
		    },
		    {
		      featureType: "road",
		      elementType: "geometry.stroke",
		      stylers: [{ color: "#212a37" }],
		    },
		    {
		      featureType: "road",
		      elementType: "labels.text.fill",
		      stylers: [{ color: "#9ca5b3" }],
		    },
		    {
		      featureType: "road.highway",
		      elementType: "geometry",
		      stylers: [{ color: "#746855" }],
		    },
		    {
		      featureType: "road.highway",
		      elementType: "geometry.stroke",
		      stylers: [{ color: "#1f2835" }],
		    },
		    {
		      featureType: "road.highway",
		      elementType: "labels.text.fill",
		      stylers: [{ color: "#f3d19c" }],
		    },
		    {
		      featureType: "transit",
		      elementType: "geometry",
		      stylers: [{ color: "#2f3948" }],
		    },
		    {
		      featureType: "transit.station",
		      elementType: "labels.text.fill",
		      stylers: [{ color: "#d59563" }],
		    },
		    {
		      featureType: "water",
		      elementType: "geometry",
		      stylers: [{ color: "#17263c" }],
		    },
		    {
		      featureType: "water",
		      elementType: "labels.text.fill",
		      stylers: [{ color: "#515c6d" }],
		    },
		    {
		      featureType: "water",
		      elementType: "labels.text.stroke",
		      stylers: [{ color: "#17263c" }],
		    },
		  ]
	}),
	methods: {
		setLocationLatLng: function() {
          navigator.geolocation.getCurrentPosition(geolocation => {
            this.center = {
              lat: geolocation.coords.latitude,
              lng: geolocation.coords.longitude
            };
          })
        },
        async getBookings(){

	        this.$store
	          .dispatch('getBookings')
	          .then(() => {
	            this.isBusy = false
	          })
	          .catch(() => {
	            this.isBusy = false
	          })
	      },
	},
	computed: {

		allbookings() {
        return this.$store.state.bookings
      },
	},
	mounted() {
		
	  this.setLocationLatLng();

      if(!this.allbookings || this.allbookings.length == 0) {
        this.getBookings()
      }
 
    // map
      // var markers, i;

      this.$gmapApiPromiseLazy().then(() => { 

        var bounds = new google.maps.LatLngBounds()

        var map = new google.maps.Map(this.$refs["map"], {
          zoom: 10,
          center: new google.maps.LatLng(-1.286389, 36.817223),
          mapTypeId: google.maps.MapTypeId.ROADMAP
        });
        map.setOptions({ styles: this.night })

        var marker = new google.maps.Marker({
          map: map
        });

        this.watchPositionId = navigator.geolocation.watchPosition(
          position => {
            this.lat = position.coords.latitude
            this.lng = position.coords.longitude

            map.setCenter(new google.maps.LatLng(this.lat, this.lng))
            marker.setPosition(new google.maps.LatLng(this.lat, this.lng))
            // this.updateLocation(this.lat, this.lng)
          },
          error => {
            console.log(error.message);
          }
        )


        for (let i = 0; i < this.allbookings.length; i++) {

          var markerIcon = {
            url: '/assets/icons/driver.svg',
            scaledSize: new google.maps.Size(30, 30),
          }

          var markers = 'marker_'+this.allbookings[i].id;
          
          markers = new google.maps.Marker({
            icon: markerIcon,
            map,
            position: new google.maps.LatLng(parseFloat(this.allbookings[i].fromlat), parseFloat(this.allbookings[i].fromlong)),
          });

          markers.addListener("click", () => {
            // map.setZoom(13);
            // map.setCenter(markers.getPosition());

            // show info window
            this.dateNo = Vue.filter('dateNo')(this.allbookings[i].pickup_date)
            this.dateName = Vue.filter('dateName')(this.allbookings[i].pickup_date)
            this.passenger = this.allbookings[i].passenger_name
            this.organise = this.allbookings[i].organisation.name
            this.pdate = Vue.filter('dateF')(this.allbookings[i].pickup_date)
            this.ptime = this.allbookings[i].pickup_time+"hrs"
            this.ptelephone = this.allbookings[i].telephone
            this.travelstatus = Vue.filter('timeremaining')(this.allbookings[i].pickup_date+' '+this.allbookings[i].pickup_time)

            this.$refs['showmdetails'].show()

          });


        }


      })
	},
	created() {
        //do we support geolocation
        if(!("geolocation" in navigator)) {
         console.log('Geolocation is not available.')
         return
        }
     
    }
}
</script>