<template>
    <!-- BEGIN: Content-->
    <div class="mt-0">
      <loader/>
      <div class="newCreditNote">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
          <v-form enctype="multipart/form-data" v-model="valid" ref="form">
            <v-row style="margin-top:0 !important;margin-bottom:2rem !important">
              <v-col  cols="12" md="8" lg="7" sm="12" class="my-1 py-0">
                <v-card class="card-shadow py-3 px-2 mb-5">
                  <v-card-text>
                    <v-row class="py-4">
                      <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-autocomplete
                            :items="organisations"
                            label="Select Organisation*"
                            v-model="item.organisation_id"
                            :value="item.organisation_id"
                            item-text="name"
                            item-value="id"
                            :rules="fieldRules"
                            :search-input.sync="searchfilter"
                            @change="selectOrg"
                            @click:clear="item.organisation_id = ''"
                          ></v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-autocomplete
                            :items="invoices"
                            label="Select Invoice"
                            v-model="item.invoice_no_id"
                            :value="item.invoice_no_id"
                            item-text="invoice_no"
                            item-value="id"
                            :search-input.sync="searchinvoice"
                          ></v-autocomplete>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            label="Credit Amount*"
                            v-model="item.amount"
                            :rules="fieldRules"
                          ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        >
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Credit date*"
                              :rules="fieldRules"
                              :value="timepickerdate"
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="date = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            no-title
                            scrollable
                            @input="menu = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="#f9780d"
                              @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                          <v-text-field
                            label="Notes"
                            v-model="item.notes"
                          ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col cols="12" sm="12" style="padding-bottom: .4rem !important;align-items:center;justify-content:space-between" class="d-flex">
                        <h6></h6>
                        <div style="width:250px !important"><v-text-field
                            v-model="search"
                            label="Search"
                            solo
                            hide-details="auto"
                          ></v-text-field></div>
                      </v-col>
                      <v-col cols="12" sm="12" style="padding-top: .3rem !important" class="cardtext2">

                        <div class="empty-state" v-if="allinvoices.length === 0" style="margin-top:8rem !important">
                          <div class="empty-state__content">
                            <div class="empty-state__icon">
                              <v-img src="@/assets/icon.png" alt=""></v-img>
                            </div>
                            <div class="empty-state__message">No records available.</div>
                          </div>
                        </div>

                        <v-virtual-scroll
                            :items="allinvoices"
                            :item-height="20"
                            renderless 
                            id="virtualscroll"
                           >
                            <template v-slot:default="{ item }">
                                <div class="tcell"><RouterLink style="color:#F98525 !important;text-decoration:none !important" link
                                        :to='{name:"showInvoice",params:{id:item.id}}'>{{ item.invoice_no }}</RouterLink></div>
                                <div class="tcell">{{ item.status }}</div>
                                <div class="tcell">KES<span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span></div>
                                <div class="tcell">
                                  <v-menu offset-x left bottom origin="center center" transition="scale-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                          icon
                                          v-bind="attrs"
                                          small
                                          v-on="on">
                                        <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                      </v-btn>
                                    </template>
                                    
                                    <v-list-item-group
                                      color="primary"
                                      class="actions"
                                      >
                                        <v-list-item
                                          link
                                          :to='{name:"editInvoice",params:{id:item.id}}'
                                          >
                                            <v-list-item-icon>
                                              <v-icon color="#000000">mdi-pencil</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Edit</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                      <v-list-item
                                        link
                                        @click="deleteInvoice(item.id)"
                                        >
                                            <v-list-item-icon>
                                              <v-icon>mdi-delete</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>Remove</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>

                                    </v-list-item-group>
                                  </v-menu>
                                </div>
                            </template>
                        </v-virtual-scroll>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-bottom-navigation
                  fixed
                  horizontal
                  class="pt-3"
                >
                <div>
                  <v-btn
                    color="#F98525"
                    class="mx-2"
                    style="height:35px !important;border-radius:3px;"
                    :loading="loading1"
                    :disabled="!valid"
                    @click="save"
                  >
                    <span style="font-size:.82rem !important;color:#111 !important">Save</span>
                  </v-btn>
                  <v-btn
                      color="#dedede"
                      class="mx-2"
                      depressed
                      link
                      :to="{name:'paymentList'}"
                      style="height:35px !important;border-radius:3px;"
                    >
                    <span style="font-size:.82rem !important;color:#111 !important">Cancel</span>
                  </v-btn>
                </div>
                </v-bottom-navigation>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </div>

</template>
<style>
  .newCreditNote .v-virtual-scroll__item{
    width:100%;
    display: grid !important;

     /*grid-auto-columns: minmax(12.5%, 12.5%) !important;*/
     grid-template-columns: 25% 25% 25% 25%;
     grid-auto-flow: column !important;
     grid-gap: 0px;
  }
</style>
<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import {ability} from '@/services/ability'
import loader from '@/components/loader'
import $ from 'jquery';

export default {
    name:"newCreditNote",
    components: { loader },
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu: false,
          duedate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
          menu2: false,
          searchfilter:null,
          search:'',
          searchinvoice:null,
          paymentmodesearch:null,
          valid: true,
          searchcategory:null,
          searchcountry:null,
          searchstatus:null,
          dialog: false,
          updatedialog: false,
          fields: [
            //  {
            //   text: 'DATE',
            //   align: 'start',
            //   value: 'date',
            // },
            { text: 'INVOICE#', value: 'invoiceno' },
            // { text: 'ORGANISATION', value: 'customer' },
            { text: 'STATUS', value: 'status' },
            { text: 'AMOUNT', value: 'amount' },
            { text: 'ACTION', value: 'action' },
           ],
          item:{
             organisation_id: "",
             invoice_no_id: "",
             amount: "",
             notes: ""
          },
            isBusy: false,
            loading: false,
            loading1: false,
           disabled: false,
           organisations: [],
           invoices: [],
           modes: [],
           height: '100%',
            rowHeight: 24,
            perPage: 32,
            start: 0,
            timeout: null,
            selectedItem: false
        }
    },
    beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
    mounted(){

      this.$store.state.loader_text = 'Add Credit Note'

      this.getData()

      let headertitle = `<div class="v-virtual-scroll__container"><div class="v-virtual-scroll__item" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="thead">${value.text}</div>`;
         });
      headertitle +=`</div></div>`;
      $('.v-virtual-scroll').prepend(headertitle)

    },
    computed: {
      timepickerdate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
      timepickerduedate () {
        return this.duedate ? moment(this.duedate).format('Do MMM YYYY') : ''
      },
      allinvoices () {
        return this.invoices.filter(item => {

          this.start = 0

          let invoice_no = "";
          let organisation = "";

          if(item.organisations.length > 0) {
            organisation = item.organisations[0].name
          }
          if(item.invoice_no !== null) {
            invoice_no = item.invoice_no
          }
          return invoice_no.toLowerCase().includes(this.search.toLowerCase()) || organisation.toLowerCase().includes(this.search.toLowerCase())
        })
      },
    },
    methods:{

      async save(){

        if (this.$refs.form.validate()) {

          this.loading1 = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',this.item.organisation_id)
          fd.append('invoice_no_id',this.item.invoice_no_id)
          fd.append('amount',this.item.amount)
          fd.append('notes',this.item.notes)
          fd.append('date',this.date)

          axios.post('/credit-notes', fd, config).then(response=>{

            if(response.data.status == parseInt(200)) {

              let found = this.$store.state.creditnotes.find(data => data.id == response.data.creditnote.id);
              if (!found) {
                this.$store.state.creditnotes.unshift(response.data.creditnote)
              }

              this.$toast.success('Credit note submitted successfully','Success',{position: 'topRight',timeout: 7000,})

              this.$router.push({ name: 'creditnotesList' })
            }
              this.loading1 = false
              this.disabled = false
            })
            .catch(() => {
              this.loading1 = false
              this.disabled = false
            })

          }
      },

      async getData(){
        this.$store.state.tableloader = true
        await axios.get('/new/credit-note').then(response=>{
            this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.organisations = response.data.organisations
            }
        }).catch(error=>{
            this.$store.state.tableloader = false
            console.log(error)
        })
      },

      async selectOrg(id) {
        await axios.get(`/credit-note/invoices/${id}`).then(response=>{
            if(response.data.status == parseInt(200)) {
              this.invoices = response.data.invoices
            }
        }).catch(error=>{
            console.log(error)
        })
      },
  
      clear () {
        if(this.$refs.form != undefined) {
          this.$refs.form.reset()
        }
      
      }
    }
}
</script>