<template>
  <div>
    <v-dialog
      max-width="800px"
      v-model="show"
      >
      <v-card>
        <v-card-text class="mt-0 pt-0">
           <v-row  class="mx-0 my-0 py-0">
              <v-col cols="12">
                <p style="font-size:.95rem !important;display:flex;align-items:center;justify-content:space-between" class="mx-1"><strong>Rates</strong>
                  <v-btn
                    color="#000000"
                    class="white--text"
                    depressed
                    @click.stop="dialog = true"
                  >
                  Rate
                    <v-icon
                      right
                      dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </p>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Date
                        </th>
                        <th class="text-left">
                          Rate(%)
                        </th>
                        <th class="text-left">
                          Topup
                        </th>
                        <th class="text-left">
                          Status
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="rates.length == 0">
                        <td colspan="4">
                          <v-fade-transition>
                            <div>
                              <!-- <div class="classloader"> -->
                                 <Spinner name="circle" color="#008000" fadeIn="quarter" v-show="overlay"/>
                              <!-- </div> -->
                            </div>
                          </v-fade-transition>
                        </td>
                      </tr>
                      <tr
                        v-for="(item,i) in rates"
                        :key="i"
                      >
                        <td>{{ item.start_date }}</td>
                        <td>{{ item.rate }}</td>
                        <td>{{ item.topup | currency('',2) }}</td>
                        <td>
                          <v-chip @click="changeStatus(item)">{{ item.status }}</v-chip>
                        </td>
                        <td>
                          <v-icon
                              small
                              class="mr-2"
                              @click="edit(item.id)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                              small
                              @click="deleteItem(item.id)"
                            >
                              mdi-delete
                            </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

	  <!-- add commission rate dialog -->
    <v-dialog
      v-model="dialog"
      max-width="300px"
      >
      <v-card>

        <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

        <v-card-title>
          <span class="text-h6">New Rate</span>
        </v-card-title>

        <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    label="Rate*"
                    v-model="item.rate"
                    required
                    :rules="fieldRules"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    label="Topup*"
                    v-model="item.topup"
                    required
                    :rules="fieldRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="!valid"
              @click="create"
            >
              Save
            </v-btn>
        </v-card-actions>

      </v-form>
      </v-card>
    </v-dialog>
    <!-- end commission rate -->

    <!-- commission rate update modal-->
    <v-dialog
      v-model="updatedialog"
      max-width="300px"
      >
      <v-card>

        <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

        <v-card-title>
          <span class="text-h6">Update Rate</span>
        </v-card-title>

        <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    label="Rate*"
                    v-model="item.updaterate"
                    required
                    :rules="fieldRules"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    label="Topup*"
                    v-model="item.updatetopup"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="updatedialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="!valid"
              @click="update"
            >
              Save
            </v-btn>
        </v-card-actions>

      </v-form>
      </v-card>
    </v-dialog>
    <!-- update commission rate -->

	</div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import Spinner from 'vue-spinkit'
import $ from 'jquery';

export default {
	name: 'commissionrates',
	components: {
    Spinner
	},
	data: () => ({
    overlay:false,
    searchfilter: "",
		fieldRules: [
      (v) => !!v || 'Field is required'
    ],
    dialog: false,
    updatedialog: false,
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,

     item:{
        rate: "",
        updaterate: "",
        topup: "",
        updatetopup: "",

        updateID: "",
        _method:"patch"
    },

	}),
	methods: {

		fetchcommissions() {
    this.overlay = true
    this.$store
        .dispatch('fetchcommissionrates',this.driverid)
        .then(() => {
          this.overlay = false
        })
        .catch(() => {
          this.overlay = false
        })
    },
    create(){

        if (this.$refs.form.validate()) {

        this.loading = true
        this.disabled = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('rate',this.item.rate)
        fd.append('topup',this.item.topup)
        fd.append('driver_id',this.$route.params.id)

        axios.post('/commissions', fd, config).then(response=>{
          if(response.data.status == parseInt(200)) {

            this.$toast.success('Commission rate updated successfully','Success',{position: 'topRight',timeout: 7000,})

            this.$store.state.commission_rates = response.data.commissions
            this.clear()
            this.dialog = false
          }
            this.loading = false
            this.disabled = false
          })
          .catch(() => {
            this.loading = false
            this.disabled = false
          })

        }
    },
   clear () {
      this.$refs.form.reset()
    },
    edit(id) {
      let index = this.rates.filter(item => item.id === id );
      this.item.updaterate = index[0].rate
      this.item.updatetopup = index[0].topup
      this.item.updateID = index[0].id
      this.updatedialog = true
    },
    update(){

      if (this.$refs.form.validate()) {

      this.loading = true
      this.disabled = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('rate',this.item.updaterate)
        fd.append('topup',this.item.updatetopup)
        fd.append('_method',this.item._method)

        axios.post(`/commissions/${this.item.updateID}`, fd, config).then(response=>{
          this.loading = false
          this.disabled = false
          if(response.data.status === parseInt(200))
            this.$toast.success("Rate updated successfully",'Success',{position: 'topRight',timeout: 7000,})
            this.updatedialog = false

            let index = this.$store.state.commission_rates.findIndex(data => data.id == response.data.commission.id);
            if (index > -1) {
              this.$store.state.commission_rates.splice(index, 1, response.data.commission);
            }

          this.clear()
          this.item.updateID = ''
        }).catch(error=>{
          this.loading = false
          this.disabled = false
            console.log(error)
        })

      }

    },
    changeStatus(data){

      axios.get(`/change-commission-status/${data.id}/${data.status}`).then(response=>{

          if(response.data.status === parseInt(200))
            this.$toast.success("Rate updated successfully",'Success',{position: 'topRight',timeout: 7000,})

            let index = this.$store.state.commission_rates.findIndex(data => data.id == response.data.commission.id);
            if (index > -1) {
              this.$store.state.commission_rates.splice(index, 1, response.data.commission);
            }

        }).catch(error=>{
            console.log(error)
        })

    },
	},
	props: {
     driverid: Number,
     value: Boolean,
  },
  watch: {
      show: function (newVal,oldVal) {
        if(newVal == true) {
          this.$store.state.commission_rates = []
          this.fetchcommissions()
        }
      }
  },
  computed: {
    rates() {
      return this.$store.state.commission_rates
    },
    driver() {
      return this.$store.state.driverId
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    },
  },
	mounted() {
	},
	created() {
     
    }
}
</script>