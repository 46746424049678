<template>
	<div>
	  <!-- client profile modal -->
      <v-dialog
        v-model="show"
        max-width="400px"
        class="updatedetails"
        >
        <v-card>
          <div style="width:100%;height:.3rem !important">
            <v-progress-linear
              color="#008000"
              indeterminate
              height="3"
              :active="addbookingloader"
            ></v-progress-linear>
          </div>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">
            <v-card-title>
              <span class="text-h6">Transfer Records</span>
            </v-card-title>

            <v-card-text class="mt-0 pt-0" v-if="selected.length > 0">
              <v-row class="mt-0">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  >
                  <v-autocomplete
                    :items="organisations"
                    label="Select organisation*"
                    v-model="organisation_id"
                    item-text="name"
                    item-value="id"
                    :rules="fieldRules"
                    required
                    hide-details
                    clearable
                    @click:clear="organisation_id === ''"
                  >
                    <template slot="selection" slot-scope="{ item, selected }">
                        {{ item.name }}
                    </template>
                    <template slot="item" slot-scope="{ item, tile }">
                        {{ item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mt-5 mb-0 pb-0">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  class="my-0 py-0"
                  >
                  <v-switch
                      v-model="updateaccount"
                      label="Apply changes to individual account ?"
                      hide-details
                    ></v-switch>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click="show = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="submit"
                >
                  Save
                </v-btn>
            </v-card-actions>

          </v-form>
        </v-card>
      </v-dialog>
      <!-- end client profile -->
	</div>
</template>

<style scoped>
  .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th, .v-data-table-header > th {
    font-weight: 500;
    height: 25px !important;
}
.clientprofile td{
  white-space: normal !important; 
}

</style>
<style>
  .v-select:not(.v-select--is-multi).v-text-field--single-line .v-select__selections{
    text-transform: uppercase !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'updatedetails',
	components: {
	},
	data: () => ({
    tableloader: false,
    loading1: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        organisation_id: "",
        updateaccount: null,
	}),
	methods: {
      getUpdateDetails(){

        this.$store.state.addbookingloader = true

        this.$store
          .dispatch('getUpdateDetails')
          .then(() => {
            
          })
          .catch(() => {
            this.isBusy = false
          })
      },
      submit(){
          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('selected',JSON.stringify(this.selected))
            fd.append('organisation_id',this.organisation_id)
            fd.append('updateaccount',this.updateaccount)

            axios.post('/transfer/records', fd, config).then(response=>{

              if(response.data.status === parseInt(200)) {
                this.$toast.success("Transfer was successful",'Success',{position: 'topRight',timeout: 7000,})
              }


              this.show = false
              this.loading = false
              this.disabled = false
              
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

        }
      },

	},
	props: {
	     value: Boolean,
	     selected: Array
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
      organisations() {
        return this.$store.state.updateorganisations
      },
      addbookingloader() {
        return this.$store.state.addbookingloader
      },
	  },
	mounted() {
  },
  watch: {
      show: function (newVal,oldVal) {
        if(newVal == true) {
          this.$store.state.updateorganisations = this.$store.state.reportorganisations
          if(this.organisations.length == 0){
            this.getUpdateDetails()
          }
        }
      }
  },
	created() {}
}
</script>