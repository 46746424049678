<template>
	<div style="display:flex;align-items:center;justify-content:space-between;flex-direction:row">
    <v-text-field
      :loading="searchloading"
      placeholder="Start typing to search"
      variant="solo"
      hide-details
      v-model="advancedsearch"
      clearable
      style="width:300px !important"
      class="mx-3"
      @click:clear="onClear"
    ></v-text-field>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import debounce from 'debounce'

export default {
	name: 'bookingAdvancedSearch',
	components: {
	},
	data: () => ({
		messageclientdialog: false,
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    search: "",
    advancedsearch: '',
    searchloading: false,
	}),
	methods: {
		onClear() {
      this.$store.state.tableloader = true
      this.$store
          .dispatch('getBookings')
    },
    fetchSearch: async (value, self) => {
      // Handle empty value
      if (!value) {}
      // Items have already been requested
      if (self.searchloading) {
        return
      }

      if (value.length > 2) {

        self.searchloading = true

        await axios.get(`/booking/advancedsearch/${value}`).then(({ data }) => {
          self.searchloading = false
          if(data.status == parseInt(200)) {
            self.$store.commit('getBookings', data)
          }
          
        })
        .catch((error) => {
          self.searchloading = false
          console.log(error)
        })
      }

    },
	},
  watch: {
    advancedsearch(value) {
      if (!value) {
        return
      }
      debounce(this.fetchSearch, 700)(value, this)
    }
  },
	props: {},
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    },
  },
	mounted() {

	},
	created() {
     
    }
}
</script>