<template>
	<div>
	  <!-- client profile modal -->
      <v-dialog
        v-model="show"
        max-width="350px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Update Distance</span>
          </v-card-title>

          <v-card-text class="mt-0 pt-0">
            <v-row class="mx-0 my-0 py-0">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  >
                  <v-text-field
                      label="Distance*"
                      required
                      v-model="distance"
                      :rules="fieldRules"
                    ></v-text-field>
                </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="default darken-1"
                text
                @click="show = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="submit"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end client profile -->
	</div>
</template>

<style scoped>
  .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th, .v-data-table-header > th {
    font-weight: 500;
    height: 25px !important;
}
.clientprofile td{
  white-space: normal !important; 
}
</style>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'editdistance',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        distance:"",
	}),
	methods: {
      submit(){
          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('distance',this.distance)
            fd.append('id',this.bookingid)

            axios.post('/booking/updatedistance', fd, config).then(response=>{

              if(response.data.status === parseInt(201)) {
                this.$toast.success("Distance updated successfully",'Success',{position: 'topRight',timeout: 7000,})
              }
              this.distance = ""
              this.show = false
              this.loading = false
              this.disabled = false
              
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

        }
      },
	},
	props: {
	     value: Boolean,
	     bookingid: Number
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {},
	created() {}
}
</script>