<template>
  <div class="allinvoices invdialog">
    <v-dialog
      max-width="600px"
      v-model="show"
      >
        <v-card>
          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">
            <v-card-title class="d-flex justify-space-between">
              <div class="loadflex"><span class="text-h6">Create Invoice</span>&nbsp;&nbsp;<div class="load"><v-progress-circular
                    color="primary"
                    indeterminate
                    :size="18"
                    :width="3"
                    v-show="loader"></v-progress-circular></div></div>
            </v-card-title>

            <v-card-text>
                <v-container class="mt-0 mx-0 px-0 mb-0">
                  <v-row>
                    <v-col
                      cols="12"
                      xl="12"
                      md="12"
                      >
                      <date-range-picker
                        ref="picker"
                        :showWeekNumbers="showWeekNumbers"
                        :showDropdowns="showDropdowns"
                        :autoApply="autoApply"
                        opens="top"
                        :always-show-calendars="alwaysshowcalendars"
                        v-model="dateRange"
                        @select="updateValues"
                      >
                          <template v-slot:input="picker">
                              <v-text-field
                                label="Select date range"
                                :rules="fieldRules"
                                v-model="date"
                                hide-details
                                solo
                              ></v-text-field>
                          </template>
                      </date-range-picker>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-autocomplete
                          :items="drivers"
                          label="Select driver"
                          v-model="item.driver_id"
                          item-text="name"
                          item-value="id"
                          :search-input.sync="syncdriver"
                          @change="(event) => selectedDriver(event)"
                        >
                        </v-autocomplete>
                      </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      >
                      <v-switch
                        v-model="item.alldrivers"
                        label="All drivers ?"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      class="mt-0 pt-0"
                      >
                      <p style="color:darkgrey;font-size:15px;"><i>If you check <b>All drivers?</b>, invoices will be created for all the drivers for the speific dates provided.</i></p>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="default darken-1"
                  text
                  @click.stop="show = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :loading="loading"
                  :disabled="!valid"
                  @click="submit"
                >
                  Submit
                </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'
import DateRangePicker from 'vue2-daterange-picker'

export default {
  name: 'allinvoices',
  components: {
    DateRangePicker
  },
  data: () => ({
    fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          menu2: false,
          date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        valid: true,
        syncdriver: null,
        isBusy: false,
        loading: false,
        disabled: false,
        item:{
           amount: "",
           driver_id: '',
           alldrivers: null,
           date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        },
        dateRange: {
        startDate: null,
        endDate: null
       },
      singleDatePicker: true,
      showWeekNumbers: true,
      showDropdowns: true,
      autoApply: true,
      alwaysshowcalendars: true,
      date: "",
      dates: {},
      daterange: {
        start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
      },
      rangedate: "",
  }),
  methods: {

    selectedDriver(id){},

    updateValues(val) { 
      this.daterange.start = Vue.filter('date')(val.startDate)
      this.daterange.end = Vue.filter('date')(val.endDate)
      this.date = this.daterange.start+" - "+this.daterange.end
    },

    async getData(){
        this.$store.state.componentloader = true
        await axios.get('/alldrivers').then(response=>{
            this.$store.state.componentloader = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.alldrivers = response.data.drivers
            }
        }).catch(error=>{
          this.$store.state.componentloader = false
          console.log(error)
        })
      },
    submit(){
      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('driver_id',this.item.driver_id)
      fd.append('amount',this.item.amount)
      fd.append('date',this.item.date)
      fd.append('alldrivers',this.item.alldrivers)
      fd.append('start',this.daterange.start)
      fd.append('end',this.daterange.end)

      axios.post('/driver/create/allinvoices', fd, config).then(response=>{
          this.loading = false
        if(response.data.status == parseInt(200)) {
          this.$toast.success('Invoice created successfully','Success',{position: 'topRight',timeout: 7000,})
          this.clear()
          this.show = false
          this.getDriverInvoices()
        }
          
        })
        .catch(() => {
          this.loading = false
        })
    },

    async getDriverInvoices(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getDriverInvoices')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },

    clear() {
      this.item.driver_id = ""
      this.item.amount = ""
      this.item.date = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    }
  },
  watch: {
      show: function (newVal,oldVal) {
        if(newVal == true) {
          this.getData()
        }
      }
  },
  props: {
     value: Boolean,
     driverid: Number
  },
  computed: {
    loader() {
      return this.$store.state.componentloader
    },
    drivers() {
      return this.$store.state.alldrivers
    },
    timepickerdate () {
        return this.date ? moment(this.date).format('Do MMM YYYY') : ''
      },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
  mounted() {
  },
  created() {}
}
</script>